import React, { useEffect, useState } from 'react';
import LandItem from './LandItem';
import { ZOOM_RATE, MAP_START_X, MAP_START_Y } from './Const';
import * as DataService from "../../services/DataService";

function GridOverlay({zoomLevel, offsetX, offsetY, startXPos, startYPos, width, height, activeParcel, setActiveParcel, isUpdated, isUpdatingDetails, setIsUpdatingDetails}) {
	const [mapData, setMapData] = useState([]);
	const [dataChanged, setDataChanged] = useState(false);
	
	useEffect(() => {
		if(activeParcel) {
			let tempMapData = mapData;
			setMapData([]);
			for (let i = 0; i < tempMapData.length; i++) {
				for (let j = 0; j < tempMapData[i].length; j++) {
					if(!activeParcel.TileGroup) {
						if (tempMapData[i][j]?.posX === activeParcel.posX && tempMapData[i][j]?.posY === activeParcel.posY) {
							tempMapData[i][j] = activeParcel;
							setMapData(tempMapData);
							return;  // Exit after the first match is replaced
						}
					}
					else {
						if (tempMapData[i][j]?.tileGroupId === activeParcel.tileGroupId) {
							tempMapData[i][j] = {
								...tempMapData[i][j], 
								User: activeParcel.User,
								TileGroup: activeParcel.TileGroup,
								isForBid: activeParcel.isForBid,
								isForSale: activeParcel.isForSale,
								minted: activeParcel.minted,
								isInfrastructure: activeParcel.isForInfrastructure,
								isforRent: activeParcel.isforRent,
								ownerId: activeParcel.ownerId,
							};
						}
					}
				}
			}
			setMapData(tempMapData);
		}
	}, [activeParcel]);

	useEffect(() => {
		setIsUpdatingDetails(true);
		DataService.fetchTiles({
			startX: MAP_START_X - startXPos,
			endX: MAP_START_X - startXPos + width,
			startY: MAP_START_Y - startYPos,
			endY: MAP_START_Y - startYPos + height
		}).then((res) => {
			let array = [];

			for(let j=0; j<=height; j++) {
				let xArray = [];
				for(let i=0; i<=width; i++) {
					xArray.push(null);
				}
				array.push(xArray);
			}
			for(let index=0; index<res.count; index++) {
				array[res.rows[index].posY - (MAP_START_Y - startYPos)][res.rows[index].posX - (MAP_START_X - startXPos)] = res.rows[index];
			}

			setMapData(array);
			setDataChanged(!dataChanged);
			setIsUpdatingDetails(false);
		}).catch(e => {
			setIsUpdatingDetails(false);
		});
		// eslint-disable-next-line
	}, [zoomLevel, isUpdated])
		
  return (
	<React.Fragment>
		{!isUpdatingDetails && <div style={{position: 'absolute', left: -(Math.pow(ZOOM_RATE, zoomLevel) - offsetX), top: offsetY, zIndex: 10}}>
			{mapData.map((row, y) => {
				return <div key={`index-${y}`} style={{display: 'flex'}}>{
					row.map((el, x) => {
						return <LandItem key={`${x}-${y}`} size={Math.pow(ZOOM_RATE, zoomLevel)} data={el} y={y} x={x} activeParcel={activeParcel} setActiveParcel={setActiveParcel} />
					})
				}</div>;
			})}
		</div>}
	</React.Fragment>
  );
}

export default GridOverlay;

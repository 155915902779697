import { axiosWithToken } from '../utils/functions/apiCallWithToken';

const serverURL = process.env.REACT_APP_SERVER;

export const fetchDesigners = async (companyId) => {
    const result = await axiosWithToken(`${serverURL}/designer/${companyId}`, 'get', {});
    return result;
}

export const createDesigner = async (data) => {
    const result = await axiosWithToken(`${serverURL}/designer/create/`, 'post', data);
    return result;
}

import { createSelector, createStructuredSelector } from "reselect";


//Store Selectors
export const assetBreakdownState = (state) => state.NFT.assetBreakdown;
export const nftBreakdownState = (state) => state.NFT.nftBreakdown;
export const nftLikeBreakdownState = (state) => state.NFT.nftLikeBreakdown;
export const assetLikeBreakdownState = (state) => state.NFT.assetLikeBreakdown;
export const tileLikeBreakdownState = (state) => state.NFT.tileLikeBreakdown;
export const nftWishlistBreakdownState = (state) => state.NFT.nftWishlistBreakdown;
export const assetWishlistBreakdownState = (state) => state.NFT.assetWishlistBreakdown;
export const tileWishlistBreakdownState = (state) => state.NFT.tileWishlistBreakdown;
export const tileBreakdownState = (state) => state.NFT.tileBreakdown;
export const tileGroupBreakdownState = (state) => state.NFT.tileGroupBreakdown;
export const nftShowcaseState = (state) => state.NFT.nftShowcase;
export const assetDetailState = (state) => state.NFT.assetDetail;
export const nftDetailState = (state) => state.NFT.nftDetail;
export const tileDetailState = (state) => state.NFT.tileDetail;
export const tileGroupDetailState = (state) => state.NFT.tileGroupDetail;
export const showLoadMore = (state) => state.NFT.showLoadMore;
export const showLoadMoreNft = (state) => state.NFT.showLoadMoreNft;
export const showLoadMoreNftLike = (state) => state.NFT.showLoadMoreNftLike;
export const showLoadMoreAssetLike = (state) => state.NFT.showLoadMoreAssetLike;
export const showLoadMoreTileLike = (state) => state.NFT.showLoadMoreTileLike;
export const showLoadMoreNftWishlist = (state) => state.NFT.showLoadMoreNftWishlist;
export const showLoadMoreAssetWishlist = (state) => state.NFT.showLoadMoreAssetWishlist;
export const showLoadMoreTileWishlist = (state) => state.NFT.showLoadMoreTileWishlist;
export const showLoadMoreTile = (state) => state.NFT.showLoadMoreTile;
export const showLoadMoreTileGroup = (state) => state.NFT.showLoadMoreTileGroup;
export const nftCount = (state) => state.NFT.nftCount;
export const nftLikeCount = (state) => state.NFT.nftLikeCount;
export const assetLikeCount = (state) => state.NFT.assetLikeCount;
export const tileLikeCount = (state) => state.NFT.tileLikeCount;
export const nftWishlistCount = (state) => state.NFT.nftWishlistCount;
export const assetWishlistCount = (state) => state.NFT.assetWishlistCount;
export const tileWishlistCount = (state) => state.NFT.tileWishlistCount;
export const assetCount = (state) => state.NFT.assetCount;
export const tileCount = (state) => state.NFT.tileCount;
export const balance = (state) => state.NFT.balance;
export const continent = (state) => state.NFT.continent;
export const tileGroupCount = (state) => state.NFT.tileGroupCount;
export const hotCollectionsState = (state) => state.hotCollection.hotCollections;
export const authorsState = (state) => state.authors.authorList;
export const currentAuthorState = (state) => state.authors.currentAuthor;
export const authorRankingsState = (state) => state.authors.authorRanking;
export const user = (state) => state.user.user;
export const isTimeout = (state) => state.user.isTimeout;

//blogs
export const blogsState = (state) => state.blogs.blogPosts;
export const recentPostsState = (state) => state.blogs.recentPosts;
export const tagsState = (state) => state.blogs.tags;
export const commentsState = (state) => state.blogs.comments;

//Notifications
export const notificationsState = (state) => state.notifications.notifications;

export const loadingState = (state) => state.loadings.isActive;
export const showRegisterModal = (state) => state.loadings.showRegisterModal;

export const auctionedNfts = createSelector(assetBreakdownState, ( nfts ) => {
    if(!nfts.data) {
        return [];
    }
    const acutioned = nfts.data.filter(nft => !!nft.deadline);
    return acutioned;
});

export const nftFilter = createStructuredSelector({
    categories: (state) => state.filters.selectedCategories,
    status: (state) => state.filters.selectedStatus,
    itemsType: (state) => state.filters.selectedItemsType,
    collections: (state) => state.filters.selectedCollections,
    nftTitle: (state) => state.filters.filterNftTitle
});

export const nftItems = createSelector(nftFilter, nftBreakdownState, ( filters, nfts ) => {
    let { data } = nfts;
    const { categories, status, itemsType, collections, nftTitle } = filters;
    
    if(!data) {
        return [];
    }

    if(categories.size) {
        data = data.filter( nft => categories.has(nft.category));
    }
    if(status.size) {
        data = data.filter( nft => status.has(nft.status));
    }
    if(itemsType.size) {
        data = data.filter( nft => itemsType.has(nft.item_type));
    }
    if(collections.size) {
        data = data.filter( nft => collections.has(nft.collections));
    }
    if(nftTitle.trim().length) {
        let pattern = new RegExp(`${nftTitle.trim()}`, 'gi');
        data = data.filter( nft => nft.title.match(pattern));
    }

    return data;
});

export const nftLikeItems = createSelector(nftFilter, nftLikeBreakdownState, ( filters, nfts ) => {
    let { data } = nfts;
    const { categories, status, itemsType, collections, nftTitle } = filters;
    
    if(!data) {
        return [];
    }

    if(categories.size) {
        data = data.filter( nft => categories.has(nft.category));
    }
    if(status.size) {
        data = data.filter( nft => status.has(nft.status));
    }
    if(itemsType.size) {
        data = data.filter( nft => itemsType.has(nft.item_type));
    }
    if(collections.size) {
        data = data.filter( nft => collections.has(nft.collections));
    }
    if(nftTitle.trim().length) {
        let pattern = new RegExp(`${nftTitle.trim()}`, 'gi');
        data = data.filter( nft => nft.title.match(pattern));
    }

    return data;
});

export const assetLikeItems = createSelector(nftFilter, assetLikeBreakdownState, ( filters, nfts ) => {
    let { data } = nfts;
    const { categories, status, itemsType, collections, nftTitle } = filters;
    
    if(!data) {
        return [];
    }

    if(categories.size) {
        data = data.filter( nft => categories.has(nft.category));
    }
    if(status.size) {
        data = data.filter( nft => status.has(nft.status));
    }
    if(itemsType.size) {
        data = data.filter( nft => itemsType.has(nft.item_type));
    }
    if(collections.size) {
        data = data.filter( nft => collections.has(nft.collections));
    }
    if(nftTitle.trim().length) {
        let pattern = new RegExp(`${nftTitle.trim()}`, 'gi');
        data = data.filter( nft => nft.title.match(pattern));
    }

    return data;
});

export const tileLikeItems = createSelector(nftFilter, tileLikeBreakdownState, ( filters, nfts ) => {
    let { data } = nfts;
    const { categories, status, itemsType, collections, nftTitle } = filters;
    
    if(!data) {
        return [];
    }

    if(categories.size) {
        data = data.filter( nft => categories.has(nft.category));
    }
    if(status.size) {
        data = data.filter( nft => status.has(nft.status));
    }
    if(itemsType.size) {
        data = data.filter( nft => itemsType.has(nft.item_type));
    }
    if(collections.size) {
        data = data.filter( nft => collections.has(nft.collections));
    }
    if(nftTitle.trim().length) {
        let pattern = new RegExp(`${nftTitle.trim()}`, 'gi');
        data = data.filter( nft => nft.title.match(pattern));
    }

    return data;
});

export const nftWishlistItems = createSelector(nftFilter, nftWishlistBreakdownState, ( filters, nfts ) => {
    let { data } = nfts;
    const { categories, status, itemsType, collections, nftTitle } = filters;
    
    if(!data) {
        return [];
    }

    if(categories.size) {
        data = data.filter( nft => categories.has(nft.category));
    }
    if(status.size) {
        data = data.filter( nft => status.has(nft.status));
    }
    if(itemsType.size) {
        data = data.filter( nft => itemsType.has(nft.item_type));
    }
    if(collections.size) {
        data = data.filter( nft => collections.has(nft.collections));
    }
    if(nftTitle.trim().length) {
        let pattern = new RegExp(`${nftTitle.trim()}`, 'gi');
        data = data.filter( nft => nft.title.match(pattern));
    }

    return data;
});

export const assetWishlistItems = createSelector(nftFilter, assetWishlistBreakdownState, ( filters, nfts ) => {
    let { data } = nfts;
    const { categories, status, itemsType, collections, nftTitle } = filters;
    
    if(!data) {
        return [];
    }

    if(categories.size) {
        data = data.filter( nft => categories.has(nft.category));
    }
    if(status.size) {
        data = data.filter( nft => status.has(nft.status));
    }
    if(itemsType.size) {
        data = data.filter( nft => itemsType.has(nft.item_type));
    }
    if(collections.size) {
        data = data.filter( nft => collections.has(nft.collections));
    }
    if(nftTitle.trim().length) {
        let pattern = new RegExp(`${nftTitle.trim()}`, 'gi');
        data = data.filter( nft => nft.title.match(pattern));
    }

    return data;
});

export const tileWishlistItems = createSelector(nftFilter, tileWishlistBreakdownState, ( filters, nfts ) => {
    let { data } = nfts;
    const { categories, status, itemsType, collections, nftTitle } = filters;
    
    if(!data) {
        return [];
    }

    if(categories.size) {
        data = data.filter( nft => categories.has(nft.category));
    }
    if(status.size) {
        data = data.filter( nft => status.has(nft.status));
    }
    if(itemsType.size) {
        data = data.filter( nft => itemsType.has(nft.item_type));
    }
    if(collections.size) {
        data = data.filter( nft => collections.has(nft.collections));
    }
    if(nftTitle.trim().length) {
        let pattern = new RegExp(`${nftTitle.trim()}`, 'gi');
        data = data.filter( nft => nft.title.match(pattern));
    }

    return data;
});

export const assetItems = createSelector(nftFilter, assetBreakdownState, ( filters, nfts ) => {
    let { data } = nfts;
    const { categories, status, itemsType, collections, nftTitle } = filters;
    
    if(!data) {
        return [];
    }

    if(categories.size) {
        data = data.filter( nft => categories.has(nft.category));
    }
    if(status.size) {
        data = data.filter( nft => status.has(nft.status));
    }
    if(itemsType.size) {
        data = data.filter( nft => itemsType.has(nft.item_type));
    }
    if(collections.size) {
        data = data.filter( nft => collections.has(nft.collections));
    }
    if(nftTitle.trim().length) {
        let pattern = new RegExp(`${nftTitle.trim()}`, 'gi');
        data = data.filter( nft => nft.title.match(pattern));
    }

    return data;
});

export const tileItems = createSelector(nftFilter, tileBreakdownState, ( filters, nfts ) => {
    let { data } = nfts;
    const { categories, status, itemsType, collections } = filters;
    
    if(!data) {
        return [];
    }

    if(categories.size) {
        data = data.filter( tile => categories.has(tile.category));
    }
    if(status.size) {
        data = data.filter( tile => status.has(tile.status));
    }
    if(itemsType.size) {
        data = data.filter( tile => itemsType.has(tile.item_type));
    }
    if(collections.size) {
        data = data.filter( tile => collections.has(tile.collections));
    }

    return data;
});

export const tileGroupItems = createSelector(nftFilter, tileGroupBreakdownState, ( filters, nfts ) => {
    let { data } = nfts;
    const { categories, status, itemsType, collections } = filters;
    
    if(!data) {
        return [];
    }

    if(categories.size) {
        data = data.filter( tileGroup => categories.has(tileGroup.category));
    }
    if(status.size) {
        data = data.filter( tileGroup => status.has(tileGroup.status));
    }
    if(itemsType.size) {
        data = data.filter( tileGroup => itemsType.has(tileGroup.item_type));
    }
    if(collections.size) {
        data = data.filter( tileGroup => collections.has(tileGroup.collections));
    }

    return data;
});
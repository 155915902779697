import { axiosWithToken } from '../utils/functions/apiCallWithToken';

const serverURL = process.env.REACT_APP_SERVER;

export const createTransaction = async (data) => {
    const result = await axiosWithToken(`${serverURL}/transaction/create/`, 'post', data);
    return result;
}

export const fetchTransaction = async (param) => {
    const result = await axiosWithToken(`${serverURL}/transaction/`, 'get', {}, param);
    return result;
}

export const fetchCouponTransaction = async (couponId) => {
    const result = await axiosWithToken(`${serverURL}/transaction/coupon/${couponId}`, 'get', {}, {});
    return result;
}

export const claim = async (data) => {
    const result = await axiosWithToken(`${serverURL}/transaction/claim/`, 'post', data);
    return result;
}

export const fetchRevenueTransaction = async() => {
    const result = await axiosWithToken(`${serverURL}/transaction/revenue`, 'get', {});
    return result;
}
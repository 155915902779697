import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { useHistory } from "react-router";
import axios from 'axios';
import Footer from '../../components/footer';
import * as selectors from '../../../../store/selectors';
import { StyledHeader } from '../../Styles';
import useStyles from "../styles/Authors/authors"
import { useTranslation } from "react-i18next";
import authHeader from "../../../../services/auth-header";
import { fetchNftBids } from "../../../../services/NftService";
import { fetchTileBids, fetchTileGroupBids } from "../../../../services/TileService";
import Header from "../../menu/header";

//SWITCH VARIABLE FOR PAGE STYLE
const theme = 'GREY'; //LIGHT, GREY, RETRO

function MyBids() {
    const {t}=useTranslation();
    const classes=useStyles();
    const history=useHistory();
    const [nftBids, setNftBids] = useState([]);
    const [TileBids, setTileBids] = useState([]);
    const [TileGroupBids, setTileGroupBids] = useState([]);
    const [openMenuNft, setOpenMenuNft] = useState(true);
    const [openMenuLand, setOpenMenuLand] = useState(false);
    const [openMenuLandGroup, setOpenMenuLandGroup] = useState(false);
    const signedUser = useSelector(selectors.user);
    const serverURL = process.env.REACT_APP_SERVER;
  
    useEffect(async () => {
      const nftBidsData = await fetchNftBids();
      if(nftBidsData.status === 200) {
        setNftBids(nftBidsData.data);
      }
      else {
        alert(nftBidsData.response);
      }

      const tileBidsData = await fetchTileBids();
      if(tileBidsData?.status === 200) {
        setTileBids(tileBidsData.data);
      }
      else {
        alert(tileBidsData?.response);
      }

      const tileGroupBidsData = await fetchTileGroupBids();
      if(tileGroupBidsData.status === 200) {
        setTileGroupBids(tileGroupBidsData.data);
      }
      else {
        alert(tileGroupBidsData.response);
      }
      // eslint-disable-next-line
    }, []);

    const handleBtnNft = () => {
        setOpenMenuNft(true);
        setOpenMenuLand(false);
        setOpenMenuLandGroup(false);
        document.getElementById('Nft').classList.add('active');
        document.getElementById('Land')?.classList?.remove('active');
        document.getElementById('LandGroup').classList.remove('active');
    };
    const handleBtnLand = () => {
        setOpenMenuLand(true);
        setOpenMenuLandGroup(false);
        setOpenMenuNft(false);
        document.getElementById('Land').classList.add('active');
        document.getElementById('Nft').classList.remove('active');
        document.getElementById('LandGroup').classList.remove('active');
    };
    const handleBtnLandGroup = () => {
        setOpenMenuLandGroup(true);
        setOpenMenuLand(false);
        setOpenMenuNft(false);
        document.getElementById('LandGroup').classList.add('active');
        document.getElementById('Land')?.classList?.remove('active');
        document.getElementById('Nft').classList.remove('active');
    };

  return (
   <div>
      {/* <GlobalStyles/> */}
      <StyledHeader theme={theme} />
      <Header className="" />
      <section className='jumbotron breadcumb no-bg' style={{backgroundImage: `url(${'./img/background/subheader.jpg'})`}}>
        <div className='mainbreadcumb'>
          <div className='container'>
            <div className='row m-10-hor'>
              <div className='col-12'>
                <h1 className='text-center'>{t("My Bids")}</h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <ul className='de_nav'>
                <li id='Nft' className='active'>
                    <span onClick={handleBtnNft}>{t("NFT")}</span>
                </li>
                <li id='Land' className=''>
                      <span onClick={handleBtnLand}>{t("Land")}</span>
                </li>
                <li id='LandGroup' className=''>
                    <span onClick={handleBtnLandGroup}>{t("Land Group")}</span>
                </li>
            </ul>
            {/* <div className="items_filter centerEl">
            <div className='dropdownSelect one'><Select className='select1' styles={customStyles} menuContainerStyle={{'zIndex': 999}} defaultValue={options[0]} options={options} /></div>
            <div className='dropdownSelect two'><Select className='select1' styles={customStyles} defaultValue={options1[0]} options={options1} /></div>
            </div> */}
            <table className="table de-table table-rank">
              <thead>
                <tr>
                  <th scope="col" className={classes.avatarHeader}>#</th>
                  <th scope="col" className={classes.avatarHeader}>{"Title"}</th>
                  <th scope="col">{"Status"}</th>
                  <th scope="col">{"Created"}</th>
                </tr>
                <tr></tr>
              </thead>
              {openMenuNft && <tbody>
                {
                  nftBids.map((bid, index) => (
                    <tr key={index} onClick={() => history.push({pathname: `/3D_NFT/${bid.Nft.id}/${bid.Nft?.itemId}`})} className={classes.tableRow}>
                      <td>
                      {index+1}
                      </td>
                      <td>{`Nft #${bid.Nft.id}`}</td>
                      <td>{bid.status}</td>
                      <td>{bid.Nft.createdAt.replace('T', ' ').replace('.000Z', '')}</td>
                    </tr>
                  ))
                }
              </tbody>}
              {openMenuLand && <tbody>
                {
                  TileBids.map((bid, index) => (
                    <tr key={index} onClick={() => history.push({pathname: `/tile/${bid.Tile.id}`})} className={classes.tableRow}>
                      <td>
                      {index+1}
                      </td>
                      <td>{`Land #${bid.Tile.id}`}</td>
                      <td>{bid.status}</td>
                      <td>{bid.Tile.createdAt.replace('T', ' ').replace('.000Z', '')}</td>
                    </tr>
                  ))
                }
              </tbody>}
              {openMenuLandGroup && <tbody>
                {
                  TileGroupBids.map((bid, index) => (
                    <tr key={index} onClick={() => history.push({pathname: `/tile-group/${bid.TileGroup.id}`})} className={classes.tableRow}>
                      <td>
                      {index+1}
                      </td>
                      <td>{`Land Group #${bid.TileGroup.id}`}</td>
                      <td>{bid.status}</td>
                      <td>{bid.TileGroup.createdAt.replace('T', ' ').replace('.000Z', '')}</td>
                    </tr>
                  ))
                }
              </tbody>}
            </table>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
}

export default MyBids;
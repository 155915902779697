import React, { memo, useEffect,useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import Pagination from '@material-ui/lab/Pagination';
import { useHistory } from "react-router";

import Footer from '../../components/footer';
import * as selectors from '../../../../store/selectors';
import { fetchAuthorList } from "../../../../store/actions/thunks";
import api from "../../../../core/api";
import { StyledHeader } from '../../Styles';
import useStyles from "../styles/Authors/authors"
import { useTranslation } from "react-i18next";
import Header from "../../menu/header";
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';

//SWITCH VARIABLE FOR PAGE STYLE
const theme = 'GREY'; //LIGHT, GREY, RETRO

const StyledTableCell = withStyles((theme) => ({
  head: {
      backgroundColor: '#30166e',
      color: theme.palette.common.white,
  },
  body: {
      fontSize: 14,
  },
}))(TableCell);

function Authors() {
    const {t}=useTranslation();
    const classes=useStyles();
    const dispatch = useDispatch();
    const history=useHistory();
    const authorsState = useSelector(selectors.authorsState);
    const authors = authorsState.data ? authorsState.data : [];
    const [indexPage, setIndexPage] = useState(0);
    const [selectedPage, setSelectedPage] = useState(1);
    const [orderBy, setOrderBy] = useState(1);
    const [orderDirection, setOrderDirection] = useState('desc')
    const startPageIndex=indexPage*5;
    const endPageIndex=indexPage *5+5;
  
    useEffect(() => {
        dispatch(fetchAuthorList(orderBy, orderDirection));
    }, [dispatch, orderBy, orderDirection]);

    const sortHandler = (orderById) => {
      if(orderById === orderBy) {
        setOrderDirection(orderDirection === 'desc' ? 'asc' : 'desc');
      } else {
        setOrderBy(orderById);
        setOrderDirection('desc');
      }
    }

    const handleSelectPage=(event,newPage)=>{
      setSelectedPage(newPage);
      setIndexPage(newPage-1)
    }
    // const handleRedirectToPath=(path)=>{
    //   history.push({pathname:path})
    // } 

    const handleRedirectToAuthor=(id)=>{
      history.push({pathname:`/author/${id}`})
    }

  return (
   <div>
      {/* <GlobalStyles/> */}
      <StyledHeader theme={theme} />
      <Header className=""/>
      <section className='jumbotron breadcumb no-bg' style={{backgroundImage: `url(${'./img/background/subheader.jpg'})`}}>
        <div className='mainbreadcumb'>
          <div className='container'>
            <div className='row m-10-hor'>
              <div className='col-12'>
                <h1 className='text-center'>{t("Authors")}</h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            {/* <div className="items_filter centerEl">
            <div className='dropdownSelect one'><Select className='select1' styles={customStyles} menuContainerStyle={{'zIndex': 999}} defaultValue={options[0]} options={options} /></div>
            <div className='dropdownSelect two'><Select className='select1' styles={customStyles} defaultValue={options1[0]} options={options1} /></div>
            </div> */}
            <TableContainer component={Paper} style={{maxHeight: 500}}>
              <Table className={classes.table} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell scope="col" className={classes.avatarHeader}>{t("Author")}</StyledTableCell>
                    <StyledTableCell scope="col" sortDirection={orderBy === 0 ? orderDirection : false}>
                      <TableSortLabel direction={orderBy === 0 ? orderDirection : 'asc'} className={classes.sortLabel} onClick={() => sortHandler(0)}>{"Username"}</TableSortLabel>
                    </StyledTableCell>
                    <StyledTableCell scope="col" sortDirection={orderBy === 1 ? orderDirection : false}>
                      <TableSortLabel direction={orderBy === 1 ? orderDirection : 'asc'} className={classes.sortLabel} onClick={() => sortHandler(1)}>{"Number of Items"}</TableSortLabel>
                    </StyledTableCell>
                    <StyledTableCell scope="col">{"Number of Likes"}</StyledTableCell>
                  </TableRow>
                  <TableRow></TableRow>
                </TableHead>
                <TableBody>
                  {
                    authors && authors.slice(startPageIndex,endPageIndex).map((author, index) => (
                      <TableRow key={index} onClick={() => handleRedirectToAuthor(author.id)} className={classes.tableRow}>
                        <StyledTableCell className={classes.avatarCell}>
                        {/* <Link to={`/author/${author.id}`}  onClick={()=>handleRedirectToAuthor(author.id)}> */}
                          <div className="author_list_pp author_list_pp_authors" >
                            { author.picture ?
                              <img className="lazy" src={`${api.baseUrl}/${author.picture}`} alt=""/>
                              : <img className="lazy" src="../../img/author/author-11.png" alt=""/>
                            }
                          </div> 
                        </StyledTableCell>
                        <StyledTableCell>{author.username}</StyledTableCell>
                        <StyledTableCell>{author?.Assets?.length}</StyledTableCell>
                        <StyledTableCell>{author?.Likes?.length}</StyledTableCell>
                      </TableRow>
                    ))
                  }
                </TableBody>
              </Table>
            </TableContainer>
            <div className={classes.root}>
              <Pagination
              page={selectedPage} 
              count={Math.ceil(authors.length/5)} 
              onChange={handleSelectPage}
               variant="outlined"
                shape="rounded" />

            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
}

export default memo(Authors);
const InitaleState={
    isOpen:false,
}
 
 
 const openModalNftDetailsReducer=(state=InitaleState,action)=>{
    switch (action.type) {
        case "OPEN_MOADL_NFT_DETAILS":            
          return action.payload    
        default:
          return   state;
    }
}
export default openModalNftDetailsReducer
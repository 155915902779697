import React from "react";
import { useSelector, useDispatch } from 'react-redux';

import Footer from '../../components/footer';
import * as selectors from '../../../../store/selectors';
import api from "../../../../core/api";
import { StyledHeader } from '../../Styles';
import useStyles from "../styles/Authors/authors"
import { useTranslation } from "react-i18next";
import parse from 'html-react-parser';
import * as NotificationService from '../../../../services/NotificationService';
import * as actions from '../../../../store/actions/thunks';
import * as setActions from '../../../../store/actions';
import { Typography } from '@material-ui/core';
import Header from "../../menu/header";

//SWITCH VARIABLE FOR PAGE STYLE
const theme = 'GREY'; //LIGHT, GREY, RETRO

function Notifications() {
    const {t}=useTranslation();
    const classes=useStyles();
    const notificationsState = useSelector(selectors.notificationsState)
    const notifications = notificationsState.data ? notificationsState.data : [];
    const signedUser = useSelector(selectors.user);
    const dispatch = useDispatch();
  
    const clearAllNotifications = async () => {
      dispatch(setActions.setLoading(true));
      await NotificationService.clearNotifications();
      dispatch(setActions.setLoading(false));
      dispatch(actions.fetchNotifications());
    }

  return (
   <div>
      {/* <GlobalStyles/> */}
      <StyledHeader theme={theme} />
      <Header className="" />
      <section className='jumbotron breadcumb no-bg' style={{backgroundImage: `url(${'./img/background/subheader.jpg'})`}}>
        <div className='mainbreadcumb'>
          <div className='container'>
            <div className='row m-10-hor'>
              <div className='col-12'>
                <h1 className='text-center'>{t("Notifications")}</h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            {/* <div className="items_filter centerEl">
            <div className='dropdownSelect one'><Select className='select1' styles={customStyles} menuContainerStyle={{'zIndex': 999}} defaultValue={options[0]} options={options} /></div>
            <div className='dropdownSelect two'><Select className='select1' styles={customStyles} defaultValue={options1[0]} options={options1} /></div>
            </div> */}
            {notifications.length > 0 ? <table className="table de-table table-rank notification-table">
              <thead>
                <tr>
                  <th scope="col" className={classes.avatarHeader}>{t("")}</th>
                  <th scope="col">{"Description"}</th>
                  <th scope="col">{"Created"}</th>
                </tr>
                <tr></tr>
              </thead>
              <tbody>
                {
                  notifications.map((notification, index) => (
                    <tr key={index} onClick={() => window.location = notification.url} className={classes.tableRow}>
                      <td className={classes.avatarCell}>
                      {/* <Link to={`/author/${author.id}`}  onClick={()=>handleRedirectToAuthor(author.id)}> */}
                        <div className="author_list_pp" >
                            <img className="lazy" src={signedUser.picture ? `${api.baseUrl}/${signedUser.picture}` : "../../../img/author/author-11.png"} alt=""/>
                          {/* <i className="fa fa-check"></i> */}
                        </div> 
                      </td>
                      <td>{parse(notification.desc)}</td>
                      <td>{notification.createdAt.replace('T', ' ').replace('.000Z', '')}</td>
                    </tr>
                  ))
                }
              </tbody>
            </table> : <div style={{textAlign: 'center'}}>
              <Typography style={{marginBottom: 10}}>No new notification exists.</Typography>
            </div>}
          </div>
          {notifications.length > 0 && <div>
            <span onClick={() => clearAllNotifications()} className="btn-main lead m-auto">{"Clear All Notifications"}</span>
          </div>}
        </div>
      </section>
      <Footer />
    </div>
  )
}

export default Notifications;
import { axiosWithToken } from '../utils/functions/apiCallWithToken';
import axios from 'axios';

const serverURL = process.env.REACT_APP_SERVER;

export const fetchExchangeData = async () => {
    // const result = await apiCallWithToken(`${serverURL}/data/exchange/`, 'get', {});
    const result = await axios.get(
        'https://api.coingecko.com/api/v3/simple/price',
        {
            params: {
                ids: 'metagamz',
                vs_currencies: 'usd',
            },
        }
    );
    return result.data.metagamz.usd;
}

export const fetchContinents = async () => {
    const result = await axiosWithToken(`${serverURL}/data/continent/`, 'get', {});
    return result;
}

export const fetchTiers = async () => {
    const result = await axiosWithToken(`${serverURL}/data/tier/`, 'get', {});
    return result;
}

export const fetchSnapshot = async () => {
    const result = await axiosWithToken(`${serverURL}/data/snapshot/`, 'get', {});
    return result;
}

export const fetchSetting = async () => {
    const result = await axiosWithToken(`${serverURL}/data/setting/`, 'get', {});
    return result;
}

export const fetchCompany = async (wallet) => {
    const result = await axiosWithToken(`${serverURL}/data/company/${wallet}`, 'get', {});
    return result;
}

export const fetchRoles = async () => {
    const result = await axiosWithToken(`${serverURL}/data/roles/`, 'get', {});
    return result;
}

export const fetchUsername = async (username) => {
    const result = await axiosWithToken(`${serverURL}/data/username/${username}`, 'get', {});
    return result;
}

export const fetchEmail = async (email) => {
    const result = await axiosWithToken(`${serverURL}/data/email/${email}`, 'get', {});
    return result;
}

export const fetchAsset = async (title) => {
    const result = await axiosWithToken(`${serverURL}/data/asset/${title}`, 'get', {});
    return result;
}

export const fetchCompanies = async () => {
    const result = await axiosWithToken(`${serverURL}/data/company/`, 'get', {});
    return result;
}

export const fetchTiles = async (params) => {
    const result = await axiosWithToken(`${serverURL}/data/tile-map/tile/`, 'get', {}, params);
    return result;
}

export const fetchTileGroups = async (params) => {
    const result = await axiosWithToken(`${serverURL}/data/tile-map/tile-group/`, 'get', {}, params);
    return result;
}

export const fetchNumberOfUsers = async () => {
    const result = await axiosWithToken(`${serverURL}/data/number-of-registered`, 'get', {});
    return result;
}

import React from 'react';
import { Typography } from '@material-ui/core';

import COINS from "../../assets/images/EIV_BACK_COVER_5.jpg";

import { EPATHS } from '../../routes/paths.enum';

import useStyles from "../../styles/components/Home/tradeSection"
import DefaultButton from '../buttons/defaultButton';
import { useTranslation } from 'react-i18next';
import { NavLink, useHistory } from 'react-router-dom';

function TradeSection() {
    const classes=useStyles();
    const {t}=useTranslation();
    const history = useHistory();

  return <div className={classes.root}>
       <div className={classes.blockText}>
        <Typography variant='h1'>{t("Trade")}</Typography>
        <Typography variant='h3'>{t("VirtualDestination")}</Typography>
        <NavLink to={EPATHS.EXPLORE} className={classes.buttonContainer} onClick={() => history.push({pathname:EPATHS.EXPLORE})}>
          <DefaultButton color="primary" variant='outlined' text={t("VisitMarketplace")} />
        </NavLink>
      </div>
      <div>
        <img src={COINS} alt={COINS} className={classes.img}/>
      </div>
</div>;
}

export default TradeSection;

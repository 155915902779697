import { Axios, Canceler } from '../../../core/axios';
import * as actions from '../../actions';
import api from '../../../core/api';
import authHeader from "../../../services/auth-header";
import * as AuthService from "../../../services/AuthService";

export const fetchAuthorList = (orderBy=1, orderDirection='desc') => async (dispatch) => {

  dispatch(actions.setLoading(true));
  dispatch(actions.getAuthorList.request(Canceler.cancel));

  try {
    let params = {};
    params['orderBy'] = orderBy;
    params['orderDirection'] = orderDirection;

    const data = await AuthService.fetchUsers(params);

    dispatch(actions.setLoading(false));
    dispatch(actions.getAuthorList.success(data));
  } catch (err) {
    dispatch(actions.setLoading(false));
    dispatch(actions.getAuthorList.failure(err));
  }
};

export const fetchCurrentAuthor = (authorId) => async (dispatch) => {

  dispatch(actions.setLoading(true));
  dispatch(actions.getCurrentAuthor.request(Canceler.cancel));

  try {
    const data = await AuthService.fetchCurrentUser(authorId);

    dispatch(actions.setLoading(false));
    dispatch(actions.getCurrentAuthor.success(data));
  } catch (err) {
    dispatch(actions.setLoading(false));
    dispatch(actions.getCurrentAuthor.failure(err));
  }
};

export const fetchAuthorRanking = () => async (dispatch) => {

  dispatch(actions.getAuthorRanking.request(Canceler.cancel));

  try {
    const { data } = await Axios.get(`${api.baseUrl}${api.authorsSales}`, {
      cancelToken: Canceler.token,
      params: {},
      headers: authHeader()
    });

    dispatch(actions.getAuthorRanking.success(data));
  } catch (err) {
    dispatch(actions.getAuthorRanking.failure(err));
  }
};

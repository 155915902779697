import Web3 from "web3";
import { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay-ts';
import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Footer from '../../components/footer';
import CouponAbi from "../../../../artifacts/contracts/MarketplaceCoupon.sol/MarketplaceCoupon.json";
import * as selectors from '../../../../store/selectors';
import enableWeb3 from "../../../../utils/functions/enableWeb3";

import { convertDateFormat } from '../../../../utils/functions/convertDateFormat';
import * as CouponService from "../../../../services/CouponService";
import Notification from "../../../../utils/functions/notification";
import Header from "../../menu/header";


const theme = 'GREY'; //LIGHT, GREY, RETRO

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#30166e',
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
  }))(TableCell);
  
const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
    greenField: {
        color: 'green'
    },
    redField: {
        color: 'red'
    },
    transferButtonBlock: {
      display: "flex",
      marginTop: 10,
  },
});

function Coupon() {
    const { t } = useTranslation();
    const web3 = new Web3(Web3.givenProvider);
    const couponAddress = process.env.REACT_APP_COUPON_CONTRACT;
    const couponContract = new web3.eth.Contract(CouponAbi.abi, couponAddress);

    const [isActive, setActive] = useState(false);
    const [coupons, setCoupons] = useState([]);
    const [code, setCode] = useState('');
    const [discount, setDiscount] = useState('');
    const [influencer, setInfluencer] = useState('');
    const [contact, setContact] = useState('');
    const [rewardForInfluencer, setRewardForInfluencer] = useState('');
    const [rewardForContact, setRewardForContact] = useState('');
    const [expired, setExpired] = useState('');
    const [loadingMsg, setLoadingMsg] = useState('')
    const signedUser = useSelector(selectors.user);
    const classes = useStyles();
    

    useEffect(() => {
        CouponService.fetchCoupons().then(res => {
            setCoupons(res)
        })
        // eslint-disable-next-line
    }, [signedUser.id]);

    const onSubmit = async() => {
        setActive(true);
        const enabled = await enableWeb3();
        if (!enabled) {
            Notification('Waiting for metamask sign in.', false);
            return
        }
        setLoadingMsg('Please wait...');
        const account = signedUser.metamask;
        try {
            let currentDate = new Date();
            currentDate.setDate(currentDate.getDate() + parseInt(expired));

            const tx = await couponContract.methods.create(
                influencer, contact, discount, rewardForInfluencer, rewardForContact, Math.floor(currentDate.getTime() / 1000), code
            ).send({from: account});

            const data = {
                'code': code,
                'influencer': influencer,
                'contact': contact,
                'discount': discount,
                'rewardInfluencer': parseInt(rewardForInfluencer),
                'rewardContact': parseInt(rewardForContact),
                'expired': currentDate
            }
            await CouponService.createCoupon(data).then((res) => {
                CouponService.fetchCoupons().then(res => {
                    setCoupons(res)
                });
            });

            setCode('');
            setInfluencer('');
            setContact('');
            setDiscount('');
            setRewardForInfluencer('');
            setRewardForContact('');
            setExpired('');
           
            Notification(`Coupon created`, true);

            setActive(false);
        } catch (error) {
            let msg = (typeof error === 'object') ? error.message : error;
            setActive(false);
            Notification(msg, false);
        }
    }

    const onRemove = async (id, code) => {
        setActive(true);
        try {
            const account = signedUser.metamask;
            await couponContract.methods.remove(code).send({from: account});

            await CouponService.removeCoupon(id).then((res) => {
                CouponService.fetchCoupons().then(res => {
                    setCoupons(res)
                });
            });
            Notification(`Coupon removed`, true);
            setActive(false);
        } catch (error) {
            let msg = (typeof error === 'object') ? error.message : error;
            setActive(false);
            Notification(msg, false);
        }
    }

    return (
        <div>
            <ReactNotifications />
            <Header className="" />
            <section className='jumbotron breadcumb no-bg' style={{backgroundImage: `url(${'./img/background/subheader.jpg'})`}}>
                <div className='mainbreadcumb'>
                    <div className='container'>
                        <div className='row m-10-hor'>
                            <div className='col-12'>
                                <h1 className='text-center'>{t("MyCoupons")}</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <LoadingOverlay
                active={isActive}
                spinner
                text={loadingMsg}
                className='centeredOverlay'>
                <section className="container">
                    <TableContainer component={Paper} style={{maxHeight: 500}}>
                        <Table className={classes.table} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>#</StyledTableCell>
                                    <StyledTableCell>Code</StyledTableCell>
                                    <StyledTableCell>Discount</StyledTableCell>
                                    <StyledTableCell>Influencer</StyledTableCell>
                                    <StyledTableCell>Contact</StyledTableCell>
                                    <StyledTableCell>Reward For Influencer</StyledTableCell>
                                    <StyledTableCell>Reward For Contact</StyledTableCell>
                                    <StyledTableCell>Expired</StyledTableCell>
                                    <StyledTableCell>Claimed</StyledTableCell>
                                    <StyledTableCell></StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {coupons.map((coupon, index) => {
                                return (
                                <TableRow key={index}>
                                    <StyledTableCell>{index + 1}</StyledTableCell>
                                    <StyledTableCell><Link to={`/coupon/${coupon.id}`} style={{ color: 'blue' }}>{coupon.code}</Link></StyledTableCell>
                                    <StyledTableCell>{coupon.discount}</StyledTableCell>
                                    <StyledTableCell>{coupon.influencer}</StyledTableCell>
                                    <StyledTableCell>{coupon.contact}</StyledTableCell>
                                    <StyledTableCell>{coupon.rewardInfluencer}</StyledTableCell>
                                    <StyledTableCell>{coupon.rewardContact}</StyledTableCell>
                                    <StyledTableCell>{convertDateFormat(coupon.expired.replace('T', ' ').replace('.000Z', ''))} (UTC)</StyledTableCell>
                                    <StyledTableCell>{coupon.saleTransactionsCount}</StyledTableCell>
                                    <StyledTableCell><input type="button" className="btn-main" value="Remove" style={{marginTop: 25}} onClick={() => onRemove(coupon.id, coupon.code)} /></StyledTableCell>
                                </TableRow>)
                            })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div className='row mt-4'>
                        <div className='col-2'>
                            <span>Coupon Code</span>
                            <input type="text" className="form-control" onChange={(event) => setCode(event.target.value)} value={code}/>
                        </div>
                        <div className='col-2'>
                            <span>Discount</span>
                            <input type="number" step={0.1} className="form-control" onChange={(event) => setDiscount(event.target.value)} value={discount}/>
                        </div>
                        <div className='col-2'>
                            <span>Influencer</span>
                            <input type="text" className="form-control" onChange={(event) => setInfluencer(event.target.value)} value={influencer} />
                        </div>
                        <div className='col-2'>
                            <span>Contact</span>
                            <input type="text" className="form-control" onChange={(event) => setContact(event.target.value)} value={contact}/>
                        </div>
                        <div className='col-2'>
                            <span>Reward For Influencer</span>
                            <input type="number" step={0.1} className="form-control" onChange={(event) => setRewardForInfluencer(event.target.value)} value={rewardForInfluencer}/>
                        </div>
                        <div className='col-2'>
                            <span>Reward For Contact</span>
                            <input type="number" step={0.1} className="form-control" onChange={(event) => setRewardForContact(event.target.value)} value={rewardForContact}/>
                        </div>
                        <div className='col-2'>
                            <span>Expired</span>
                            <input type="number" step={1} className="form-control" onChange={(event) => setExpired(event.target.value)} value={expired}/>
                        </div>
                        <div className='col-2'>
                            <input type="button" className="btn-main" value="Submit" style={{marginTop: 25}} onClick={() => onSubmit()} />
                        </div>
                    </div>
                </section>
            </LoadingOverlay>
            <Footer />
        </div>
    )
}

export default Coupon
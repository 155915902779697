import { useTranslation } from 'react-i18next';

function ModalGain({onClose, onSubmit, value}) {
    const {t}=useTranslation();
    // const [open, setOpen] = useState(true);

  return (
    <div className='checkout'>
      <div className='maincheckout'>
        <button
          className='btn-close'
          onClick={() => onClose()}
        >
          x
        </button>
        <div className='heading'>
          <h3>{"Please Confirm"}</h3>
        </div>
        <div className='heading'>
        <p>{`Are you sure you wish to claim ${value} USDC from your revenue share`}</p>
        </div>
        <div className='d-flex justify-content-center'>
          <button className='btn-main lead m-3' onClick={onSubmit}>{"Yes"}</button>
          <button className='btn-main lead m-3' onClick={onClose}>{"No"}</button>
        </div>
      </div>
    </div>
  )
}

export default ModalGain;
import { memo, useEffect,useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import Pagination from '@material-ui/lab/Pagination';
import { useParams, useHistory } from "react-router";

import Footer from '../../components/footer';
import * as selectors from '../../../../store/selectors';
import { StyledHeader } from '../../Styles';
import useStyles from "../styles/Authors/authors"
import { useTranslation } from "react-i18next";
import * as actions from "../../../../store/actions/thunks";
import Web3 from "web3";
import authHeader from "../../../../services/auth-header";
import axios from 'axios';
import Header from "../../menu/header";

//SWITCH VARIABLE FOR PAGE STYLE
const theme = 'GREY'; //LIGHT, GREY, RETRO
const serverURL = process.env.REACT_APP_SERVER;

function CollectionDetail() {
    const { assetId } = useParams();
    const {t}=useTranslation();
    const classes=useStyles();
    const dispatch = useDispatch();
    const history=useHistory();

    const nftCount = useSelector(selectors.nftCount);
    const authorsState = useSelector(selectors.authorsState);
    const author = authorsState.data ? authorsState.data[0] : {};
    const [indexPage, setIndexPage] = useState(0);
    const [selectedPage, setSelectedPage] = useState(1);
    const [asset, setAsset] = useState(0);

    const startPageIndex=indexPage*12;
    const endPageIndex=indexPage *12+12;

    useEffect(() => {
      axios.get(`${serverURL}/data/asset/${assetId}/`, { headers: authHeader() }).then(res => {
          setAsset(res.data);
        });
      // eslint-disable-next-line
    }, []);
  
    const handleSelectPage=(event,newPage)=>{
      dispatch(actions.fetchAssetsBreakdown(author.id, newPage-1));
      setSelectedPage(newPage);
      setIndexPage(newPage-1)
    }

    const handleRedirectToEditItem=(id)=>{
      history.push({pathname:`/collection/${id}/history`})
    }

    // const tokenDescription = (description) => {
    //   if(description.length > 20) {
    //     return description.slice(0, 20) + '...';
    //   }
    //   return description;
    // }

  return (
   <div>
      {/* <GlobalStyles/> */}
      <StyledHeader theme={theme} />
      <Header className="" />
      <section className='jumbotron breadcumb no-bg' style={{backgroundImage: `url(${'/img/background/subheader.jpg'})`}}>
        <div className='mainbreadcumb'>
          <div className='container'>
            <div className='row m-10-hor'>
              <div className='col-12'>
                <h1 className='text-center'>{t("NFTs")}</h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='container'>
        <div className='row'>
          <div className='col-lg-12 collection__table_wrapper'>
            <table className="table de-table table-rank">
              <thead>
                <tr>
                  <th scope="col">{"No"}</th>
                  <th scope="col">{"Asset ID"}</th>
                  <th scope="col">{"Is For Bid"}</th>
                  <th scope="col">{"Land ID"}</th>
                  <th scope="col">{"List Item"}</th>
                  <th scope="col">{"NFT Metadata"}</th>
                  <th scope="col">{"Price"}</th>
                  <th scope="col">{"Item ID"}</th>
                  <th scope="col">{"User ID"}</th>
                  <th scope="col">{"Created At"}</th>
                  <th scope="col">{"Updated At"}</th>
                </tr>
                <tr></tr>
              </thead>
              <tbody>
                {
                  asset.Nfts && asset.Nfts.slice(startPageIndex,endPageIndex).map((nft, index) => (
                    <tr key={index} className={classes.tableRow} onClick={(event) => handleRedirectToEditItem(nft.id)}>
                      <td>
                        {index+1}
                      </td>
                      <td>{nft.assetId}</td>
                      <td>
                        {nft.isForBid ? 'Bid for Auction' : 'Fixed Price'}
                      </td>
                      <td>
                        {nft.landId}
                      </td>
                      <td>{nft.listedItem}</td>
                      <td>{nft.nftMetadata}</td>
                      <td>{Web3.utils.fromWei(nft.price, 'gwei') * 1000} EIV</td>
                      <td>{nft?.itemId}</td>
                      <td>{nft?.userId}</td>
                      <td>{nft?.createdAt.replace('T', ' ').replace('.000Z', '')}</td>
                      <td>{nft?.updatedAt.replace('T', ' ').replace('.000Z', '')}</td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
          <div className={classes.root}>
            <Pagination
            page={selectedPage} 
            count={Math.ceil(nftCount/12)} 
            onChange={handleSelectPage}
              variant="outlined"
              shape="rounded" />
          </div>
          <div className="spacer-double"></div>
        </div>
      </section>
      <Footer />
    </div>
  )
}

export default memo(CollectionDetail);
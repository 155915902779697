import { memo, useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay-ts';
import { ReactNotifications } from 'react-notifications-component';
import { Store } from 'react-notifications-component';

import 'react-notifications-component/dist/theme.css';

import Clock from '../../components/Clock';
import Footer from '../../components/footer';
import { createGlobalStyle } from 'styled-components';
import Web3 from "web3";
import * as selectors from '../../../../store/selectors';
import * as actions from '../../../../store/actions/thunks';
import * as setActions from '../../../../store/actions';
import api from '../../../../core/api';
import { useParams, useHistory } from 'react-router';
import Header from '../../menu/header';
import { debounce } from 'lodash';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import { Typography, Dialog, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import MarketplaceAbi from "../../../../artifacts/contracts/Marketplace.sol/Marketplace.json";
import NFTAbi from "../../../../artifacts/contracts/Collectible.sol/Collectible.json";
import SNAPSHOTAbi from "../../../../artifacts/contracts/MarketplaceSnapshot.sol/MarketplaceSnapshot.json";
import erc20Abi from "../../../../artifacts/contracts/IERC20.sol/IERC20.json";
import Nft3dCard from '../../../../components/Cards/nft3DCard';
import useStyles from '../../../../styles/pages/Explore/AssetDetails';
import FormControl from '@material-ui/core/FormControl';
import { login } from '../../../../utils/functions/authenticate';
import { EROLES } from "../../../../utils/constants/roles.enum";
import ModalError from '../../../../components/Servererror/modalError';
import * as TileService from '../../../../services/TileService';
import Allowance from "../../../../utils/functions/allowance";
import Notification from "../../../../utils/functions/notification";
import * as AuthService from "../../../../services/AuthService";
import * as CouponService from "../../../../services/CouponService";
import { BigNumber } from '@ethersproject/bignumber';
import { setTileLike,
  setTileDislike,
  setTileWishlist,
  setTileDiswishlist
} from '../../../../services/TileService';
import enableWeb3 from "../../../../utils/functions/enableWeb3";
import ModalNoMetamask from "../../../../components/Servererror/modalNoMetamask";

const GlobalStyles = createGlobalStyle `
  header#myHeader.navbar.white {
    background: #fff;
    border-bottom: solid 1px #dddddd;
  }
  header#myHeader.navbar .search #quick_search{
    color: #20005e;
    background: rgba(255, 255, 255, .1);
    }
    header#myHeader.navbar.white .btn, .navbar.white a{
        color: #20005e;
    }
    header#myHeader.navbar.sticky.white .btn, .navbar.sticky.white a{
        color: #20005e;
    }
    header#myHeader .dropdown-toggle::after{
        color: #20005e;
    }
  .mr40{
    margin-right: 40px;
  }
  .mr15{
    margin-right: 15px;
  }
  .btn2{
    background: #f6f6f6;
    color: #8364E2 !important;
  }
  @media only screen and (max-width: 1199px) {
    .navbar{
      background: #403f83;
    }
    .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
      background: #111;
    }
    .item-dropdown .dropdown a{
      color: #111 !important;
    }
  }
`;

const TileDetails = () => {
  const { landId } = useParams();
  const [marketplaceContract, setMarketplaceContract] = useState();
  const [nftContract, setNftContract] = useState();
  const [snapshotContract, setSnapshotContract] = useState();
  const [eivContract, setEivContract] = useState();
  const [isPriceError, setPriceError] = useState(false);
  const [isBidAvailable, setBidAvailable] = useState(true);
  const [sellPrice, setSellPrice] = useState("");
  const [isForBid, setIsForBid] = useState(false);

  const [isActive, setActive] = useState(false);
  const [bidAmount, setBidAmount] = useState();
  // eslint-disable-next-line
  const [isListed, setIsListed] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('Please wait...');
  const [vaultAmount, setVaultAmount] = useState(0);
  const [vaultPercent, setVaultPercent] = useState(0);
  const [isVaultChecked, setVaultChecked] = useState(false);
  const [exchangeRate, setExchangeRate] = useState(false);
  const [exchangeRateScale, setExchangeRateScale] = useState(false);
  const [coupon, setCoupon] = useState("");
  const [isCouponError, setCouponError] = useState(false);
  const [isCouponSet, setCouponSet] = useState(false);
  const [isCouponOverdue, setCouponOverdue] = useState(false);
  const [isFetchingCoupon, setFetchingCoupon] = useState(false);

  const [actionType, setActionType] = useState();
  const [render, setRender] = useState(false);

  const signedUser = useSelector(selectors.user);
  const authorsState = useSelector(selectors.authorsState);
  const authors = authorsState.data ? authorsState.data : [];

  const currentAuthorState = useSelector(selectors.currentAuthorState);
  const holder = currentAuthorState.data ? currentAuthorState.data : {};

  const walletAddress = process.env.REACT_APP_COMPANY_WALLET_ADDRESS;
  const nftAddress = process.env.REACT_APP_NFT_ADDRESS;
  const marketplaceAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
  const snapshotAddress = process.env.REACT_APP_SNAPSHOT_CONTRACT;
  const eivAddress = process.env.REACT_APP_EIV_ADDRESS;
  const SNOWTRACE_URL = process.env.REACT_APP_SNOWTRACE_URL;

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const web3 = window.ethereum ? new Web3(Web3.givenProvider) : null;

  const [price, setPrice] = useState("");
  const [couponPrice, setCouponPrice] = useState("");
  // eslint-disable-next-line
  const [signer, setSigner] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showTransferNft, setShowTransferNft] = useState(false);
  const [confirmTransferNft, setConfirmTransferNft] = useState(false);
  const [isSendClicked, setSendClicked] = useState(false);
  const [transferAddress, setTransferAddress] = useState(0);
  const [priceRate, setPriceRate] = useState(0);
  const [openMenu, setOpenMenu] = useState(false);
  const [royalty, setRoyalty] = useState(0);
  const [txProcessing, setTxProcessing] = useState(false);
  const history = useHistory();
  const classes=useStyles();

  const notificationsState = useSelector(selectors.notificationsState);
  const notifications = notificationsState.data ? notificationsState.data : [];

  const [openCheckout, setOpenCheckout] = useState(false);
  const [openCheckoutbid, setOpenCheckoutbid] = useState(false);
  const [openWithdrawBid, setOpenWithdrawBid] = useState(false);
  const [openAddListing, setOpenAddListing] = useState(false);

  // eslint-disable-next-line
  const [countLike, setCountLike] = useState();
  const [alertMessage, setAlertMessage] = useState('');
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [transferAddressList, setTransferAddressList] = useState([]);
  const [searchAddress, setSearchAddress] = useState();

  // useEffect(() => {
  //   fetchPriceRate();
  //   const timer = setTimeout(() => {
  //     fetchPriceRate();
  //   }, 60000);
  //   return () => clearTimeout(timer);
  // }, []);
  let nft = useSelector(state=>state.NFT.tileDetail.data);

  useEffect(() => {
    if(nft && parseFloat(price) <= parseFloat(Web3?.utils?.fromWei(nft.price, 'ether'))) {
      setPriceError(true);
    } else {
      setPriceError(false);
    }
    // eslint-disable-next-line
  }, [price]);

  useEffect(() => {
    if(nft && nft.txHash) {
      const tx = JSON.parse(nft.txHash);
      web3.eth.getTransaction(tx.hash).then(res => {
        if(res.from.toLowerCase() === signedUser.metamask) {
          setTxProcessing(true);
        }
        else {
          setTxProcessing(true);
        }
      })
    }
  }, [nft?.txHash, signedUser])

  // const fetchPriceRate = () => {
  //   DataService.fetchExchangeData().then((res => {
  //     setPriceRate(res);
  //   }));
  // }

  const handleBtnClick = () => {
    setOpenMenu(true);
    document.getElementById('Mainbtn').classList.add('active');
  };

  const fetchAddress = (value) => {
    setTransferAddressList([]);
    if(value === '' || value === null) {
      setTransferAddress(0);
    }
    else {
      AuthService.fetchUsersByAddress({address: value}).then(res => {
        setTransferAddressList(res);
      });
    }
  }

  const fetchCoupon = (value, amount) => {
    setFetchingCoupon(true);
    if(value === '' || value === null) {
      setCouponError(false);
      setCouponPrice(amount);
      setCoupon('');
      setCouponSet(false);
      setFetchingCoupon(false);
      setCouponOverdue(false);
    }
    else {
      CouponService.fetchCoupon(value).then(res => {
        if (res) {
          if (res === true) {
            setCouponError(true);
            setCouponSet(false);
            setCoupon('');
            setCouponPrice(amount);
            setFetchingCoupon(false);
            setCouponOverdue(true);
          }
          else {
            setCouponError(false);
            setCouponSet(true);
            setCoupon(res.id);
            setCouponPrice(parseInt(amount * (1 - res.discount / 100)));
            setFetchingCoupon(false);
            setCouponOverdue(false);
          }
        } else {
          setCouponError(true);
          setCouponPrice(amount);
          setCoupon('');
          setCouponSet(false);
          setFetchingCoupon(false);
          setCouponOverdue(false);
        }
      });
    }
  }

  const [debounceFetchAddress] = useState(() => debounce(fetchAddress, 1000));
  const [debounceFetchCoupon] = useState(() => debounce(fetchCoupon, 1000));

  useEffect(() => {
    init().then((res) => {
      dispatch(actions.fetchTileDetail(landId));
    });
    // dispatch(actions.fetchAssetsBreakdown(landId));
    // eslint-disable-next-line
  }, [dispatch, landId]);

  useEffect(() => {
    setCoupon('');
    setCouponPrice(parseFloat(nft?.price));
    setCouponError(false);
  }, [openCheckout]);

  useEffect(() => {
    if(openAddListing) {
      marketplaceContract.methods.getNFTcommission(nft.tokenId).call().then(res => {
        const [_, commission, royaltyAmount, authorRoyaltyAmount] = Object.values(res).map(Number);
        setRoyalty(royaltyAmount / 10);
      })
    }
  }, [openAddListing])

  useEffect(() => {   
    setCountLike(nft?.likes); 
    if (signedUser.id && nft && marketplaceContract) {
      setCouponPrice(parseFloat(nft?.price));

      marketplaceContract.methods.EXCHANGE_RATE_SCALE().call().then(result => {
        setExchangeRateScale(parseFloat(result));
      });

      marketplaceContract.methods.exchangeRate().call().then(result => {
        setExchangeRate(parseFloat(result));
      });

      dispatch(actions.fetchCurrentAuthor(walletAddress));
    }
    // eslint-disable-next-line
  }, [signedUser.id, nft, marketplaceContract, priceRate]);

  const init = async() => {
    if(window.ethereum) {
      const marketplaceContract = new web3.eth.Contract(MarketplaceAbi.abi, marketplaceAddress);
      const nftContract = new web3.eth.Contract(NFTAbi.abi, nftAddress);
      const eivContract = new web3.eth.Contract(erc20Abi.abi, eivAddress);
      const snapshotContract = new web3.eth.Contract(SNAPSHOTAbi.abi, snapshotAddress);

      setMarketplaceContract(marketplaceContract);
      setNftContract(nftContract)
      setSnapshotContract(snapshotContract);
      setEivContract(eivContract);
    }
  }

  const auth = async () => {
    if (window.ethereum) {
      login(dispatch, setActions.setLoading, setActions.setUser, history, setServerError);
    }
  };
  
  const handleBuyNow = () => {
    if (signedUser.id) {
      dispatch(setActions.setLoading(true));
      AuthService.sessionCheck().then((res) => {
        dispatch(setActions.setLoading(false));
        snapshotContract.methods.getInfo(signedUser.metamask).call().then(result => {
          setOpenCheckout(true);
          setCoupon("");
          setVaultChecked(false);
          setVaultAmount(parseFloat(Web3.utils.fromWei(result[0], 'mwei')));

          snapshotContract.methods.EQUITY_INITIAL_SUPPLY().call().then(initialSupply => {
            const nftPrice = BigNumber.from(nft?.price);
            const balance = BigNumber.from(result[0]);
            const exchangeRateScaled = nftPrice.mul(exchangeRateScale).div(exchangeRate);
            const subtracted = balance.sub(exchangeRateScaled);
            const divided = parseFloat(Web3.utils.fromWei(subtracted.toString(), 'mwei')) * 100 / parseFloat(Web3.utils.fromWei(initialSupply, 'mwei'));
            setVaultPercent(divided);
          })
        });
      })
      .catch(err => {
        dispatch(setActions.setLoading(false));
        if(err.response?.status == '401') {
          dispatch(actions.setIsTimeout(true));
        }
      })
    } else {
      setShowModal(true);
    }
  };

  const buyForBid = async (bidder) => {
    setActive(true);
    // await enableWeb3();
    try {
      setActive(false);
    } catch(error) {
      setActive(false)
    }
  }

  const buyMintNFT = async (isVault) => {
    const enabled = await enableWeb3();
    if (!enabled) {
      Notification('Waiting for metamask sign in.', false);
      return
    }
    setActive(true);
    setLoadingMessage("Please wait while you purchase.")
    const account = signedUser.metamask;
    try {
      // const amount = Web3.utils.toBN(couponPrice).add(Web3.utils.toBN(Web3.utils.toWei('0.001', 'mwei')));
      const amount = Web3.utils.toBN(couponPrice).add(Web3.utils.toBN(Web3.utils.toWei('0', 'mwei')));
      if(!isVault) {
        await Allowance(eivContract, account, marketplaceAddress, amount);
      }
      await TileService.mintTile(nft?.id, {isVault: isVault, coupon: coupon});

      dispatch(actions.fetchTileDetail(nft?.id));
      dispatch(actions.fetchNotifications());
      setOpenCheckout(false);
      setActive(false);
      Notification(`You purchased the Land`, true);

    } catch (error) {
      let msg = (typeof error === 'object') ? error.message : error;
      setActive(false);
      Notification(msg, false);
    }
  }

  const buyNFT = async (isVault) => {
    setActive(true);
    setLoadingMessage("Please wait while you purchase.");
    const enabled = await enableWeb3();
    if (!enabled) {
      Notification('Waiting for metamask sign in.', false);
      return
    }
    const account = signedUser.metamask;
    try {
      if (!isVault) {
        const amount = couponPrice;
        await Allowance(eivContract, account, marketplaceAddress, amount);
      }

      const tx = await marketplaceContract.methods.buyItem(
        nft?.tokenId, true, isVault, coupon ? true : false, coupon).send({from: account});

      await TileService.updateTileTransaction({
        'txHash': JSON.stringify({hash: tx.transactionHash, isVault: isVault, coupon: coupon, type: 0}), //Type 0 is Buy, 1 Listing, 2 Cancel listing
        'type': 0, //Type 0 is Tile, Type 1 is Tile Group
        'id': nft.id
      });

      dispatch(actions.fetchTileDetail(nft?.id));

      const metadata = {
        'txHash': tx.transactionHash,
        'tokenId': nft.tokenId,
        'isVault': isVault,
        'coupon': coupon
      }

      await TileService.buyTile(nft.id, metadata);

      dispatch(actions.fetchNotifications());
      dispatch(actions.fetchTileDetail(nft?.id));
      setOpenCheckout(false);
      setActive(false);
      Notification(`You purchased the Land ${nft?.Continent?.name}_${nft?.posX}_${nft?.posY}_${nft?.posZ} #${nft.itemId}.`, true);

    } catch (error) {
      let msg = (typeof error === 'object') ? error.message : error;
      setActive(false);
      Notification(msg, false);
    }
  }

  const admint = async () => {
    setActive(true);
    setLoadingMessage("Please wait while you admint.");
    try {
      await TileService.admintTile(nft?.id, {"isAdmint": true});

      dispatch(actions.fetchTileDetail(nft?.id));
      dispatch(actions.fetchNotifications());
      setOpenCheckout(false);
      setActive(false);
      Notification(`You adminted a Land.`, true);

    } catch (error) {
      let msg = (typeof error === 'object') ? error.message : error;
      setActive(false);
      Notification(msg, false);
    }
  }

  const sendManualTransfer = async () => {
    dispatch(setActions.setLoading(true));
    AuthService.sessionCheck().then(async (res) => {
      dispatch(setActions.setLoading(false));
      const enabled = await enableWeb3();
      if (!enabled) {
        Notification('Waiting for metamask sign in.', false);
        return
      }
      setActive(true);
      try {
        const metadata = {
          'receiverAddress': transferAddress.metamask,
          'receiverId': transferAddress.id,
        };

        await TileService.transferTile(nft.id, metadata);

        setShowTransferNft(false)
        setActive(false);

        dispatch(actions.fetchNotifications());
        dispatch(actions.fetchTileDetail(nft?.id));

        Notification(`Your Land #${nft?.tokenId} has been transferred successfully.`, true);
      } catch (error) {
        let msg = (typeof error === 'object') ? error.message : error;
        setActive(false);
        setShowTransferNft(false)
        Notification(msg, false);
      }
    })
    .catch(err => {
      dispatch(setActions.setLoading(false));
      if(err.response?.status == '401') {
        dispatch(actions.setIsTimeout(true));
      }
    })
  }

  const handleSearchAddress = (value) => {
    setSearchAddress(value);
    debounceFetchAddress(value);
  }

  const cancelListing = async () => {
    dispatch(setActions.setLoading(true));
    AuthService.sessionCheck().then(async (res) => {
      dispatch(setActions.setLoading(false));
      setOpenWithdrawBid(false);
      setActive(true);
      const enabled = await enableWeb3();
      if (!enabled) {
        Notification('Waiting for metamask sign in.', false);
        return
      }

      try {
        const account = signedUser.metamask;
        const txRes = await marketplaceContract.methods.cancel(nft.tokenId).send({from: account});

        await TileService.updateTileTransaction({
          'txHash': JSON.stringify({hash: txRes.transactionHash, type: 2}), //Type 0 is Buy, 1 Listing, 2 Cancel listing
          'type': 0, //Type 0 is Tile, Type 1 is Tile Group
          'id': nft.id
        });
  
        dispatch(actions.fetchTileDetail(nft?.id));

        const metadata = {
          'txHash': txRes.transactionHash
        };

        await TileService.cancelTileListing(nft?.id, metadata);

        dispatch(actions.fetchTileDetail(nft?.id));
        dispatch(actions.fetchNotifications());
        setActive(false);
        setIsListed(true);

        Notification('Cancelled to sell the Land.', true);

      } catch (error) {
        let msg = (typeof error === 'object') ? error.message : error;
        setActive(false);
        Notification(msg, false);
        return false
      }
    })
    .catch(err => {
      dispatch(setActions.setLoading(false));
      if(err.response?.status == '401') {
        dispatch(actions.setIsTimeout(true));
      }
    })
  }

  const handleAddListing = async () => {
    if (signedUser.id) {
      dispatch(setActions.setLoading(true));
      setLoadingMessage("Please wait while you list your NFT for sale.")
      AuthService.sessionCheck().then(async (res) => {
        dispatch(setActions.setLoading(false));
        setOpenAddListing(true);
        setOpenCheckoutbid(false);
        setOpenCheckout(false);
        setOpenWithdrawBid(false);
      })
      .catch(err => {
        dispatch(setActions.setLoading(false));
        if(err.response?.status == '401') {
          dispatch(actions.setIsTimeout(true));
        }
      })
    } else {
      setShowModal(true);
    }
  }

  const handleBid = async () => {
    if (signedUser.id) {
      setOpenCheckoutbid(true);
      setOpenCheckout(false);
      setOpenWithdrawBid(false);
      setOpenAddListing(false);
    } else {
      setShowModal(true);
    }
  }

  const handleWithdrawBid = async () => {
    if (signedUser.id) {
      setOpenWithdrawBid(true);
      setOpenCheckoutbid(false);
      setOpenCheckout(false);
      setOpenAddListing(false);
    } else {
      setShowModal(true);
    }
  }

  const placeBid = async () => {
    // await enableWeb3();
    setOpenCheckoutbid(false);
    setActive(true);
    try {
    } catch (error) {
      setActive(false);
      Store.addNotification({
        title: "Failed",
        message: `${error}`,
        type: "danger",
        insert: "top",
        container: "bottom-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          onScreen: true,
          showIcon: true
        }
      });
      return false
    }
  }

  const withdrawBid = async () => {
    // await enableWeb3();
    setOpenWithdrawBid(false);
    setActive(true);
    try {
      const account = signedUser.metamask;

      const response = await marketplaceContract.methods.cancelBid(nft.tokenId).send({from: account});

      dispatch(actions.fetchTileDetail(nft?.id));
      
      setActive(false);
      setBidAvailable(true);
      Store.addNotification({
        title: "Success",
        message: `You withdrawn the bid.`,
        type: "success",
        insert: "top",
        container: "bottom-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          onScreen: true,
          showIcon: true
        }
      });
    } catch (error) {
      setActive(false);
      Store.addNotification({
        title: "Failed",
        message: `${error}`,
        type: "danger",
        insert: "top",
        container: "bottom-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          onScreen: true,
          showIcon: true
        }
      });
      return false
    }
  }

  const addListing = async () => {
    setActive(true);
    const enabled = await enableWeb3();
    if (!enabled) {
      Notification('Waiting for metamask sign in.', false);
      return
    }
    const account = signedUser.metamask;
    try {
      const amount = Web3.utils.toWei(sellPrice, 'mwei');

      const approvedAddress = await nftContract.methods.getApproved(nft?.tokenId).call();
      if (approvedAddress !== marketplaceAddress) {
        await nftContract.methods.approve(marketplaceAddress, nft?.tokenId).send({from: account});
      }
      const txRes = await marketplaceContract.methods.list(nft?.tokenId, amount).send({from: account});

      await TileService.updateTileTransaction({
        'txHash': JSON.stringify({hash: txRes.transactionHash, price: amount, type: 1}), //Type 0 is Buy, 1 Listing, 2 Cancel listing
        'type': 0, //Type 0 is Tile, Type 1 is Tile Group
        'id': nft.id
      });

      dispatch(actions.fetchTileDetail(nft?.id));

      const metadata = {
        'price': amount,
        'txHash': txRes.transactionHash
      };

      await TileService.addTileListing(nft?.id, metadata);

      setOpenAddListing(false);
      dispatch(actions.fetchTileDetail(nft?.id));
      dispatch(actions.fetchNotifications());
      setActive(false);
      setIsListed(true);

      Notification('Land has been added for sale.', true);

    } catch (error) {
      let msg = (typeof error === 'object') ? error.message : error;
      setActive(false);
      Notification(msg, false);
      return false
    }
  }

  const handleSearchCoupon = (value) => {
    debounceFetchCoupon(value, parseInt(nft?.price));
  }

  const handleUpdateNFT = async () => {
    setActive(true);
    setLoadingMessage("Please wait while you update metadata.");
    try {
      await TileService.updateTileMetadata(nft?.id);

      dispatch(actions.fetchNotifications());
      setOpenCheckout(false);
      setActive(false);
      Notification(`You updated the metadata for this Land.`, true);

    } catch (error) {
      let msg = (typeof error === 'object') ? error.message : error;
      setActive(false);
      Notification(msg, false);
    }
  }

  const handleManualRegister = async () => {
    setActive(true);
    const tx = JSON.parse(nft.txHash);
    if (tx.type === 0) {
      const metadata = {
        'txHash': tx.hash,
        'tokenId': nft.tokenId,
        'isVault': tx.isVault,
        'coupon': tx.coupon
      }

      await TileService.buyTile(nft.id, metadata);
    } else if (tx.type === 1) {
      const metadata = {
        'price': tx.price,
        'txHash': tx.hash
      };

      await TileService.addTileListing(nft?.id, metadata);
    } else if (tx.type === 2) {
      const metadata = {
        'txHash': tx.hash
      };

      await TileService.cancelTileListing(nft?.id, metadata);
    }
    dispatch(actions.fetchTileDetail(nft?.id));
    dispatch(actions.fetchNotifications());
    setActive(false);
  }

  const isVoted = useRef(false);
  const isWishlisted = useRef(false);

  useEffect(() => {
    if(nft?.TileLikes) {
      for(let i=0; i<nft?.TileLikes?.length; i++) {
        if(signedUser?.metamask === nft?.TileLikes[i]?.User?.metamask?.toLowerCase()) {
          isVoted.current = true;
        }
      }
    }

    if(nft?.TileWishlists) {
      for(let i=0; i<nft?.TileWishlists?.length; i++) {
        if(signedUser?.metamask === nft?.TileWishlists[i]?.User?.metamask?.toLowerCase()) {
          isWishlisted.current = true;
        }
      }
    }

    if(nft?.TileLikes) {
      setCountLike(nft?.TileLikes?.length);
    }
  }, [nft?.id]);

  useEffect(() => {
    setRender(!render);
  }, [isVoted.current, isWishlisted.current, nft?.TileLikes?.length]);

  const handleLike = async (userData) => {
    setActionType();
    // if(nft?.TileLikes) {
    //     for(let i=0; i<nft?.TileLikes.length; i++) {
    //         if(userData.metamask === nft?.TileLikes[i].User.metamask.toLowerCase()) {
    //             isVoted.current = true;
    //         }
    //     }
    // }
    if(!isVoted.current) {
      dispatch(setActions.setLoading(true));
      try {
        const res = await setTileLike(nft?.id);
        let totalLikes = res.likes;
        setCountLike(totalLikes);
        setShowAlertModal(true);
        setAlertMessage('Your like has been added.');
        isVoted.current = true;
        dispatch(actions.fetchNotifications());
        dispatch(actions.fetchAssetLikeBreakdown(userData.id, 0));
      } catch (error) {
        let msg = (typeof error === 'object') ? error.message : error;
        Notification(msg, false);
      }
      dispatch(setActions.setLoading(false));
    }
    else {
      dispatch(setActions.setLoading(true));
      try {
        const res = await setTileDislike(nft.id);
        setCountLike(res.likes);
        dispatch(actions.fetchNotifications());
        setShowAlertModal(true);
        isVoted.current = false;
        setAlertMessage('Your like has been removed.');
        dispatch(actions.fetchAssetLikeBreakdown(userData.id, 0));
      } catch(error) {
        let msg = (typeof error === 'object') ? error.message : error;
        Notification(msg, false);
      }
      dispatch(setActions.setLoading(false));
    }
  }

  const onClickLike = () => {
    if(signedUser.id) {
      handleLike(signedUser);
    }
    else {
      setShowModal(true);
      setActionType('like');
    }
  }

  const handleWishlist = async (userData) => {
    setActionType();

    // if(nft?.TileWishlists) {
    //     for(let i=0; i<nft?.TileWishlists.length; i++) {
    //         if(userData.metamask === nft?.TileWishlists[i].User.metamask.toLowerCase()) {
    //             isWishlisted.current = true;
    //         }
    //     }
    // }

    if(!isWishlisted.current) {
      dispatch(setActions.setLoading(true));
      try {
          const res = await setTileWishlist(nft?.id);
          dispatch(actions.fetchNotifications());
          setShowAlertModal(true);
          isWishlisted.current = true;
          setAlertMessage('This Asset has been added to your wishlist.');
          dispatch(actions.fetchAssetWishlistBreakdown(userData.id, 0));
      } catch(error) {
        let msg = (typeof error === 'object') ? error.message : error;
        Notification(msg, false);
      }
      dispatch(setActions.setLoading(false));
    }
    else {
      dispatch(setActions.setLoading(true));
      try {
        const res = await setTileDiswishlist(nft.id);
        dispatch(actions.fetchNotifications());
        setShowAlertModal(true);
        isWishlisted.current = false;
        setAlertMessage('This Asset has been removed from your wishlist.');
        dispatch(actions.fetchAssetWishlistBreakdown(userData.id, 0));
      } catch(error) {
        let msg = (typeof error === 'object') ? error.message : error;
        Notification(msg, false);
      }
      dispatch(setActions.setLoading(false));
    }
  }

  const onClickBasket = () => {
    if(signedUser.id) {
      handleWishlist(signedUser);
    }
    else {
      setShowModal(true);
      setActionType('wishlist');
    }
  }

  const handleAuth = async () => {
    const userData = await login(dispatch, setActions.setLoading, setActions.setUser, history, setServerError);
    setShowModal(false);
    if(userData && actionType === 'like') {
      handleLike(userData);
    }
    if(userData && actionType === 'wishlist') {
      handleWishlist(userData);
    }
  }

  return (
    <div>
      <LoadingOverlay
        active={isActive}
        spinner
        text={loadingMessage}>
      <GlobalStyles />
      {/* <StyledHeader theme={theme} /> */}
      <Header className='HOME' />
      <ReactNotifications />
      <section className='container'>
        <div className='row mt-md-5 pt-md-4'>
          <div className='col-md-6 text-center'>
          { nft && nft.gltf &&
              <Nft3dCard src={`${api.baseUrl}/${nft.directory}/${nft.gltf}`} width={450} height={450} />
          }
          { nft && !nft.gltf && <img src={api.ipfsURL + nft.image} className="img-fluid img-rounded mb-sm-30" alt=""/>}
          {nft && nft.gltf && <p>To view this 3D Assets, click on the image and move your mouse. You can also mouse scroll to zoom-in and zoom-out in the preview</p>}
          </div>
          <div className='col-md-6'>
            <div className='item_info'>
              {nft && nft.item_type === 'on_auction' && (
                <>
                  {t("AuctionEnd")}
                  <div className='de_countdown'>
                    <Clock deadline={nft.deadline} />
                  </div>
                </>
              )}
              <h2>{nft && nft.title}</h2>
              <div className='item_info_counts nft__item_info'>
                {/* <div className='item_info_type'>
                  <i className='fa fa-image'></i>
                  {nft && nft.category}
                </div>
                <div className='item_info_views'>
                  <i className='fa fa-eye'></i>
                  {nft && nft.views}
                </div> */}
                <div className={isVoted.current ? "details__item_liked" : "details__item_like"} onClick={() => onClickLike()}>
                  <i className="fa fa-heart"></i><span>{countLike}</span>
                </div>
                <div className={isWishlisted.current ? "details__item_liked" : "details__item_like"} onClick={() => onClickBasket()}>
                  <i className="fa fa-shopping-basket"></i>
                </div> 
              </div>
              {nft?.minted && <p>Item ID: #{nft?.itemId}</p>}
              {nft?.minted && <p>On-Chain Reference: <a href={`${SNOWTRACE_URL}${nftAddress}/${nft?.tokenId}`} target="_blank"><u>#{nft && nft?.tokenId}</u></a></p>}
              <p>Continent: {nft?.Continent?.name}</p>
              <p>Type: {nft?.tileType}</p>
              <p>Pos X: {nft?.posX}</p>
              <p>Pos Y: {nft?.posY}</p>
              <p>Pos Z: {nft?.posZ}</p>
              <p>Type: {nft?.vvip ? 'VVIP' : nft?.Tier?.name} </p>
              {nft && nft.price && <p>
              { nft?.ownerId === signedUser?.id ?
                "You own this parcel" 
                :(nft?.isInfrastructure ?
                  "Not for Sale"
                  : (
                    nft.isForSale 
                      ? `Price in USDC : ${Web3.utils.fromWei(nft?.price, 'gwei') * 1000} USDC`
                      : "Not for Sale"
                  )
                )
              }</p>}
              {nft?.description && <><p>Description: </p><p>{nft?.description}</p></>}
              {/* <p>Price in USDT : {nft && nft.price && (Web3.utils.fromWei(nft.price, 'mwei') * priceRate).toFixed(2)} USDT</p> */}
              {/* <p>1 EIV = {priceRate} USDT {roi ? (roi > 0 ? <span className="roi roi-positive">+{roi}%</span> : <span className="roi roi-negetive">{roi}%</span>) : null}</p> */}
              <div className='d-flex flex-row'>
                {nft && nft.User && <div className='mr40'>
                  <h6>{t("Owner")}</h6>
                  <div className='item_author'>
                    <div className='author_list_pp'>
                      <span>
                        { nft && nft.User && nft.User.picture ?
                          <img
                            className='lazy'
                            src={
                              nft &&
                              nft.User &&
                              `${api.baseUrl}/${nft?.User?.picture}`
                            }
                            alt=''
                            onClick={() => {
                              if(authors.findIndex(e => e.id === nft?.User?.id) > -1)
                                history.push({pathname: `/author/${nft.User.id}`})
                            }}
                          />
                          // <i className='fa fa-check'></i>
                          :
                          <img className="lazy" src="/img/author/author-11.png" alt="" onClick={() => {
                            if(authors.findIndex(e => e.id === nft?.User?.id) > -1)
                              history.push({pathname: `/author/${nft.User.id}`})
                          }}/>
                        }
                      </span>
                    </div>
                    <div className='author_list_info'>
                      <span>{nft && nft.User && nft.User.username}</span>
                    </div>
                  </div>
                </div>}
              </div>
              <div className='spacer-40'></div>

              <div className='de_tab'>
                <ul className='de_nav'>
                  { nft?.TileBids?.length > 0 &&
                    <li id='Mainbtn' className=''>
                      <span onClick={handleBtnClick}>{t("Bids")}</span>
                    </li>
                  }
                </ul>

                <div className='de_tab_content'>
                {openMenu && (
                  <div className='tab-1 onStep fadeIn'>
                    {nft && signedUser.id && nft.TileBids && 
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th scope="col">No</th>
                            <th scope="col">Price</th>
                            <th scope="col">From</th>
                            <th scope="col"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {nft.TileBids.map((bid, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td><strong>{Web3.utils.fromWei(bid.price, 'mwei')}</strong> USDC</td>
                            <td><strong>{bid.User.username}</strong></td>
                            {nft.User && signedUser.metamask === nft.User.metamask &&
                            <td>
                              <div className='p_list_info'>
                                  <button className='btn-main' onClick={() => buyForBid(bid.User.metamask)}>accept</button>
                              </div>
                            </td>}
                          </tr>
                        ))}
                        </tbody>
                      </table>}
                  </div>
                )}
                </div>
              </div>
              <div className='d-flex flex-row mt-5'>
                {nft && nft.txHash ? <>
                  {txProcessing ? <>{nft && signedUser.id &&
                    <button
                      className='btn-main lead mb-5 mr15'
                      onClick={handleManualRegister}
                    >
                    {t("Register Transaction")}
                    </button>
                  }</> : <>
                    <p style={{color:'red'}}>Transaction is processing with other user.</p>
                  </>}
                </>
                : <>
                  {nft && nft?.User?.metamask !== signedUser?.metamask && !nft?.isForBid && nft?.isForSale && <button className='btn-main lead mb-5 mr15' onClick={handleBuyNow}>
                      {t("BuyNow")}
                  </button>}
                  {nft && signedUser.id && nft?.User?.metamask !== signedUser?.metamask && nft?.isForBid && isBidAvailable &&
                    <button
                      className='btn-main lead mb-5 mr15'
                      onClick={handleBid}
                    >
                    {t("AddBid")}
                    </button>
                  }
                  {nft && signedUser.id && nft?.User?.metamask === signedUser?.metamask && nft?.isForSale && nft?.minted && <button
                      className='btn-main lead mb-5 mr15'
                      onClick={cancelListing}
                    >Do not Sell</button>}

                  {nft && signedUser.id && nft?.User?.metamask === signedUser?.metamask && !nft?.isForSale && nft?.minted && <button
                      className='btn-main lead mb-5 mr15'
                      onClick={handleAddListing}
                    >Want to Sell</button>}

                  {nft && signedUser.id && nft?.minted && nft?.User?.metamask === signedUser?.metamask && signedUser.roleId === EROLES.ADMIN && !nft?.isForSale && signedUser?.metamask === walletAddress.toLowerCase() && <button
                        className='btn-main lead mb-5 mr15'
                        onClick={() => setShowTransferNft(true)}
                      >Transfer this NFT</button>}

                  {!isBidAvailable &&
                    <button
                      className='btn-main lead mb-5 mr15'
                      onClick={handleWithdrawBid}
                    >
                      Withdraw your Bid
                    </button>
                  }
                  {/* {nft && nft?.User?.metamask === signedUser?.metamask && nft?.minted && <button className='btn-main lead mb-5 mr15' onClick={handleUpdateNFT}>
                    {t("Update NFT")}
                  </button>} */}
                  {signedUser.metamask == walletAddress.toLowerCase() && !nft?.minted && <button className='btn-main lead mb-5 mr15' onClick={admint}>Admint</button>}
                </>}
              </div>
              <div className='spacer-40'></div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
      </LoadingOverlay>
      {openCheckout && (
        <div className='checkout'>
          <div className='maincheckout'>
            <button
              className='btn-close'
              onClick={() => setOpenCheckout(false)}
            >
              x
            </button>
            <div className='heading'>
              <h3>{t("Checkout")}</h3>
            </div>
            {Web3.utils.fromWei(nft?.price, 'mwei') * exchangeRateScale / exchangeRate < vaultAmount && isVaultChecked === false ?
            <>
              <p>
                {t("ToPurchase")}{' '}
                <span className='bold'>{nft && nft.tileType}{' '}{`X=${nft.posX}, Y=${nft.posY}, Z=${nft.posZ}`}{' '}</span>
                <span>{t("From")}</span>
                <span className='bold'> {nft && nft.User && nft.User.username}</span>
                <span> With <span className='bold'>your holdings</span>.</span>
              </p>
              <div className='mt-3'>
                <span>Would you like to buy this Land with your holdings. Your current share will be updated to { vaultPercent }%</span>
              </div>
              <div className='heading'></div>
              <div className='d-flex justify-content-center'>
                <button className='btn-main lead m-2' onClick={() => nft?.minted ? buyNFT(true) : buyMintNFT(true)}>{'Buy (Holdings)'}</button>
                <button className='btn-main lead m-2' onClick={() => setVaultChecked(true)}>{t("Buy (USDC)")}</button>
              </div>
            </> :
            <>
              <p>
                {t("ToPurchase")}{' '}
                <span className='bold'>{nft && nft.tileType}{' '}{`X=${nft.posX}, Y=${nft.posY}, Z=${nft.posZ}`}{' '}</span>
                <span>{t("From")}</span>
                <span className='bold'> {nft && nft.User && nft.User.username}</span>
                <span> with <span className='bold'>USDC</span>.</span>
              </p>
              <div className='heading mt-3'>
                {/* <p>{t("YourBlance")}</p> */}
                {/* <div className='subtotal'>10.67856 ETH</div> */}
                {/* <div className='subtotal'> {balance ? balance.formatted : ''}</div> */}
              </div>
              {/* <div className='heading'>
                <p>Price: </p>
                <div className='subtotal'>{nft && nft.price && Web3.utils.fromWei(nft.price, 'mwei') + ' EIV / ' + (Web3.utils.fromWei(nft.price, 'mwei') * priceRate).toFixed(2) + ' USDT'}</div>
              </div> */}
              {isCouponSet && coupon && <>
                <div className='heading'>
                  <p>{t("Price")}</p>
                  <div className='subtotal'>{Web3.utils.fromWei(nft?.price.toString(), 'mwei')} USDC</div>
                </div>
                <div className='heading'>
                  <p>{t("Discount")}</p>
                  <div className='subtotal'>{Web3.utils.fromWei((nft?.price - couponPrice).toString(), 'mwei')} USDC</div>
                </div>
              </>}
              <div className='heading'>
                <p>{t("YouWillPay")}</p>
                <div className='subtotal'>{Web3.utils.fromWei(couponPrice.toString(), 'mwei')} USDC</div>
              </div>
              {nft.User?.metamask === walletAddress.toLowerCase() && <div className='heading mt-3'>
                <div className='coupon'>
                  <p>Coupon Code (optional)</p>
                  <input
                    type='text'
                    name='coupon'
                    id='coupon'
                    className='form-control'
                    onChange={(event) => handleSearchCoupon(event.target.value)}
                  />
                  {isCouponError && <p style={{color:'red'}}>{isCouponOverdue ? 'This Coupon Code is expired.' : 'Invalid Coupon.'}</p>}
                </div>
              </div>}
              <button disabled={isFetchingCoupon} className='btn-main lead mb-5' onClick={() => nft?.minted ? buyNFT(false) : buyMintNFT(false)}>{t("BuyNow")}</button>
              {/* {
                // eslint-disable-next-line
                <p>The current price is calculated as per USDT / EIV Exchange Rate at the time of loading and will be available for the next 60 seconds. <a onClick={() => fetchPriceRate()}><u className={classes.refreshLink}>Refresh now</u></a></p>
              } */}
            </>}
            </div>
        </div>
      )}
      {openCheckoutbid && (
        <div className='checkout'>
          <div className='maincheckout'>
            <button
              className='btn-close'
              onClick={() => setOpenCheckoutbid(false)}
            >
              x
            </button>
            <div className='heading'>
              <h3>{t("PlaceBid")}</h3>
            </div>
            <p>
            {t("ToPurchase")}{' '}
              <span className='bold'>{nft && nft.posX + ', ' + nft.posY + ', ' + nft.posZ}</span>
              <span>{t("From")}</span>
              <span className='bold'> {nft && nft.User && nft.User.username}</span>
            </p>
            <p>
              <span>Price: {nft && Web3.utils.fromWei(nft.price, 'mwei')} USDC</span>
            </p>
            <div className='detailcheckout mt-4'>
              <div className='listcheckout'>
                <h6>{t("YourBid")} (USDC)</h6>
                <input type='number' step={0.01} className='form-control' onChange={(event) => setPrice(event.target.value)} />
                {isPriceError && <p style={{color:'red'}}>Bid price should be higher than {Web3.utils.fromWei(nft.price, 'mwei')} USDC.</p>}
              </div>
            </div>
            {/* <div className='detailcheckout mt-3'>
              <div className='listcheckout'>
                <h6>
                 {t("EnterQuantity")}{' '}
                  <span className='color'>10 {' '}{t("Available")}</span>
                </h6>
                <input
                  type='text'
                  name='buy_now_qty'
                  id='buy_now_qty'
                  className='form-control'
                />
              </div>
            </div> */}
            <div className='heading mt-3'>
              {/* <p>{t("YourBlance")}</p>
              <div className='subtotal'>{balance ? balance.formatted : ''}</div> */}
              {/* <div className='subtotal'>10.67856 ETH</div> */}
            </div>
            <div className='heading'>
              {/* <p>{t("ServiceFee")} {serviceFee / 10}%</p>
              <div className='subtotal'>{nft && price*serviceFee/feeScale} EIV</div> */}
            </div>
            <div className='heading'>
              <p>{t("YouWillPay")}</p>
              {/* <div className='subtotal'>{nft && price && (parseFloat(price) + parseFloat(price*serviceFee/feeScale)).toFixed(3)} EIV</div> */}
            </div>
            <button className='btn-main lead mb-5' onClick={placeBid}>{t("AddBid")}</button>
          </div>
        </div>
      )}
      {openWithdrawBid && (
        <div className='checkout'>
          <div className='maincheckout'>
            <button
              className='btn-close'
              onClick={() => setOpenWithdrawBid(false)}
            >
              x
            </button>
            <div className='heading'>
              <h3>Withdraw your bid</h3>
            </div>
            <p>
              <span>Price: {nft && Web3.utils.fromWei(bidAmount, 'mwei')} USDC</span>
            </p>
            <div className='heading mt-3'>
              {/* <p>{t("YourBlance")}</p>
              <div className='subtotal'>{balance ? balance.formatted : ''}</div> */}
            </div>
            <div className='heading'>
              <p>You will be paid</p>
              <div className='subtotal'>{nft && Web3.utils.fromWei(bidAmount, 'mwei')} USDC</div>
            </div>
            <button className='btn-main lead mb-5' onClick={withdrawBid}>Withdraw</button>
          </div>
        </div>
      )}
      {openAddListing && nft && (
        <div className='checkout'>
          <div className='maincheckout'>
            <button
              className='btn-close'
              onClick={() => setOpenAddListing(false)}
            >
              x
            </button>
            <div className='heading'>
              <h3>Sell My NFT</h3>
            </div>
            <p>
              <span className='bold'>{nft && nft?.Continent?.name}: {nft && nft.posX + ', ' + nft.posY + ', ' + nft.posZ}</span>
            </p>
            <div className='detailcheckout mt-4'>
              <div className='listcheckout'>
                <div className="de_tab tab_methods">
                  <ul className="de_nav">
                    <li className={isForBid ? null : "active"} onClick={() => setIsForBid(false)}><span><i className="fa fa-tag"></i>{t("FixedPrice")}</span>
                    </li>
                    {/* <li className={isForBid ? "active" : null} onClick={() => setIsForBid(true)}><span><i className="fa fa-users"></i>{t("OpenForBids")}</span>
                    </li> */}
                  </ul>
                </div>
                {/* <p>{(sellPrice*priceRate).toFixed(2)} USDT</p> */}
                <div className='price_container' style={{marginTop: 20}}>
                  <h6 style={{lineHeight: 0}}>Set the price</h6>
                </div>
                <input
                  type='text'
                  name='buy_now_qty'
                  id='buy_now_qty'
                  className='form-control'
                  style={{marginBottom: 5}}
                  onChange={(event) => setSellPrice(event.target.value)}
                />
                <div className='price_container'>
                  <p>{isForBid 
                      ? `Set the minimum bid price for this NFT :`
                      : `Set the selling price for the NFT :`}</p>
                  <p>
                    {isForBid ? (sellPrice ? sellPrice + ' USDC' : '') : (sellPrice ? sellPrice + ' USDC' : '')}
                  </p>
                </div>
                {/* <div className='price_container'>
                  {sellPrice && <p>Price in USDT :</p>}
                  {sellPrice && <p>{(sellPrice * priceRate).toFixed(2)} USDT</p>}
                </div> */}
              </div>
            </div>
            <div className='heading mt-3'>
              {/* <p>{t("YourBlance")}</p>
              <div className='subtotal'> {balance ? balance.formatted : ''}</div> */}
            </div>
            {/* <div className='heading'>
              <p>{t("ServiceFee")} {parseFloat(serviceFee / feeScale * 100)}%</p>
              <div className='subtotal'>{sellPrice && sellPrice*serviceFee/feeScale} EIV</div>
            </div>
            <div className='heading'>
              <p>{t("YouWillPay")}</p>
              <div className='subtotal'>{sellPrice && parseFloat(sellPrice) + parseFloat(sellPrice*serviceFee/feeScale)} EIV</div>
            </div> */}
            <div className='heading'>
              <p>Selling a LAND is subject to Royalties as per our <a href="http://earniverse.io/terms-of-service" target='_blank'><u>Terms of Service</u></a> ({royalty}% Marketplace Royalty). After the sell, you will receive an amount of {sellPrice? parseFloat(sellPrice) * (1 - royalty / 100) : 0} $USDC</p>
            </div>
            <button className='btn-main lead mb-5' onClick={addListing} disabled={sellPrice ? false : true}>Add for Sale</button>
          </div>
        </div>
      )}
      <Dialog
          open={showModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className='alert-modal'
      >
        <div className={classes.root}>
          <div className={classes.topBlock}>
            <CheckCircleOutlinedIcon className={classes.icon} />
          </div>  
          <div className={classes.bottomBlock}>
            <Typography className={classes.title}>
                {t("You need to connect your wallet first!")}
            </Typography>
            <div className={classes.buttonBlock}>
              <button
                className="btn-main"
                onClick={()=>setShowModal(false)}
              >
                {t("Close")} 
              </button>
              <button
                className="btn-main"
                onClick={() => { handleAuth(); }}
              >
                {t("Connect")}
              </button>
            </div>
          </div>
        </div>
        { serverError && <ModalError open={serverError} onClose={() => {setServerError(false); setShowModal(false);}}/>}   
      </Dialog>
      <Dialog
          open={showAlertModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className='alert-modal'
      >
          <div className={classes.root}>
              <div className={classes.topBlock}>
                  <CheckCircleOutlinedIcon className={classes.icon} />
              </div>  
              <div className={classes.bottomBlock}>
                  <Typography className={classes.title}>
                      {alertMessage}
                  </Typography>
                  <div className={classes.alertButtonBlock}>
                      <button
                          className="btn-main"
                          onClick={()=>{setShowAlertModal(false)}}
                      >
                          {t("Close")} 
                      </button>
                  </div>
              </div>
          </div>
      </Dialog>
      <Dialog
          open={showTransferNft}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className='alert-modal'
      >
        <div className='checkout'>
          <div className='maincheckout'>
            <button
              className='btn-close'
              onClick={() => setShowTransferNft(false)}
            >
              x
            </button>
            <div className='heading'>
              <h3>{t("Manual Transfer")}</h3>
            </div>
            <p>
              <span>Please insert the wallet address you which to transfer this NFT to.</span>
              <span> Make sure this wallet address is compatible with AVAX C-CHAIN.</span>
              <span> Earniverse does not take any responsibility for any wrong transfer executed that will not be subject to refund.</span>
            </p>
            <span className='bold'>Enter wallet address.</span>
            {<FormControl className={classes.formControl}>
              <Autocomplete
                id="free-solo-demo"
                freeSolo
                disablePortal
                value={transferAddress}
                getOptionLabel={(option)=>{
                  // Value selected with enter, right from the input
                  if (typeof option === 'string') {
                    return option;
                  }
                  if (!option) {
                    return '';
                  }
                  return option.metamask;
                }}
                options={transferAddressList}
                onInputChange={(event, newInputValue) => {
                  if(transferAddressList.find(obj => {return obj.metamask === newInputValue})) {
                    setSendClicked(false);
                    setTransferAddress(transferAddressList.find(obj => {return obj.metamask === newInputValue}));
                  }
                  setSearchAddress(newInputValue);
                }}
                onChange={(event, value, reason) => {
                  if(reason === "clear") {
                    setTransferAddress(0);
                    setTransferAddressList([]);
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params}
                    margin="normal"
                    placeholder="Wallet Address"
                    className="form-control"
                    style={{width: '100%'}}
                    onChange={(e) => {
                      handleSearchAddress(e.target.value)
                    }}
                    value={searchAddress}
                  />
                )}
                style={{margin: 'auto', width: '100%'}}
              />
              {isSendClicked && transferAddress === 0 && <p style={{color: 'red'}}>Please select address.</p>}
              {transferAddress?.metamask && searchAddress && <>
                <div className='heading'>
                  <p>Name: </p>
                  <div className='subtotal'>{transferAddress?.username}</div>
                </div>
              </>}
            </FormControl>}
            <div className={classes.transferButtonBlock}>
              <button
                  className="btn-main"
                  style={{margin: 10}}
                  disabled={!transferAddress?.metamask || !searchAddress}
                  onClick={()=>{if(transferAddress===0) setSendClicked(true); else sendManualTransfer();}}
              >
                  {t("Send")} 
              </button>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default memo(TileDetails);

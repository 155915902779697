import React, { memo, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as selectors from '../../../store/selectors';
import * as actions from '../../../store/actions/thunks';
import { clearAssets, clearFilter, showLoadMoreAssets } from '../../../store/actions';
import AssetCard from './AssetCard';
import { shuffleArray } from '../../../store/utils';
import { useTranslation } from 'react-i18next';
import Pagination from '@material-ui/lab/Pagination';
import useStyles from '../pages/styles/Authors/authors';

//react functional component
const ColumnNewRedux = ({ priceRate, category=false, query=false, trigger=false, shuffle = false, authorId = null, tagIds=false, utilityIds=false, orderBy=false, isNotSold=false }) => {
    const {t}=useTranslation();
    const dispatch = useDispatch();
    const assetItems = useSelector(selectors.assetItems);
    const assetCount = useSelector(selectors.assetCount);
    const nfts = assetItems ? shuffle ? shuffleArray(assetItems) : assetItems : [];
    const [page, setPage] = useState(0);
    const [height, setHeight] = useState(250);
    const classes = useStyles();

    const onImgLoad = ({target:img}) => {
        // let currentHeight = height;
        // if(currentHeight < img.offsetHeight) {
        //     setHeight(img.offsetHeight);
        // }
    }

    useEffect(() => {
        if(trigger !== undefined && query !== undefined) {
            dispatch(showLoadMoreAssets(true));
            dispatch(clearFilter());
            dispatch(clearAssets());
            if(category) {
                dispatch(actions.fetchAssetsBreakdown(authorId, 0, query, category, true, tagIds, utilityIds, orderBy, isNotSold));
                setPage(1);
            }
            else {
                dispatch(actions.fetchAssetsBreakdown(authorId, 0, query, false, true, tagIds, utilityIds, orderBy, isNotSold));
                setPage(1);
            }
        }
    }, [dispatch, category, trigger, authorId, tagIds, utilityIds, orderBy, isNotSold, isNotSold]);

    const handleSelectPage=(event,newPage)=>{
        dispatch(clearAssets());
        if(category) {
          dispatch(actions.fetchAssetsBreakdown(authorId, newPage-1, query, category, true, tagIds, utilityIds, orderBy, isNotSold));
        }
        else {
          dispatch(actions.fetchAssetsBreakdown(authorId, newPage-1, query, false, true, tagIds, utilityIds, orderBy, isNotSold));
        }
        setPage(newPage);
      }

    return (
        <div className='row'>
            {nfts && nfts.map( (nft, index) => (
                <AssetCard priceRate={priceRate} nft={nft} key={index} onImgLoad={onImgLoad} account={authorId} height={height}/>
            ))}
            <div className={classes.root}>
                <Pagination
                    page={page} 
                    count={Math.ceil(assetCount/12)} 
                    onChange={handleSelectPage}
                    variant="outlined"
                    shape="rounded" />
            </div>
        </div>              
    );
};

export default memo(ColumnNewRedux);
import { makeStyles, Theme } from '@material-ui/core';
import { ECOLORS } from '../../../utils/constants/colors.enum';

export default makeStyles((theme: Theme) => ({ 
    root:{
        width:"inherit",
        height:"inherit",
        minWidth:130,
        borderRadius:(props:any)=>props.boderRadius?props.boderRadius:25,
        fontFamily:"system-ui,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif",
        textTransform:"capitalize",
        cursor:"pointer",
        fontSize:(props:any)=>props.fontSize,
        fontWeight:"bold",
        background:(props:any)=>props.color==="primary" ? `linear-gradient(90deg,${ECOLORS.PRIMARY_LIGHT},${ECOLORS.PRIMARY_DARK})`: ECOLORS.PRIMARY_LIGHT,
        backgroundClip: 'padding-box !important',
        borderColor:(props:any)=> props.color==="secondary" ? ECOLORS.PRIMARY_LIGHT : "",
        color:ECOLORS.PRIMARY,
        marginLeft:5,
        marginRight:5,
        "&.MuiButton-outlined:hover":{
            border: '1px solid',
            background:"transparent",
            borderColor:(props:any)=>props.color==="primary" ? ( props.outline === "white" ? 'white' : ECOLORS.PRIMARY_DARK):ECOLORS.PRIMARY_LIGHT,
            color:(props:any)=>props.color==="primary" ? ( props.outline === "white" ? 'white' : ECOLORS.PRIMARY_DARK):ECOLORS.PRIMARY_LIGHT,
        }
    }
 }));
import { makeStyles, Theme } from '@material-ui/core';
import { ECOLORS } from '../../../utils/constants/colors.enum';


export default makeStyles((theme: Theme) => ({  
    root:{
        width:"100%",
        display:"flex",
        flexDirection:"column",
        justifyContent:"center",
        alignItems:"flex-end",
        overflow:"hidden",
        position: "relative",    
        zIndex: 1,
        marginTop: 50,
        marginBottom: 20
    },
    blockText:{
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '100%',
        textAlign: 'center',
        zIndex: 2,
        [theme.breakpoints.down('md')]: {
               paddingRight:"10vw",
        },
        [theme.breakpoints.down(1600)]: {
            marginRight:"10vw",
        },
         [theme.breakpoints.down(1280)]: {
            marginRight:"4vw",
        },
         [theme.breakpoints.down(1000)]: {
            marginRight:"9vw",
        },
        "& h1":{
                textTransform:"capitalize",
                fontFamily:"tahoma",
                fontSize:48,
                fontWeight: "bold",
                marginBottom:20,
                [theme.breakpoints.down(1700)]: {
                    fontSize:44,
                },              
                [theme.breakpoints.down(1550)]: {
                    fontSize:42,
                },              
                [theme.breakpoints.down(1350)]: {
                    fontSize:40,
                },  
                [theme.breakpoints.down(1280)]: {
                    fontSize:38,
                },             
                [theme.breakpoints.down(1000)]: {
                    fontSize:36,
                },   
                [theme.breakpoints.down(860)]: {
                    fontSize:33,
                } 
        },
        "& h2":{
              fontFamily:"tahoma",
            fontSize:20,
            fontWeight:"bold",
            color:ECOLORS.SECONDARY_DARK,
            width:"28vw",
            textAlign:"center",
            [theme.breakpoints.down(1855)]: {
                fontSize:19,
                 width:"29vw",
            },
            [theme.breakpoints.down(1700)]: {
                fontSize:19,
                 width:"31vw",
            },
            [theme.breakpoints.down(1590)]: {
                fontSize:18,
                 width:"31vw",
            },
            [theme.breakpoints.down(1550)]: {
                fontSize:18,
                 width:"32vw",
            },
            [theme.breakpoints.down(1460)]: {
                fontSize:17,
                 width:"33vw",
            },   
            [theme.breakpoints.down(1350)]: {
                fontSize:16,
                 width:"32vw",
            }, 
            [theme.breakpoints.down(1280)]: {
                fontSize:16,
                width:"35vw",
            },  
            [theme.breakpoints.down(1000)]: {
                fontSize:15,
                width:"43vw",
            }, 
            [theme.breakpoints.down(860)]: {
                fontSize:14,
                width:"44vw",
            },    
        }
    },
    title: {
        backgroundImage: 'linear-gradient(90deg, #ea0db0, #4d058e)',
        '-webkit-background-clip': 'text',
        color: 'transparent',
        width: 500,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    tile: {
        maxWidth: 200
    },
    rightBlock: {
        textAlign: 'left',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
    },
    buttonGroup: {
        display: 'flex',
        position: 'absolute',
        bottom: 15,
        width: '55%',
        right: 0
    },
    logo: {
        maxHeight: 50,
        marginBottom: 15
    },
    viewMapButton: {
        maxWidth: '200px important'
    },
    buyPlotButton: {
        maxWidth: '200px important'
    },
    cardItem: {
        marginTop: 60
    },
    imageContainer: {
        textAlign: 'right',
        paddingRight: '30px !important',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center'
        }
    },
    cardDescription: {
        maxWidth: '60%'
    },
    downloadContainer: {
        marginTop: 120
    }
}));
import React from 'react';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { EPATHS } from '../../routes/paths.enum';

import useStyles from "../../styles/components/Home/getStartSection"
import DefaultButton from '../buttons/defaultButton';
import { NavLink } from 'react-router-dom';

function GetStartSection() {
    const classes=useStyles()
    const {t}=useTranslation();
    const history = useHistory();
    
    return <div className={classes.root}>
    {/* <div>
    <img src={ROCKET} alt={ROCKET} className={classes.img}/>
    </div> */}
    <div className={classes.blockText}>
      <Typography variant='h1' className={classes.title}>Play Earniverse</Typography>
      <Typography variant='h4' className={classes.title}>{t("CreateExplore")}</Typography>
        <NavLink to={EPATHS.GET_STARTED} className={classes.buttonContainer} onClick={() => history.push({pathname:EPATHS.GET_STARTED})}>
          <DefaultButton color="primary" variant='outlined' text={t("GetStarted")} fontSize={18} />
        </NavLink>
    </div>
</div>;
}

export default GetStartSection;

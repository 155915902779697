import React, { memo, useEffect,useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from "react-router";

import ColumnNewRedux from '../../components/ColumnNewRedux';
import Footer from '../../components/footer';
import * as selectors from '../../../../store/selectors';
import api from "../../../../core/api";
import Header from "../../menu/header";
import { fetchExchangeData } from "../../../../services/DataService";
import { fetchAuthorList } from "../../../../store/actions/thunks";

function Author() {
    const {id} = useParams();
    const [author, setAuthor] = useState(null);
    const [priceRate, setPriceRate] = useState(0);

    const dispatch = useDispatch();
    const authorsState = useSelector(selectors.authorsState);

    useEffect(() => {
      // fetchPriceRate();
      dispatch(fetchAuthorList());
      // eslint-disable-next-line
    }, [dispatch,id]);

    // const fetchPriceRate = async () => {
    //   fetchExchangeData().then((res => {
    //     setPriceRate(res);
    //   }));
    // }

    useEffect(() => {
      if(authorsState.data) {
        let index = authorsState.data.findIndex(_author => _author.id === parseInt(id));
        const currentAuthor = authorsState.data ? authorsState.data[index] : {};
        setAuthor(currentAuthor);
      }
      // eslint-disable-next-line
    }, [authorsState.data]);

  return (
    <React.Fragment>
      <Header className=""/>
      {author && <div>
      { author.banner ? 
        <section id='profile_banner' className='jumbotron breadcumb no-bg' style={{
          backgroundImage: `url(${
            author
              ? author.banner
                ? api.baseUrl + '/' + encodeURIComponent(author.banner)
                : `url(${'../img/background/subheader.jpg'})`
              : ''
          })`,
          backgroundSize: 'cover'
        }}>
        <div className='mainbreadcumb'>
        </div>
      </section> :
      <section className='jumbotron breadcumb no-bg' style={{backgroundImage: `url(${'../img/background/subheader.jpg'})`}}>
        <div className='mainbreadcumb'>
          <div className='container'>
            <div className='row m-10-hor'>
              <div className='col-12'>
                <h1 className='text-center'>{author.username}'s collection</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
    }
    <section className='container no-bottom'>
    <div className='row'>
      <div className="col-md-12">
         <div className="d_profile de-flex">
              <div className="de-flex-col">
                  <div className="profile_avatar">
                    { author && 
                      <img src={
                        author.picture
                          ? api.baseUrl + '/' + author.picture
                          : '../../img/author/author-11.png'
                      }
                      alt='' />
                    }
                      <i className="fa fa-check"></i>
                      <div className="profile_name">
                          <h4>
                            {author ? author.username : ''}
                            {/* <span className="profile_username">{author ? author.email : ''}</span> */}
                            {/* <span id="wallet" className="profile_wallet">{author ? author.metamask : ''}</span> */}
                            {/* <span className='profile_username'>
                              {author ? author.username : ''}
                            </span> */}
                          </h4>
                      </div>
                  </div>
              </div>

          </div>
      </div>
    </div>
  </section>

  <section className='container no-top'>
      <ColumnNewRedux  priceRate={priceRate} authorId={author.id}/>
      </section>
      <Footer />
    </div>}
    </React.Fragment>
  )
}

export default memo(Author);
import Web3 from "web3";

const Allowance = async(eivContract, account, marketplaceAddress, price) => {
    try{
        const allowed = await eivContract.methods.allowance(account, marketplaceAddress).call();
        // if (parseInt(allowed) < parseInt(price)) {
            await eivContract.methods.approve(process.env.REACT_APP_CONTRACT_ADDRESS, price).send({from: account})
        // }
        return true
    } catch(error) {
        throw new Error('Not sufficient allowance.')
    }
}

export default Allowance;
import { makeStyles, Theme } from '@material-ui/core';
import { ECOLORS } from '../../../../../utils/constants/colors.enum';

export default makeStyles((theme: Theme) => ({ 
    root:{
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        marginTop:50
    },
    rootModal:{
        width:500,
        height:400
    },
    topBlock:{
        height:150,
        backgroundColor:ECOLORS.PURPLE,
        display:"flex",
        justifyContent:"center",
        alignItems:"center"
    },
    icon:{
        color:"white",
        height:80,
        width:80
    },
    bottomBlock:{
        marginTop:20,
        display:"flex",
        flexDirection:"column",
        justifyContent:"center",
        alignItems:"center"
    },
    title:{
        fontWeight:"bold",
        fontSize:18,
        color:ECOLORS.PRIMARY_DARK
    },
    supTitle:{
        fontWeight:"bold",
        fontSize:15,
        color:ECOLORS.PRIMARY_DARK,
        textAlign:"center",
        margin: 10
    },
    buttonBlock:{
        display:"flex",
        marginTop:30
    },
    tableRow: {
        '&:hover': {
            background: 'ghostwhite'
        }
    },
    avatarHeader: {
        width: 100
    },
    avatarCell: {
        paddingTop: '20px !important'
    },
    buttonWrapper: {
        display: 'flex'
    },
    rolesDropdown: {
        width: 100
    },
    table: {
        minWidth: 700,
    },
    collectionTable: {
        width: '2500px !important'
    },
    sortLabel: {
        '&:hover': {
            color: 'white'
        },
        '&:focus': {
            color: 'white'
        }
    }
}));
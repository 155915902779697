import React from 'react';
import { MAP_SEA_COLOR, MAP_LAND_COLOR, MAP_SOLD_COLOR, MAP_MY_LAND_COLOR, MAP_FOR_SALE_COLOR, MAP_OVERLAY_COLOR, TIER1, TIER2, TIER3, TIER4, TIER5, MAP_INFRA_COLOR, LAND_PACK } from './Const';
import octagonImg from '../../assets/images/octagon.png';

function Legend({zoomLevel}) {
  return (
    <div className='tile-map-legend' style={{
        background: zoomLevel > 3 ? 'white' : 'transparent',
        width: zoomLevel > 3 ? 262 : 300,
        padding: zoomLevel > 3 ? 20 : 0,
        boxShadow: zoomLevel > 3 ? '0px 4px 40px rgba(1, 4, 24, 0.1)' : '0px 4px 40px rgba(1, 4, 24, 0.0)'
    }}>
      {zoomLevel > 3 ?  <React.Fragment>
        <div className='tile-map-legend-label fw-bold text-black'><span>Topography</span></div>
        <div className='tile-map-legend-label'><div className='legend-label-icon' style={{background: MAP_LAND_COLOR }}></div><span>Land</span></div>
        <div className='tile-map-legend-label'><div className='legend-label-icon' style={{background: MAP_SEA_COLOR}}></div><span>Ocean</span></div>
        <div className='tile-map-legend-label mt-3 fw-bold text-black'><span>Land unavailable</span></div>
        <div className='tile-map-legend-label'><div className='legend-label-icon' style={{background: MAP_INFRA_COLOR}}></div><span>Infrastructure</span></div>
        <div className='tile-map-legend-label'><div className='legend-label-icon crossed' style={{backgroundColor: MAP_OVERLAY_COLOR}}></div><span>Sold</span></div>
        <div className='tile-map-legend-label'><div className='legend-label-icon' style={{background: MAP_MY_LAND_COLOR}}></div><span>My Land</span></div>
        <div className='tile-map-legend-label mt-3 fw-bold text-black'><span>Land for sale</span></div>
        {/* <div className='tile-map-legend-label'><div className='legend-label-icon' style={{background: MAP_FOR_SALE_COLOR}}></div><span>For Sale</span></div> */}
        <div className='tile-map-legend-label'><div className='legend-label-icon' style={{background: TIER1}}></div><span>Tier 1</span></div>
        <div className='tile-map-legend-label'><div className='legend-label-icon' style={{background: TIER2}}></div><span>Tier 2</span></div>
        <div className='tile-map-legend-label'><div className='legend-label-icon' style={{background: TIER3}}></div><span>Tier 3</span></div>
        <div className='tile-map-legend-label'><div className='legend-label-icon' style={{background: TIER4}}></div><span>Tier 4</span></div>
        <div className='tile-map-legend-label'><i className='fa fa-star legend-label-icon' style={{background: TIER5}}></i><span>Tier 5 / VVIP</span></div>
        <div className='tile-map-legend-label'><div className='legend-label-icon' style={{background: LAND_PACK}}></div><span>Land Pack</span></div>
      </React.Fragment> : <p className='legend-map-zoom-message'>Zoom in the map to view grid details</p>}
    </div>
  );
}

export default Legend;

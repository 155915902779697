import { axiosWithToken } from '../utils/functions/apiCallWithToken';

const serverURL = process.env.REACT_APP_SERVER;

export const fetchUtilities = async () => {
    const result = await axiosWithToken(`${serverURL}/utility`, 'get', {});
    return result;
}

export const createUtility = async (data) => {
    const result = await axiosWithToken(`${serverURL}/utility/create/`, 'post', data);
    return result;
}
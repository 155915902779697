import React from 'react';
import { Box } from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import { withStyles } from '@material-ui/core/styles';

const StyledRating = withStyles({
  iconFilled: {
    color: '#a706aa',
  },
  iconHover: {
    color: '#a706aa',
  },
})(Rating);

//react functional component
const VariableItemAssetDetail = ({ item, continents, isLabel=false }) => {

    return (
        <div>
            {
                <Box style={styles.container}>
                    <span style={styles.variableSelector}>{item.variable}</span>
                    {
                        item.format === 'STAR' ?  
                        <Box style={{display: 'flex', alignItems: 'center'}}>
                            <StyledRating name={`rating-${item.id}`} value={parseInt(item.value / 10)} max={item.maxRate} readOnly/>
                            {isLabel && <span style={styles.variableSelectorLabel}>{`(${item.value}/100)`}</span>}
                        </Box>
                        : (
                            item.variable === 'Continent' 
                            ? <span style={styles.variableValue}>{continents.find(continent => continent.id === item.value)?.name}</span>
                            : <span style={styles.variableValue}>{item.value}</span>
                        )
                    }
                </Box>
            }        
        </div>              
    );
};

const styles = {
    container: {
        display: 'flex',
        alignItems: 'center',
    },
    variableSelector: {
        width: 70,
        marginRight: 20
    },
    variableSelectorLabel: {
        width: 70,
        marginRight: 20,
        marginLeft: 10
    },
    variableValue: {
        width: 240,
    }
}

export default VariableItemAssetDetail;
import { Button } from '@material-ui/core';
import React, { useState } from 'react';

import useStyles from "../../styles/components/buttons/defaultButton";
import { IDefaultButton } from '../interfaces/buttons.interface';

function DefaultButton({variant, color, text, fontSize, boderRadius, disabled, outline}:IDefaultButton) {
    const classes=useStyles({color, fontSize, boderRadius, outline});
    const [variantColor, setVariantColor] = useState(variant);

  return <Button variant={variantColor} 
  className={classes.root} disabled={disabled}>{text}</Button>
}

export default DefaultButton;

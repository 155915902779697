import { makeStyles, Theme } from '@material-ui/core';
import { ECOLORS } from '../../../utils/constants/colors.enum';

export default makeStyles((theme: Theme) => ({  
    root:{
        display:"flex",
        justifyContent:"space-between",
        alignItems:"center",
        height:"35vw",
        backgroundColor:ECOLORS.PRIMARY,
        padding:"0px 8vw 0px 15vw",
        overflow:"hidden",
        [theme.breakpoints.down(1400)]: {
            padding:"0px 7vw 0px 7vw",
            height:"42vw",
        },  
        [theme.breakpoints.down(1400)]: {
            height:"45vw",
            padding:"0px 7vw 0px 7vw",
        }, 
        [theme.breakpoints.down(960)]: {
            padding:"0px 6vw 0px 6vw",
        },  
        [theme.breakpoints.down(890)]: {
            padding:"0px 5vw 0px 5w",
            height:"48vw",
        }
    },
    img:{
        width:"48vw",
    },
    blockText:{
        display:"flex",
        justifyContent:"center",
        flexDirection:"column",
        // textTransform:"capitalize",
            "& h1":{
              textAlign:"center",
                textTransform:"capitalize",
                fontFamily:"tahoma",
                fontSize:48,
                fontWeight: "bold",
                marginBottom:20,
                color:ECOLORS.PRIMARY_DARK,
                [theme.breakpoints.down(1700)]: {
                    fontSize:44,
                },              
                [theme.breakpoints.down(1550)]: {
                    fontSize:42,
                },              
                [theme.breakpoints.down(1400)]: {
                    fontSize:40,
                },  
                [theme.breakpoints.down(1250)]: {
                    fontSize:38,
                },             
                [theme.breakpoints.down(1150)]: {
                    fontSize:36,
                },             
                [theme.breakpoints.down(960)]: {
                    fontSize:34,
                },    
                [theme.breakpoints.down(890)]: {
                    fontSize:30,
                } 
        },
        "& h2":{
            fontFamily:"tahoma",
            fontSize:20,
            fontWeight:"bold",
            color:ECOLORS.SECONDARY_DARK,
            width:"26vw",
            textAlign:"center",
            [theme.breakpoints.down(1700)]: {
                fontSize:19,
                 width:"30vw",
            },
            [theme.breakpoints.down(1550)]: {
                fontSize:18,
                 width:"32vw",
            },
            [theme.breakpoints.down(1400)]: {
                fontSize:17,
                 width:"32vw",
            },   
            [theme.breakpoints.down(1250)]: {
                fontSize:16,
                 width:"38vw",
            }, 
            [theme.breakpoints.down(1150)]: {
                fontSize:16,
                width:"42vw",
            },  
            [theme.breakpoints.down(960)]: {
                fontSize:14,
                width:"44vw",
            },  
        },
        "& h3":{
            fontFamily:"tahoma",
            fontSize:20,
            fontWeight:"bold",
            marginBottom:20,
            color:ECOLORS.SECONDARY_DARK,
            width:"28vw",
            textAlign:"center",
            [theme.breakpoints.down(1700)]: {
                fontSize:19,
                 width:"30vw",
            },
            [theme.breakpoints.down(1550)]: {
                fontSize:18,
                 width:"32vw",
            },
            [theme.breakpoints.down(1400)]: {
                fontSize:17,
                 width:"32vw",
            },   
            [theme.breakpoints.down(1250)]: {
                fontSize:16,
                 width:"38vw",
            }, 
            [theme.breakpoints.down(1150)]: {
                fontSize:16,
                width:"42vw",
            },  
            [theme.breakpoints.down(960)]: {
                fontSize:14,
                width:"43vw",
            },  
        },
    },
    buttonContainer:{
        alignSelf:"center",
        width:250,
        height:50,
        marginTop:20,
        [theme.breakpoints.down('md')]: {
            width:200,
            height:40,
        },
        [theme.breakpoints.down(960)]: {
            width:180,
            height:35,
        }
    }
}));
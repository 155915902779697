import { axiosWithToken } from '../utils/functions/apiCallWithToken';

const serverURL = process.env.REACT_APP_SERVER;

export const fetchTags = async () => {
    const result = await axiosWithToken(`${serverURL}/tag`, 'get', {});
    return result;
}

export const createTag = async (data) => {
    const result = await axiosWithToken(`${serverURL}/tag/create/`, 'post', data);
    return result;
}
import React, { memo, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as selectors from '../../../store/selectors';
import * as actions from '../../../store/actions/thunks';
import { clearNfts, clearFilter, showLoadMoreNFTs } from '../../../store/actions';
import NftCard from './NftCard';
import NftMusicCard from './NftMusicCard';
import { shuffleArray } from '../../../store/utils';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import { useHistory } from 'react-router';
import { EPATHS } from '../../../routes/paths.enum';
import Pagination from '@material-ui/lab/Pagination';
import useStyles from '../pages/styles/Authors/authors';

//react functional component
const ColumnNftRedux = ({ priceRate, shuffle=false, authorId=null, isForSale=false }) => {
    const {t}=useTranslation();
    const dispatch = useDispatch();
    const nftItems = useSelector(selectors.nftItems);
    const nftCount = useSelector(selectors.nftCount);
    const signedUser = useSelector(selectors.user);
    const loadingState = useSelector(selectors.loadingState);
    const nfts = nftItems ? shuffle ? shuffleArray(nftItems) : nftItems : [];
    const [page, setPage] = useState(1);
    const [height, setHeight] = useState(350);
    const history = useHistory();
    const classes = useStyles();

    const onImgLoad = ({target:img}) => {
        // let currentHeight = height;
        // if(currentHeight < img.offsetHeight) {
        //     setHeight(img.offsetHeight);
        // }
    }
   
    useEffect(() => {
        dispatch(showLoadMoreNFTs(true));
        dispatch(clearFilter());
        dispatch(clearNfts());
        dispatch(actions.fetchNftsBreakdown(authorId, page-1, isForSale));
    }, [dispatch, isForSale]);

    const handleSelectPage=(event,newPage)=>{
        dispatch(clearNfts());
        dispatch(actions.fetchNftsBreakdown(authorId, newPage - 1, isForSale));
        setPage(newPage);
    }

    return (
        <div className='row'>
            {nfts.length === 0 && signedUser.id && !loadingState && <div style={{textAlign: 'center'}}>
                <Typography style={{marginBottom: 10}}>You don’t have any NFTs in your wallet. To start buying NFT you can visit the marketplace.</Typography>
                <span onClick={() => history.push({pathname: EPATHS.EXPLORE})} className="btn-main lead m-auto">{"Visit Marketplace"}</span>
            </div>}
            {nfts && nfts.map( (nft, index) => (
                nft.category === 'music' ?
                <NftMusicCard nft={nft} audioUrl={nft.audio_url} key={index} onImgLoad={onImgLoad} height={height} />
                :
                <NftCard priceRate={priceRate} nft={nft} key={index} account={authorId} height={250} asset={nft.Asset}/>
            ))}
            <div className={classes.root}>
                <Pagination
                    page={page} 
                    count={Math.ceil(nftCount/12)} 
                    onChange={handleSelectPage}
                    variant="outlined"
                    shape="rounded" />
            </div>
        </div>              
    );
};

export default memo(ColumnNftRedux);
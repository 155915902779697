import { memo, useEffect, useState, useRef } from 'react';
import Web3 from "web3";
import styled from "styled-components";
import { useTranslation } from 'react-i18next';
import Clock from "./Clock";
import api from '../../../core/api';
import { NavLink, useHistory } from 'react-router-dom';
import Nft3dCard from '../../../components/Cards/nft3DCard';
import { Typography,Dialog,Button } from '@material-ui/core';
import useStyles from '../../../styles/components/Cards/nftCard';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import ModalNoMetamask from "../../../components/Servererror/modalNoMetamask";
import * as selectors from '../../../store/selectors';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../../store/actions/thunks';
import * as setActions from '../../../store/actions';
import { login, register } from '../../../utils/functions/authenticate';
import { setNftLike,
    setNftDislike,
    setNftWishlist,
    setNftDiswishlist
} from '../../../services/NftService';
import { createNotification } from '../../../services/NotificationService';

const Outer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 8px;
`;

//react functional component
const NftCard = ({priceRate, nft, className = 'd-item col-lg-3 col-md-6 col-sm-6 col-xs-12 mb-4', clockTop = true, height, onImgLoad, account, asset }) => {
    const {t}=useTranslation();
    const history=useHistory();
    const classes=useStyles();
    const [previewType, setPreviewType] = useState('2d');
    const [countLike, setCountLike] = useState(nft?.NftLikes?.length);
    const [showModal, setShowModal] = useState(false);
    const [render, setRender] = useState(false);
    const [actionType, setActionType] = useState();
    const [showAlertModal, setShowAlertModal] = useState(false);
    const [serverError, setServerError] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    const signedUser = useSelector(selectors.user);
    const authorsState = useSelector(selectors.authorsState);
    const authors = authorsState.data ? authorsState.data : [];

    const isVoted = useRef(false);
    const isWishlisted = useRef(false);

    const dispatch = useDispatch();

    const handleRedirectToItem=(id)=>{
        history.push({pathname:`/nft/${id}`})
        localStorage.setItem("id",id)
    }

    useEffect(() => {
        if(nft.NftLikes) {
            for(let i=0; i<nft.NftLikes.length; i++) {
                if(signedUser.metamask === nft.NftLikes[i].User.metamask.toLowerCase()) {
                    isVoted.current = true;
                }
            }
        }

        if(nft.NftWishlists) {
            for(let i=0; i<nft.NftWishlists.length; i++) {
                if(signedUser.metamask === nft.NftWishlists[i].User.metamask.toLowerCase()) {
                    isWishlisted.current = true;
                }
            }
        }

    }, [nft]);

    useEffect(() => {
        setRender(!render);
    }, [isVoted.current, isWishlisted.current]);

    const handleLike = async (userData) => {
        setActionType();
        if(nft.Likes) {
            for(let i=0; i<nft.Likes.length; i++) {
                if(userData.metamask === nft.Likes[i].User.metamask.toLowerCase()) {
                    isVoted.current = true;
                }
            }
        }

        if(!isVoted.current) {
            dispatch(setActions.setLoading(true));
            try {
                const res = await setNftLike(nft?.Asset?.id, nft?.id);
                setCountLike(res.likes);
                isVoted.current = true;
                setShowAlertModal(true);
                setAlertMessage('Your like has been added.');
                dispatch(actions.fetchNotifications());
                if (asset) {
                    dispatch(actions.fetchAssetDetail(asset.id));
                }
                dispatch(setActions.showLoadMoreNFTLike(true));
                dispatch(setActions.clearNftLikes());
                dispatch(actions.fetchNftLikeBreakdown(userData.id, 0));
            } catch(e) {
                alert(e);
            }
            dispatch(setActions.setLoading(false));
        }
        else {
            dispatch(setActions.setLoading(true));
            try {
                const res = await setNftDislike(nft?.id);
                setCountLike(res.likes);
                isVoted.current = false;
                dispatch(actions.fetchNotifications());
                if (asset) {
                    dispatch(actions.fetchAssetDetail(asset.id));
                }
                dispatch(setActions.showLoadMoreNFTLike(true));
                dispatch(setActions.clearNftLikes());
                dispatch(actions.fetchNftLikeBreakdown(userData.id, 0));
                setShowAlertModal(true);
                setAlertMessage('Your like has been removed.');
            } catch(e) {
                alert(e);
            }
            dispatch(setActions.setLoading(false));
        }
    }

    const onClickLike = () => {
        if(signedUser.id) {
            handleLike(signedUser);
        }
        else {
            setShowModal(true);
            setActionType('like');
        }
    }

    const onClickBasket = () => {
        if(signedUser.id) {
            handleWishlist(signedUser);
        }
        else {
            setShowModal(true);
            setActionType('wishlist');
        }
    }

    const handleWishlist = async (userData) => {
        setActionType();

        if(nft.Wishlists) {
            for(let i=0; i<nft.Wishlists.length; i++) {
                if(userData.metamask === nft.Wishlists[i].User.metamask.toLowerCase()) {
                    isWishlisted.current = true;
                }
            }
        }

        if(!isWishlisted.current) {
            dispatch(setActions.setLoading(true));
            try {
                const res = await setNftWishlist(nft?.Asset?.id, nft?.id);
                dispatch(actions.fetchNotifications());
                dispatch(setActions.showLoadMoreNFTWishlist(true));
                dispatch(setActions.clearNftWishlists());
                dispatch(actions.fetchNftWishlistBreakdown(userData.id, 0));
                isWishlisted.current = true;
                setShowAlertModal(true);
                setAlertMessage('This NFT has been added to your wishlist.');
            } catch(e) {
                alert(e);
            }
            dispatch(setActions.setLoading(false));
          }
          else {
            dispatch(setActions.setLoading(true));
            try {
                const res = await setNftDiswishlist(nft?.id);
                // await createNotification({
                //     'desc': `This NFT - #${nft?.id} has been removed from your wishlist.`,
                //     'url': `${window.location.origin}/3D_NFT/${nft?.id}/${nft?.itemId}`,
                // });
                dispatch(actions.fetchNotifications());
                dispatch(setActions.showLoadMoreNFTWishlist(true));
                dispatch(setActions.clearNftWishlists());
                dispatch(actions.fetchNftWishlistBreakdown(userData.id, 0));
                isWishlisted.current = false;
                setShowAlertModal(true);
                setAlertMessage('This NFT has been removed from your wishlist.');
            } catch(e) {
                alert(e);
            }
            dispatch(setActions.setLoading(false));
          }
    }

    const handleAuth = async () => {
        const userData = await login(dispatch, setActions.setLoading, setActions.setUser, history, setServerError);
        setShowModal(false);
        if(userData && actionType === 'like') {
            handleLike(userData);
        }
        if(userData && actionType === 'wishlist') {
            handleWishlist(userData);
        }
    }

    return (
        <div className={className}>
            <div className="nft__item m-0">
            { nft.item_type === 'single_items' ? (
             <div className='icontype'><i className="fa fa-bookmark"></i></div>   
             ) : (  
             <div className={isWishlisted.current ? "icontype_selected" :"icontype"}><i className="fa fa-shopping-basket" onClick={() => onClickBasket()}></i></div>
                )
            }
                { nft.deadline && clockTop &&
                    <div className="de_countdown">
                        <Clock deadline={nft.deadline} />
                    </div>
                }
                <div className="author_list_pp">
                {/* onClick={()=> navigateTo(nft.author_link)} */}
                    { nft?.User && nft?.User?.picture ?
                        <span >
                            <img className="lazy" src={nft.User ? `${api.baseUrl}/${nft.User.picture}` : ""} alt="" onClick={() => {
                                if(authors.findIndex(e => e.id === nft?.User?.id) > -1)
                                    history.push({pathname:`/author/${nft.User.id}`});
                            }}/>
                            <i className="fa fa-check"></i>
                        </span>
                        :
                        <span >
                            <img className="lazy" src="/img/author/author-11.png" alt="" onClick={() => {
                                if(authors.findIndex(e => e.id === nft?.User?.id) > -1)
                                    history.push({pathname:`/author/${nft.User.id}`});
                            }}/>
                        </span>
                    }
                </div>
                <div className="nft__item_wrap" style={{height: `${height}px`, position: 'relative', marginRight: 'auto', marginLeft: 'auto'}}>
                <Outer>
                    <span>
                        { asset?.gltf ?
                            (previewType === '3d' ? 
                                <Nft3dCard src={`${api.server3dnTech}/${asset.directory}/${asset.gltf}`} width={250} height={250} />
                                :
                                <NavLink to={`/3D_NFT/${nft.id}/${nft?.itemId}`}>
                                    <img onLoad={onImgLoad} src={api.server3dnTech + '/' + asset.directory + '/' + asset.directory + '.png'} className="lazy nft__item_preview" alt="" />
                                </NavLink>
                            )
                            : 
                            <NavLink to={`/3D_NFT/${nft.id}/${nft?.itemId}`}>
                                <img onLoad={onImgLoad} src={api.ipfsURL + asset.cid} className="lazy nft__item_preview" alt="" />
                            </NavLink>
                        }
                    </span>
                </Outer>
                </div>
                { nft.deadline && !clockTop &&
                    <div className="de_countdown">
                        <Clock deadline={nft.deadline} />
                    </div>
                }
                <div className="nft__item_info">
                    {/* <span onClick={() => navigateTo(`${nft.nft_link}/${nft.id}`)}> */}
                    <NavLink to={`/3D_NFT/${nft.id}/${nft?.itemId}`}>
                        <span>
                            <h4>{asset.title}</h4>
                        </span>
                    </NavLink>
                    { nft.status === 'has_offers'  && !account &&
                        <div className="has_offers">
                            <NavLink to={`/3D_NFT/${nft.id}/${nft?.itemId}`}>
                                <p><span className='through'>{nft.priceover}</span> {Web3.utils.fromWei(nft.price, 'gwei') * 1000} USDC (1/30)</p>
                            </NavLink>
                        </div>}
                    {/* { nft && user && asset?.User?.metamask !== user?.get('ethAddress') && <div className="nft__item_action">
                        <span onClick={() => handleRedirectToItem(nft.id)}>{ nft.status === 'on_auction' ? t("PlaceBid") : t("BuyNow") }</span>
                    </div>} */}
                    <div className="nft__item_price">
                        <NavLink to={`/3D_NFT/${nft.id}/${nft?.itemId}`}>
                            <p className={classes.cardPrice}>{`Item ID: #${nft.itemId}`}</p>
                        </NavLink>
                        <NavLink to={`/3D_NFT/${nft.id}/${nft?.itemId}`}>
                            <p className={classes.cardPrice}>{Web3.utils.fromWei(nft.price, 'gwei') * 1000} USDC</p>
                        </NavLink>
                        {/* <p className={classes.cardPrice} onClick={() => handleRedirectToItem(nft.id)}>{(Web3.utils.fromWei(nft.price, 'gwei') * priceRate * 1000).toFixed(2)} USDT</p> */}
                        <p className={classes.cardPrice}>{nft?.variable && nft?.variable?.find(item => item.variable === 'Level') && <>Level: {nft?.variable?.find(item => item.variable === 'Level')?.value}</>}</p>
                    </div>
                    <div className={isVoted.current? "nft__item_liked" :"nft__item_like"}>
                        <i className="fa fa-heart" onClick={() => onClickLike()}></i><span>{countLike}</span>
                    </div>                            
                </div>
                { asset?.gltf &&
                    <NavLink to={`/3D_NFT/${nft.id}/${nft?.itemId}`}>
                        <button className='btn-main lead mb-2 nft-card-button'>{previewType === '2d' ? 'View as 3D' : 'View as 2D'}</button>
                    </NavLink>
                }
            </div>
            <Dialog
                open={showModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className='alert-modal'
            >
                <div className={classes.root}>
                    <div className={classes.topBlock}>
                        <CheckCircleOutlinedIcon className={classes.icon} />
                    </div>  
                    <div className={classes.bottomBlock}>
                        <Typography className={classes.title}>
                            {t("You need to connect your wallet first!")}
                        </Typography>
                        <div className={classes.buttonBlock}>
                        {/* <Button
                            variant="contained"
                            color="inherit"
                            className={classes.buttonResend}
                            onClick={handleAuth}
                        >
                            {t("Connect")}
                        </Button> */}
                            <button
                                className="btn-main"
                                onClick={()=>setShowModal(false)}
                            >
                                {t("Close")} 
                            </button>
                            <button
                                className="btn-main"
                                onClick={handleAuth}
                            >
                                {t("Connect")}
                            </button>
                            
                        </div>
                    </div>
                </div>
            </Dialog>
            <Dialog
                open={showAlertModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className='alert-modal'
            >
                <div className={classes.root}>
                    <div className={classes.topBlock}>
                        <CheckCircleOutlinedIcon className={classes.icon} />
                    </div>  
                    <div className={classes.bottomBlock}>
                        <Typography className={classes.title}>
                            {alertMessage}
                        </Typography>
                        <div className={classes.buttonCancelBlock}>
                            <button
                                className="btn-main"
                                onClick={()=>{setShowAlertModal(false)}}
                            >
                                {t("Close")} 
                            </button>
                        </div>
                    </div>
                </div>
            </Dialog>
            <ModalNoMetamask open={serverError} onClose={() => {setServerError(false);}}/>
        </div>
    );
};

export default memo(NftCard);
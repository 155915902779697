const InitaleState={
    id:null,
    img:null,
    name:null,
    locationX:null,
    locationY:null,
    userInfo:null,
    map:null,
    price:null,
    isSold:false,
    description:null
}
 
 
 const nftDetailsReducer=(state=InitaleState,action)=>{
    switch (action.type) {
        case "DISPTACH_NFT_DETAILS":            
          return action.payload    
        default:
          return   state;
    }
}
export default nftDetailsReducer
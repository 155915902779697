export const MAP_WIDTH = 890;
export const MAP_HEIGHT = 690;
export const ZOOM_RATE = 2;
export const MAP_START_X = -467;
export const MAP_START_Y = -674;
export const MAP_LAND_COLOR = '#e6ddc8';
export const MAP_SEA_COLOR = '#289be2';
export const MAP_SOLD_COLOR = '#a706aa';
export const MAP_MY_LAND_COLOR = '#A706AA';
export const MAP_INFRA_COLOR = '#000000';
export const MAP_LAND_LABEL = "GROUND";
export const MAP_SEA_LABEL = "SEA";
export const MAP_FOR_SALE_COLOR = 'rgb(0, 102, 0)';
export const TIER1 = '#47fe00';
export const TIER2 = '#ffb500';
export const TIER3 = '#ff0000';
export const TIER4 = '#ffff00';
export const TIER5 = '#0017fc';
export const LAND_PACK = '#6D200F';
export const MAP_OVERLAY_COLOR = 'rgb(213, 213, 213, 0.3)';
export const MAP_STAR_COLOR = 'rgb(214, 152, 69)';
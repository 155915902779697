function formatNumberWithSpaceSeparator(number) {
    // Convert the number to a string and split it into integer and decimal parts
    const [integerPart, decimalPart] = number.toString().split('.');
  
    // Insert spaces every 3 digits from the end of the integer part
    const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  
    // Join the integer part and decimal part, if there's a decimal part
    return decimalPart ? `${formattedIntegerPart}.${decimalPart}` : formattedIntegerPart;
  }
  
export { formatNumberWithSpaceSeparator };  
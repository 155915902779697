import { memo, useEffect,useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import Pagination from '@material-ui/lab/Pagination';
import { useHistory } from "react-router";

import Footer from '../../components/footer';
import * as selectors from '../../../../store/selectors';
import { fetchCurrentAuthor } from "../../../../store/actions/thunks";
import { clearAssets } from "../../../../store/actions";
import api from "../../../../core/api";
import { StyledHeader } from '../../Styles';
import useStyles from "../styles/Authors/authors"
import { useTranslation } from "react-i18next";
import * as actions from "../../../../store/actions/thunks";
import * as loadingActions from '../../../../store/actions';
import Web3 from "web3";
import { Typography, Dialog } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { TextField } from '@material-ui/core';
import Select from 'react-select';
import { ReactNotifications } from 'react-notifications-component';
import Header from "../../menu/header";
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import * as CategoryService from "../../../../services/CategoryService";
import * as DataService from "../../../../services/DataService";
import * as AssetService from "../../../../services/AssetService";
import Notification from "../../../../utils/functions/notification";

//SWITCH VARIABLE FOR PAGE STYLE
const theme = 'GREY'; //LIGHT, GREY, RETRO

const StyledTableCell = withStyles((theme) => ({
  head: {
      backgroundColor: '#30166e',
      color: theme.palette.common.white,
  },
  body: {
      fontSize: 14,
  },
}))(TableCell);

function Collections() {
    const {t}=useTranslation();
    const classes=useStyles();
    const dispatch = useDispatch();
    const history=useHistory();

    const assetItems = useSelector(selectors.assetItems);
    const nftCount = useSelector(selectors.assetCount);
    const signedUser = useSelector(selectors.user);    
    const nfts = assetItems ? assetItems : [];
    const currentAuthorState = useSelector(selectors.currentAuthorState);
    const author = currentAuthorState.data ? currentAuthorState.data : {};
    // eslint-disable-next-line
    const [indexPage, setIndexPage] = useState(0);
    const [selectedPage, setSelectedPage] = useState(1);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [isDeletable, setDeletable] = useState(true);
    const [selectedId, setSelectedId] = useState();
    const [priceRate, setPriceRate] = useState(0);
    const [category, setCategory] = useState(); //Default is `Transportation`
    const [categories, setCategories] = useState([]);
    const [categoryFilterList, setCategoryFilterList] = useState([]);
    const [isUpdate, setUpdate] = useState(false);
    const [query, setQuery] = useState('');

    useEffect(() => {
      CategoryService.fetchCategory().then((res => {
        const filteredCategories = res;
        setCategories(filteredCategories);
        generateOptionsById(0, filteredCategories).then((result) => {
          setCategoryFilterList([
            {level: 0, parent: 0, selected: null, options: result},
          ]);
        })
      }));

      // fetchPriceRate();
      // const timer = setTimeout(() => {
      //     fetchPriceRate();
      // }, 60000);

      // return () => clearTimeout(timer);
    }, []);

    // const fetchPriceRate = () => {
    //   DataService.fetchExchangeData().then((res => {
    //     setPriceRate(res);
    //   }));
    // }

    const generateOptionsById = async (id, data) => {
      let options = [];
      const tempOptions = data.filter((item) => item.parent === id);
      for(let i=0; i<tempOptions.length; i++) {
        options.push({
          value: tempOptions[i].id,
          label: tempOptions[i].name
        });
      }
  
      return options;
    }
  
    const handleFilterItemSelect = (level, value) => {
      let currentFilterItem = categoryFilterList.find((item) => item.level === level);
      currentFilterItem.selected = value;
      let tempCategoryFilterList = categoryFilterList.filter((item) => item.level < level);
      tempCategoryFilterList.push(currentFilterItem);
      setCategoryFilterList(tempCategoryFilterList);
      setUpdate(!isUpdate);
  
      if(value) {
        generateOptionsById(value, categories).then((result) => {
          if(result.length > 0) {
            tempCategoryFilterList.push({level: level + 1, parent: value, selected: null, options: result});
            setCategoryFilterList(tempCategoryFilterList);
          }
          setUpdate(!isUpdate);
          setCategory(value);
        });
      }
      else {
        setCategoryFilterList(tempCategoryFilterList);
        setUpdate(!isUpdate);
        setCategory(false);
      }
      
    }

    const defaultValue = {
      value: null,
      label: t("SelectFilter")
    };
  
    const customStyles = {
      option: (base, state) => ({
          ...base,
          background: "#fff",
          color: "#333",
          borderRadius: state.isFocused ? "0" : 0,
          "&:hover": {
              background: "#eee",
          }
      }),
      menu: base => ({
          ...base,
          borderRadius: 0,
          marginTop: 0
      }),
      menuList: base => ({
          ...base,
          padding: 0
      }),
      control: (base, state) => ({
          ...base,
          padding: 2
      })
    };
  
    useEffect(() => {
      if(signedUser.id) {
        dispatch(fetchCurrentAuthor(signedUser.id));
      }
    }, [dispatch, signedUser.id]);

    useEffect(() => {
      setIndexPage(0);
      setSelectedPage(1);
      if(author.id && query !== undefined && author.id === signedUser.id) {
        dispatch(clearAssets());
        if(category) {
          dispatch(actions.fetchAssetsBreakdown(author.id, 0, query, category));
        }
        else {
          dispatch(actions.fetchAssetsBreakdown(author.id, 0, query));
        }
      }
      // eslint-disable-next-line
    }, [author.id, category])

    useEffect(() => {
      setUpdate(!isUpdate);
      // eslint-disable-next-line
    }, [nfts])

    const handleSelectPage=(event,newPage)=>{
      dispatch(clearAssets());
      if(category) {
        dispatch(actions.fetchAssetsBreakdown(author.id, newPage-1, query, category));
      }
      else {
        dispatch(actions.fetchAssetsBreakdown(author.id, newPage-1, query));
      }
      setSelectedPage(newPage);
      setIndexPage(newPage-1)
    }
    const handleDeleteItem=(id)=>{
      dispatch(loadingActions.setLoading(true));
      try {
        AssetService.deleteAsset(id).then(res => {
          setShowDeleteModal(false);
          dispatch(clearAssets());
          setIndexPage(0)
          if(category) {
            dispatch(actions.fetchAssetsBreakdown(author.id, 0, query, category));
          }
          else {
            dispatch(actions.fetchAssetsBreakdown(author.id, 0, query));
          }
          dispatch(loadingActions.setLoading(false));
          Notification(`Your Asset has been removed from the marketplace.`, true);
        }).catch(error => {
          dispatch(loadingActions.setLoading(false));
          let msg = (typeof error === 'object') ? error.message : error;
          Notification(msg, false);
        });
      } catch(error) {
        dispatch(loadingActions.setLoading(false));
        let msg = (typeof error === 'object') ? error.message : error;
        Notification(msg, false);
      }
    } 

    const handleClickSearch = () => {
      setIndexPage(0);
      setSelectedPage(1);
      if(author.id && query !== undefined) {
        dispatch(clearAssets());
        if(category) {
          dispatch(actions.fetchAssetsBreakdown(author.id, 0, query, category));
        }
        else {
          dispatch(actions.fetchAssetsBreakdown(author.id, 0, query));
        }
      }
    }

    const handleRedirectToEditItem=(id)=>{
      history.push({pathname:`/3D_Asset/edit/${id}`})
    }

    const tokenDescription = (description) => {
      if(description.length > 20) {
        return description.slice(0, 20) + '...';
      }
      return description;
    }

  return (
   <div>
      {/* <GlobalStyles/> */}
      <StyledHeader theme={theme} />
      <Header className=""/>
      <ReactNotifications />
      <section className='jumbotron breadcumb no-bg' style={{backgroundImage: `url(${'./img/background/subheader.jpg'})`}}>
        <div className='mainbreadcumb'>
          <div className='container'>
            <div className='row m-10-hor'>
              <div className='col-12'>
                <h1 className='text-center'>{t("My Collections")}</h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='container'>
        <div className='row'>
          {categoryFilterList.length > 0 && <div className='col-lg-12 flex-display mb-20' style={{justifyContent: 'right'}}>
            <TextField
              id="outlined-number"
              type="text"
              variant="outlined"
              placeholder='Search'
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              inputProps={{
                style: {
                  height: 0,
                },
              }}
        
            />
            <span onClick={() => handleClickSearch()} className="btn-main ml-20">{t("Search")}</span>
          </div>}
          <div className='col-lg-12'>
            <div className="items_filter">
              {
                categoryFilterList.map((filterItem, index) => (
                  <div className='dropdownSelect one' key={index}>
                    <Select
                      value={filterItem.options.find((option) => option.value === filterItem.selected)}
                      styles={customStyles} 
                      menuContainerStyle={{'zIndex': 999}}
                      options={[defaultValue, ...filterItem.options]}
                      onChange={(option) => handleFilterItemSelect(index, option.value)}
                    />
                  </div>
                ))
              }
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-12 collection__table_wrapper'>
            <TableContainer component={Paper}>
              <Table className={classes.collectionTable}>
                <TableHead>
                  <TableRow>
                    <StyledTableCell scope="col">{"ID"}</StyledTableCell>
                    <StyledTableCell scope="col">{"Preview"}</StyledTableCell>
                    <StyledTableCell scope="col">{"Name"}</StyledTableCell>
                    <StyledTableCell scope="col">{"Fixed Price / Bid for Auction"}</StyledTableCell>
                    <StyledTableCell scope="col">{"Activated / Not Activated"}</StyledTableCell>
                    <StyledTableCell scope="col">{"Category"}</StyledTableCell>
                    <StyledTableCell scope="col">{"Description"}</StyledTableCell>
                    <StyledTableCell scope="col">{"Price in USDC"}</StyledTableCell>
                    {/* <StyledTableCell scope="col">{"Price in USDT"}</StyledTableCell> */}
                    <StyledTableCell scope="col">{"Number of Max Items to sell"}</StyledTableCell>
                    <StyledTableCell scope="col">{"Number of Items Sold"}</StyledTableCell>
                    <StyledTableCell scope="col">{"Total USDC Sold"}</StyledTableCell>
                    {/* <StyledTableCell scope="col">{"Total USDT Sold"}</StyledTableCell> */}
                    <StyledTableCell scope="col"></StyledTableCell>
                  </TableRow>
                  <TableRow></TableRow>
                </TableHead>
                <tbody>
                  {
                    nfts && nfts.map((nft, index) => (
                      <TableRow key={index} className={classes.tableRow} onClick={(event) => {if(!event.target.id.includes('action_btn_')) {handleRedirectToEditItem(nft.id)}}}>
                        <StyledTableCell>{nft?.id}</StyledTableCell>
                        <StyledTableCell>
                          { nft.gltf 
                          ? <img src={api.server3dnTech + '/' + nft.directory + '/' + nft.directory + '.png?' + (new Date())} className="collection__preview_img" alt=""/>
                          : <img src={api.ipfsURL + nft.image} className="collection__preview_img" alt=""/> }
                        </StyledTableCell>
                        <StyledTableCell>{nft.title}</StyledTableCell>
                        <StyledTableCell>
                          {nft.isForBid ? 'Bid for Auction' : 'Fixed Price'}
                        </StyledTableCell>
                        <StyledTableCell>
                          {nft.listedAsset ? 'Activated' : 'Not Activated'}
                        </StyledTableCell>
                        <StyledTableCell>{nft?.Category?.name}</StyledTableCell>
                        <StyledTableCell title={nft.description}>{tokenDescription(nft.description)}</StyledTableCell>
                        <StyledTableCell>{Web3.utils.fromWei(nft.price, 'gwei') * 1000} USDC</StyledTableCell>
                        {/* <StyledTableCell>{(Web3.utils.fromWei(nft.price, 'gwei') * priceRate * 1000).toFixed(2)} USDT</StyledTableCell> */}
                        <StyledTableCell>{nft?.numberOfItems}</StyledTableCell>
                        <StyledTableCell>{nft?.Nfts.length}</StyledTableCell>
                        <StyledTableCell>{nft?.Nfts.length * Web3.utils.fromWei(nft.price, 'gwei') * 1000}</StyledTableCell>
                        {/* <StyledTableCell>{nft?.number_of_minted ? nft?.number_of_minted * Web3.utils.fromWei(nft.price, 'gwei') * priceRate * 1000 : ''}</StyledTableCell> */}
                        <StyledTableCell style={{display: 'flex', borderBottomWidth: 0}}>
                          <span id={"action_btn_edit_" + index} onClick={(event) => { handleRedirectToEditItem(nft.id);}} className="btn-main lead me-2">Edit</span>
                          <span id={"action_btn_delete_" + index } onClick={(event) => { setSelectedId(nft.id); setShowDeleteModal(true); setDeletable(!nft?.Nfts.length > 0)}} className="btn-main lead me-2">Delete</span>
                          {/* <span id={"action_btn_detail_" + index } onClick={(event) => { history.push({pathname: `/collection/${nft.id}`})}} className="btn-main lead me-2">Detail</span> */}
                        </StyledTableCell>
                      </TableRow>
                    ))
                  }
                </tbody>
              </Table>
            </TableContainer>
          </div>
          <div className={classes.root}>
            <Pagination
            page={selectedPage} 
            count={Math.ceil(nftCount/12)} 
            onChange={handleSelectPage}
              variant="outlined"
              shape="rounded" />
          </div>
          {/* <div className="spacer-double"></div>
          <div className={classes.buttonWrapper}>
            <span onClick={null} className="btn-main lead m-auto">CHECK MY ROYALTIES</span>
            <span onClick={null} className="btn-main lead m-auto">CLAIM MY ROYALTIES</span>
          </div> */}
        </div>
      </section>
      <Dialog
        open={showDeleteModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className='alert-modal'
      >
        <div className={classes.rootModal}>
          <div className={classes.topBlock}>
              <ErrorOutlineIcon className={classes.icon} />
          </div>  
          <div className={classes.bottomBlock}>
              <Typography className={classes.title}>
                  {t("Attention")}
              </Typography>
              <Typography className={classes.supTitle}>         
                {isDeletable ? 'Are you sure you want to delete this asset?' : 'This asset has already been minted and sold. It can not be removed. To reduce the number of assets to be sold, please edit this item information and change value in the field « Number of Item to Mint ».'}
              </Typography>
              <div className={classes.buttonBlock}>
                <button
                    className="btn-main-cancel"
                    onClick={()=>setShowDeleteModal(false)}
                >
                {t("Cancel")} 
                </button>
                {isDeletable && <button
                    className="btn-main"
                    onClick={() => handleDeleteItem(selectedId)}
                >
                    {t("OK")}
                </button>}
                {!isDeletable && <button
                    className="btn-main"
                    onClick={() => handleRedirectToEditItem(selectedId)}
                >
                    {t("Edit")}
                </button>}
              </div>
          </div>
        </div>
      </Dialog>
      <Footer />
    </div>
  )
}

export default memo(Collections);
import React, { memo, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as selectors from '../../../store/selectors';
import * as actions from '../../../store/actions/thunks';
import { clearFilter, 
    showLoadMoreNFTLike, 
    clearNftLikes, 
    showLoadMoreNFTWishlist,
    clearNftWishlists,
    showLoadMoreAssetLike,
    clearAssetLikes,
    showLoadMoreAssetWishlist,
    clearAssetWishlists,
    showLoadMoreTileLike,
    clearTileLikes,
    showLoadMoreTileWishlist,
    clearTileWishlists
} from '../../../store/actions';
import NftCard from './NftCard';
// import AssetCard from './NftMusicCard';
import AssetCard from './AssetCard';
import { shuffleArray } from '../../../store/utils';
import { useTranslation } from 'react-i18next';
import TileCard from './TileCard';
import TileGroupCard from './TileGroupCard';

//react functional component
const ColumnProfileRedux = ({ priceRate, shuffle = false, authorId = null, itemType, votedType }) => {
    const {t}=useTranslation();
    const dispatch = useDispatch();
    const votedItems = useSelector(itemType === 'nft' ? 
    (votedType === 'like' ? selectors.nftLikeItems : selectors.nftWishlistItems) : ( itemType === 'asset' ? 
    (votedType === 'like' ? selectors.assetLikeItems : selectors.assetWishlistItems)
    : (votedType === 'like' ? selectors.tileLikeItems : selectors.tileWishlistItems)));

    const votedCount = useSelector(itemType === 'nft' ? 
    (votedType === 'like' ? selectors.nftLikeCount : selectors.nftWishlistCount) : ( itemType === 'asset' ?
    (votedType === 'like' ? selectors.assetLikeCount : selectors.assetWishlistCount) : 
    (votedType === 'like' ? selectors.tileLikeCount : selectors.tileWishlistCount))); 

    const showLoadMore = useSelector(itemType === 'nft' ? 
    (votedType === 'like' ? selectors.showLoadMoreNftLike : selectors.showLoadMoreNftWishlist) : ( itemType === 'asset' ?
    (votedType === 'like' ? selectors.showLoadMoreAssetLike : selectors.showLoadMoreAssetWishlist) : 
    (votedType === 'like' ? selectors.showLoadMoreTileLike : selectors.showLoadMoreTileWishlist)));

    const voted = votedItems ? shuffle ? shuffleArray(votedItems) : votedItems : [];
    const [page, setPage] = useState(0);
    // const [height, setHeight] = useState(350);

    const onImgLoad = ({target:img}) => {
        // let currentHeight = height;
        // if(currentHeight < img.offsetHeight) {
        //     setHeight(img.offsetHeight);
        // }
    }
   
    useEffect(() => {
        if(itemType === 'nft') {
            if(votedType === 'like') {
                dispatch(showLoadMoreNFTLike(true));
                dispatch(clearFilter());
                dispatch(clearNftLikes());
                dispatch(actions.fetchNftLikeBreakdown(authorId, page));
            }
            else {
                dispatch(showLoadMoreNFTWishlist(true));
                dispatch(clearFilter());
                dispatch(clearNftWishlists());
                dispatch(actions.fetchNftWishlistBreakdown(authorId, page));
            }
        }
        else if(itemType === 'asset'){
            if(votedType === 'like') {
                dispatch(showLoadMoreAssetLike(true));
                dispatch(clearFilter());
                dispatch(clearAssetLikes());
                dispatch(actions.fetchAssetLikeBreakdown(authorId, page));
            }
            else {
                dispatch(showLoadMoreAssetWishlist(true));
                dispatch(clearFilter());
                dispatch(clearAssetWishlists());
                dispatch(actions.fetchAssetWishlistBreakdown(authorId, page));
            }
        }
        else {
            if(votedType === 'like') {
                dispatch(showLoadMoreTileLike(true));
                dispatch(clearFilter());
                dispatch(clearTileLikes());
                dispatch(actions.fetchTileLikeBreakdown(authorId, page));
            }
            else {
                dispatch(showLoadMoreTileWishlist(true));
                dispatch(clearFilter());
                dispatch(clearTileWishlists());
                dispatch(actions.fetchTileWishlistBreakdown(authorId, page));
            }
        }
        setPage(page + 1);
    }, [dispatch]);

    const loadMore = () => {
        if(itemType === 'nft') {
            if(votedType === 'like') {
                dispatch(actions.fetchNftLikeBreakdown(authorId, page));
            }
            else {
                dispatch(actions.fetchNftWishlistBreakdown(authorId, page));
            }
        }
        else if(itemType === 'asset') {
            if(votedType === 'like') {
                dispatch(actions.fetchAssetLikeBreakdown(authorId, page));
            }
            else {
                dispatch(actions.fetchAssetWishlistBreakdown(authorId, page));
            }
        }
        else {
            if(votedType === 'like') {
                dispatch(actions.fetchTileLikeBreakdown(authorId, page));
            }
            else {
                dispatch(actions.fetchTileWishlistBreakdown(authorId, page));
            }
        }
        setPage(page + 1);
    }

    return (
        <div className='row'>
            {voted && voted.map( (vote, index) => (
                itemType === 'asset' ?
                <AssetCard priceRate={priceRate} nft={vote.Asset} key={index} onImgLoad={onImgLoad} account={authorId} height={250}/>
                :
                (itemType === 'nft' ? <NftCard priceRate={priceRate} nft={vote.Nft} key={index} account={authorId} height={250} asset={vote.Nft.Asset}/> : <>{vote.isGroup ? <TileGroupCard priceRate={priceRate} nft={vote.TileGroup} key={index} account={authorId} height={200}/> : <TileCard nft={vote.Tile} key={index} account={authorId} height={200} /> }</>)
            //    <AssetCard priceRate={priceRate} nft={nft} key={index} onImgLoad={onImgLoad} account={authorId} height={height}/>
            ))}
            { showLoadMore && voted.length < votedCount &&
                <div className='col-lg-12'>
                    <div className="spacer-single"></div>
                    <span onClick={loadMore} className="btn-main lead m-auto">{t("LoadMore")}</span>
                </div>
            }
        </div>              
    );
};

export default memo(ColumnProfileRedux);
import { makeStyles, Theme } from '@material-ui/core';
import { ECOLORS } from '../../../utils/constants/colors.enum';

export default makeStyles((theme: Theme) => ({
    root:{
        width:450,
        height:400
    },
    sessionRoot:{
        width:450,
    },
    topBlock:{
        height:130,
        backgroundColor:ECOLORS.RED_SECONDARY,
        display:"flex",
        justifyContent:"center",
        alignItems:"center"
    },
    icon:{
        cursor: 'pointer',
        color:"white",
        height:80,
        width:80
    },
    bottomBlock:{
        marginTop:20,
        marginLeft:20,
        marginRight:20,
        display:"flex",
        flexDirection:"column",
        justifyContent:"center",
        alignItems:"center"
    },
    title:{
        fontWeight:"bold",
        fontSize:18,
        color:ECOLORS.PRIMARY_DARK
    },
    supTitle:{
        fontWeight:"bold",
        fontSize:15,
        color:ECOLORS.PRIMARY_DARK,
        margin:20
    },
    button:{
        backgroundColor:ECOLORS.RED_SECONDARY,
        borderRadius:30,
        marginTop:20,
        boxShadow:"0px 0px 0px 0px"
    },
    buttonBlock: {
        display:"flex",
        marginTop:30,
        marginBottom:30
    },
    ueLoginTopBlock: {
        height:150,
        backgroundColor:ECOLORS.PRIMARY_LIGHT,
        display:"flex",
        justifyContent:"center",
        alignItems:"center"
    },
    igwClaimSubmit: {
        marginLeft: 10,
        marginBottom: 20
    },
    igwTitle: {
        fontWeight:"bold",
        fontSize:15,
        color:ECOLORS.PRIMARY_DARK,
        marginBottom: 15
    },
    noSessionTitle: {
        fontWeight:"bold",
        fontSize:25,
        color:ECOLORS.PRIMARY_DARK,
        marginBottom: 15
    },
    inputBlock: {
        display: 'flex',
        alignItems: 'center'
    },
    inGameBlock: {
        marginTop:20,
        paddingRight: 15,
        paddingLeft: 15,
    },
    modalLink: {
        marginTop: 10,
        fontSize: 14,
        cursor: 'pointer'
    }
}));
import { getType } from 'typesafe-actions';
import * as actions from '../actions';
import { initEntityState, entityLoadingStarted, entityLoadingSucceeded, entityLoadingFailed } from '../utils';

export const defaultState = {
  assetBreakdown: initEntityState(null),
  assetDetail: initEntityState(null),
  nftBreakdown: initEntityState(null),
  nftLikeBreakdown: initEntityState(null),
  assetLikeBreakdown: initEntityState(null),
  tileLikeBreakdown: initEntityState(null),
  nftWishlistBreakdown: initEntityState(null),
  assetWishlistBreakdown: initEntityState(null),
  tileWishlistBreakdown: initEntityState(null),
  nftDetail: initEntityState(null),
  nftShowcase: initEntityState(null),
  tileBreakdown: initEntityState(null),
  tileGroupBreakdown: initEntityState(null),
  tileDetail: initEntityState(null),
  tileGroupDetail: initEntityState(null),
  showLoadMore: initEntityState(true),
  showLoadMoreNft: initEntityState(true),
  showLoadMoreNftLike: initEntityState(true),
  showLoadMoreAssetLike: initEntityState(true),
  showLoadMoreTileLike: initEntityState(true),
  showLoadMoreNftWishlist: initEntityState(true),
  showLoadMoreAssetWishlist: initEntityState(true),
  showLoadMoreTileWishlist: initEntityState(true),
  showLoadMoreTile: initEntityState(true),
  showLoadMoreTileGroup: initEntityState(true),
  nftCount: initEntityState(0),
  nftLikeCount: initEntityState(0),
  assetLikeCount: initEntityState(0),
  tileLikeCount: initEntityState(0),
  nftWishlistCount: initEntityState(0),
  assetWishlistCount: initEntityState(0),
  tileWishlistCount: initEntityState(0),
  assetCount: initEntityState(0),
  tileCount: initEntityState(0),
  tileGroupCount: initEntityState(0),
  balance: 0,
  continent: 0,
};

const states = (state = defaultState, action) => {
  switch (action.type) {
    
    case getType(actions.getAssetBreakdown.request):
      return { ...state, assetBreakdown: entityLoadingStarted(state.assetBreakdown, action.payload) };
    case getType(actions.getAssetBreakdown.success):
      //append existing data with new data
      let payload = state.assetBreakdown.data ? [...state.assetBreakdown.data, ...action.payload] : action.payload;
      return { ...state, assetBreakdown: entityLoadingSucceeded(state.assetBreakdown, payload) };
    case getType(actions.getAssetBreakdown.failure):
      return { ...state, assetBreakdown: entityLoadingFailed(state.assetBreakdown) };

    case getType(actions.getTileBreakdown.request):
      return { ...state, tileBreakdown: entityLoadingStarted(state.tileBreakdown, action.payload) };
    case getType(actions.getTileBreakdown.success):
      //append existing data with new data
      let tilePayload = state.tileBreakdown.data ? [...state.tileBreakdown.data, ...action.payload] : action.payload;
      return { ...state, tileBreakdown: entityLoadingSucceeded(state.tileBreakdown, tilePayload) };
    case getType(actions.getTileBreakdown.failure):
      return { ...state, tileBreakdown: entityLoadingFailed(state.tileBreakdown) };

    case getType(actions.getTileGroupBreakdown.request):
      return { ...state, tileGroupBreakdown: entityLoadingStarted(state.tileGroupBreakdown, action.payload) };
    case getType(actions.getTileGroupBreakdown.success):
      //append existing data with new data
      let tileGroupPayload = state.tileGroupBreakdown.data ? [...state.tileGroupBreakdown.data, ...action.payload] : action.payload;
      return { ...state, tileGroupBreakdown: entityLoadingSucceeded(state.tileGroupBreakdown, tileGroupPayload) };
    case getType(actions.getTileGroupBreakdown.failure):
      return { ...state, tileGroupBreakdown: entityLoadingFailed(state.tileGroupBreakdown) };

    case getType(actions.getNftBreakdown.request):
      return { ...state, nftBreakdown: entityLoadingStarted(state.nftBreakdown, action.payload) };
    case getType(actions.getNftBreakdown.success):
      //append existing data with new data
      let nftPayload = state.nftBreakdown.data ? [...state.nftBreakdown.data, ...action.payload] : action.payload;
      return { ...state, nftBreakdown: entityLoadingSucceeded(state.nftBreakdown, nftPayload) };
    case getType(actions.getNftBreakdown.failure):
      return { ...state, nftBreakdown: entityLoadingFailed(state.nftBreakdown) };
    
    case getType(actions.getNftLikeBreakdown.request):
      return { ...state, nftLikeBreakdown: entityLoadingStarted(state.nftLikeBreakdown, action.payload) };
    case getType(actions.getNftLikeBreakdown.success):
      //append existing data with new data
      let nftLikePayload = state.nftLikeBreakdown.data ? [...state.nftLikeBreakdown.data, ...action.payload] : action.payload;
      return { ...state, nftLikeBreakdown: entityLoadingSucceeded(state.nftLikeBreakdown, nftLikePayload) };
    case getType(actions.getNftLikeBreakdown.failure):
      return { ...state, nftLikeBreakdown: entityLoadingFailed(state.nftLikeBreakdown) };

    case getType(actions.getAssetLikeBreakdown.request):
      return { ...state, assetLikeBreakdown: entityLoadingStarted(state.assetLikeBreakdown, action.payload) };
    case getType(actions.getAssetLikeBreakdown.success):
      //append existing data with new data
      let assetLikePayload = state.assetLikeBreakdown.data ? [...state.assetLikeBreakdown.data, ...action.payload] : action.payload;
      return { ...state, assetLikeBreakdown: entityLoadingSucceeded(state.assetLikeBreakdown, assetLikePayload) };
    case getType(actions.getAssetLikeBreakdown.failure):
      return { ...state, assetLikeBreakdown: entityLoadingFailed(state.assetLikeBreakdown) };

    case getType(actions.getTileLikeBreakdown.request):
      return { ...state, tileLikeBreakdown: entityLoadingStarted(state.tileLikeBreakdown, action.payload) };
    case getType(actions.getTileLikeBreakdown.success):
      //append existing data with new data
      let tileLikePayload = state.tileLikeBreakdown.data ? [...state.tileLikeBreakdown.data, ...action.payload] : action.payload;
      return { ...state, tileLikeBreakdown: entityLoadingSucceeded(state.tileLikeBreakdown, tileLikePayload) };
    case getType(actions.getTileLikeBreakdown.failure):
      return { ...state, tileLikeBreakdown: entityLoadingFailed(state.tileLikeBreakdown) };

    case getType(actions.getNftWishlistBreakdown.request):
      return { ...state, nftWishlistBreakdown: entityLoadingStarted(state.nftWishlistBreakdown, action.payload) };
    case getType(actions.getNftWishlistBreakdown.success):
      //append existing data with new data
      let nftWishlistPayload = state.nftWishlistBreakdown.data ? [...state.nftWishlistBreakdown.data, ...action.payload] : action.payload;
      return { ...state, nftWishlistBreakdown: entityLoadingSucceeded(state.nftWishlistBreakdown, nftWishlistPayload) };
    case getType(actions.getNftWishlistBreakdown.failure):
      return { ...state, nftWishlistBreakdown: entityLoadingFailed(state.nftWishlistBreakdown) };

    case getType(actions.getAssetWishlistBreakdown.request):
      return { ...state, assetWishlistBreakdown: entityLoadingStarted(state.assetWishlistBreakdown, action.payload) };
    case getType(actions.getAssetWishlistBreakdown.success):
      //append existing data with new data
      let assetWishlistPayload = state.assetWishlistBreakdown.data ? [...state.assetWishlistBreakdown.data, ...action.payload] : action.payload;
      return { ...state, assetWishlistBreakdown: entityLoadingSucceeded(state.assetWishlistBreakdown, assetWishlistPayload) };
    case getType(actions.getAssetWishlistBreakdown.failure):
      return { ...state, assetWishlistBreakdown: entityLoadingFailed(state.assetWishlistBreakdown) };

    case getType(actions.getTileWishlistBreakdown.request):
      return { ...state, tileWishlistBreakdown: entityLoadingStarted(state.tileWishlistBreakdown, action.payload) };
    case getType(actions.getTileWishlistBreakdown.success):
      //append existing data with new data
      let tileWishlistPayload = state.tileWishlistBreakdown.data ? [...state.tileWishlistBreakdown.data, ...action.payload] : action.payload;
      return { ...state, tileWishlistBreakdown: entityLoadingSucceeded(state.tileWishlistBreakdown, tileWishlistPayload) };
    case getType(actions.getTileWishlistBreakdown.failure):
      return { ...state, tileWishlistBreakdown: entityLoadingFailed(state.tileWishlistBreakdown) };

    case getType(actions.getAssetDetail.request):
      return { ...state, assetDetail: entityLoadingStarted(state.assetDetail, action.payload) };
    case getType(actions.getAssetDetail.success):
      return { ...state, assetDetail: entityLoadingSucceeded(state.assetDetail, action.payload) };
    case getType(actions.getAssetDetail.failure):
      return { ...state, assetDetail: entityLoadingFailed(state.assetDetail) };

    case getType(actions.getNftDetail.request):
      return { ...state, nftDetail: entityLoadingStarted(state.nftDetail, action.payload) };
    case getType(actions.getNftDetail.success):
      return { ...state, nftDetail: entityLoadingSucceeded(state.nftDetail, action.payload) };
    case getType(actions.getNftDetail.failure):
      return { ...state, nftDetail: entityLoadingFailed(state.nftDetail) };

    case getType(actions.getTileDetail.request):
      return { ...state, tileDetail: entityLoadingStarted(state.tileDetail, action.payload) };
    case getType(actions.getTileDetail.success):
      return { ...state, tileDetail: entityLoadingSucceeded(state.tileDetail, action.payload) };
    case getType(actions.getTileDetail.failure):
      return { ...state, tileDetail: entityLoadingFailed(state.tileDetail) };

    case getType(actions.getTileGroupDetail.request):
      return { ...state, tileGroupDetail: entityLoadingStarted(state.tileGroupDetail, action.payload) };
    case getType(actions.getTileGroupDetail.success):
      return { ...state, tileGroupDetail: entityLoadingSucceeded(state.tileGroupDetail, action.payload) };
    case getType(actions.getTileGroupDetail.failure):
      return { ...state, tileGroupDetail: entityLoadingFailed(state.tileGroupDetail) };
    
    case getType(actions.getNftShowcase.request):
      return { ...state, nftShowcase: entityLoadingStarted(state.nftShowcase, action.payload) };
    case getType(actions.getNftShowcase.success):
      return { ...state, nftShowcase: entityLoadingSucceeded(state.nftShowcase, action.payload) };
    case getType(actions.getNftShowcase.failure):
      return { ...state, nftShowcase: entityLoadingFailed(state.nftShowcase) };

    case getType(actions.clearAssets):
      return { ...state, assetBreakdown: initEntityState(null)};
    case getType(actions.clearNfts):
      return { ...state, nftBreakdown: initEntityState(null)};
    case getType(actions.clearNftLikes):
      return { ...state, nftLikeBreakdown: initEntityState(null)};
    case getType(actions.clearAssetLikes):
      return { ...state, assetLikeBreakdown: initEntityState(null)};
    case getType(actions.clearTileLikes):
      return { ...state, tileLikeBreakdown: initEntityState(null)};
    case getType(actions.clearNftWishlists):
      return { ...state, nftWishlistBreakdown: initEntityState(null)};
    case getType(actions.clearAssetWishlists):
      return { ...state, assetWishlistBreakdown: initEntityState(null)};
    case getType(actions.clearTileWishlists):
      return { ...state, tileWishlistBreakdown: initEntityState(null)};
    case getType(actions.clearTiles):
      return { ...state, tileBreakdown: initEntityState(null)};
    case getType(actions.clearTileGroups):
      return { ...state, tileGroupBreakdown: initEntityState(null)};

    case getType(actions.showLoadMoreAssets):
      return { ...state, showLoadMore: action.payload};  
    case getType(actions.showLoadMoreNFTs):
      return { ...state, showLoadMoreNft: action.payload};
    case getType(actions.showLoadMoreNFTLike):
      return { ...state, showLoadMoreNftLike: action.payload};
    case getType(actions.showLoadMoreAssetLike):
      return { ...state, showLoadMoreAssetLike: action.payload};
    case getType(actions.showLoadMoreTileLike):
      return { ...state, showLoadMoreTileLike: action.payload};
    case getType(actions.showLoadMoreNFTWishlist):
      return { ...state, showLoadMoreNftWishlist: action.payload};
    case getType(actions.showLoadMoreAssetWishlist):
      return { ...state, showLoadMoreAssetWishlist: action.payload};
    case getType(actions.showLoadMoreTileWishlist):
      return { ...state, showLoadMoreTileWishlist: action.payload};
    case getType(actions.showLoadMoreTiles):
      return { ...state, showLoadMoreTile: action.payload};
    case getType(actions.showLoadMoreTileGroups):
      return { ...state, showLoadMoreTileGroups: action.payload};
    case getType(actions.setNFTCount):
      return { ...state, nftCount: action.payload};
    case getType(actions.setNFTLikeCount):
      return { ...state, nftLikeCount: action.payload};
    case getType(actions.setAssetLikeCount):
      return { ...state, assetLikeCount: action.payload};
    case getType(actions.setTileLikeCount):
      return { ...state, tileLikeCount: action.payload};
    case getType(actions.setNFTWishlistCount):
      return { ...state, nftWishlistCount: action.payload};
    case getType(actions.setAssetWishlistCount):
      return { ...state, assetWishlistCount: action.payload};
    case getType(actions.setTileWishlistCount):
      return { ...state, tileWishlistCount: action.payload};
    case getType(actions.setAssetCount):
      return { ...state, assetCount: action.payload};
    case getType(actions.setTileCount):
      return { ...state, tileCount: action.payload};
    case getType(actions.setTileGroupCount):
      return { ...state, tileGroupCount: action.payload};
    case getType(actions.setBalance):
      return { ...state, balance: action.payload};
    case getType(actions.setContinent):
      return { ...state, continent: action.payload};
  
    default:
      return state;
  }
};

export default states;

import axios from "axios";

export const uploadVideo = async (data) => {
    const result = await axios.post(`https://api.infomaniak.com/1/vod/channel/${process.env.REACT_APP_MEDIA_CHANNEL}/upload`, data, { headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${process.env.REACT_APP_MEDIA_TOKEN}`
      }});
    return result;
}

export const getMediaList = async (media) => {
  const result = await axios.get(`https://api.infomaniak.com/1/vod/channel/${process.env.REACT_APP_MEDIA_CHANNEL}/media/${media}`, { headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${process.env.REACT_APP_MEDIA_TOKEN}`
    }});
  return result;
}

export const createShare = async (data) => {
  const result = await axios.post(`https://api.infomaniak.com/1/vod/channel/${process.env.REACT_APP_MEDIA_CHANNEL}/share`, data, { headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${process.env.REACT_APP_MEDIA_TOKEN}`
    }});
  return result;
}
import React from 'react';
import miniMapImg from '../../assets/images/mini-map.png';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

const MINIMAP_WIDTH = 222;

function ZoomModule({coordX, coordY, mapWidth, setZoomLevel, zoomLevel, setCoordX, setCoordY}) {
  return (
    <div style={{position: 'relative'}}>
        <div style={{
            background: 'white',
            position: 'fixed',
            zIndex: 20,
            bottom: 10,
            left: 10,
            padding: 20,
            borderRadius: 15,
            boxShadow: '0px 4px 40px rgba(1, 4, 24, 0.1)',
            overflow: 'hidden'
        }}>
            <img src={miniMapImg} alt="" style={{width: MINIMAP_WIDTH}}/>
            <div style={{
                    position: 'absolute',
                    background: 'transparent',
                    border: '1px solid black',
                    top: MINIMAP_WIDTH / mapWidth * (-coordY) + 20,
                    left: MINIMAP_WIDTH / mapWidth * (-coordX) + 20,
                    width: MINIMAP_WIDTH / mapWidth * window.innerWidth,
                    height: MINIMAP_WIDTH / mapWidth * window.innerHeight
                }}>
            </div>
        </div>
        <AddIcon
            onClick={() => {
                if(zoomLevel < 7) {
                    setZoomLevel(zoomLevel + 1);
                    setCoordX(coordX * 2 - window.innerWidth / 2);
                    setCoordY(coordY * 2 - window.innerHeight / 2);    
                }
            }}
            style={{background: 'white', borderRadius: 5, border: 'solid 1px #AAAAAA', position: 'absolute', top: -80, left: 280, zIndex: 20, cursor: 'pointer'}}
        />
        <RemoveIcon
            onClick={() => {
                if(zoomLevel > 0) {
                    setZoomLevel(zoomLevel - 1);
                    setCoordX(coordX / 2 + (window.innerWidth / 4));
                    setCoordY(coordY / 2 + (window.innerHeight / 4));    
                }
            }}
            style={{background: 'white', borderRadius: 5, border: 'solid 1px #AAAAAA', position: 'absolute', top: -50, left: 280, zIndex: 20, cursor: 'pointer'}}
        />
    </div>
  );
}

export default ZoomModule;

import React, { memo, useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from 'react-redux';
import Footer from '../../components/footer';
import { Form, Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import api from "../../../../core/api";
import { fetchCurrentAuthor } from "../../../../store/actions/thunks";
import * as selectors from '../../../../store/selectors';
import * as actions from '../../../../store/actions/thunks';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTranslation } from "react-i18next";
import { ReactNotifications } from 'react-notifications-component';
import { useHistory } from 'react-router-dom';
import 'react-notifications-component/dist/theme.css';

import * as AuthService from "../../../../services/AuthService";
import Notification from "../../../../utils/functions/notification";
import { EPATHS } from "../../../../routes/paths.enum";
import Header from "../../menu/header";

//SWITCH VARIABLE FOR PAGE STYLE

const Profile = () => {
    const {t}=useTranslation();
    const history = useHistory();

    const currentAuthorState = useSelector(selectors.currentAuthorState);
    const author = currentAuthorState.data ? currentAuthorState.data : null;
    const dispatch = useDispatch();
    const [profileImage, setProfileImage] = useState();
    const [profileImageTemp, setProfileImageTemp] = useState();
    const [profileBanner, setProfileBanner] = useState();
    const [profileBannerTemp, setProfileBannerTemp] = useState();
    const [openLoader, setOpenLoader] = useState(false);
    const signedUser = useSelector(selectors.user);
    const [isNewsLetterChecked, setIsNewsLetterChecked] = useState(signedUser.newsletter);
    const [isUpdate, setUpdate] = useState(false);
    const [initialValues, setInitialValues] = useState({
        username: '',
        email: '',
        firstname: '',
        lastname: ''
    })
    const inputPictureRef = useRef(null);
    const inputBannerRef = useRef(null);

    useEffect(() => {
        const account = signedUser.metamask;
        if(account) {
            dispatch(fetchCurrentAuthor(account));
        }
    }, [dispatch, signedUser.metamask]);

    useEffect(() => {
        if(author) {
            setInitialValues({
                username: author.username,
                email: author.email,
                firstname: author.firstName,
                lastname: author.lastName
            });
            setUpdate(!isUpdate);
        }
        // eslint-disable-next-line
    }, [author])

    useEffect(() => {
        setIsNewsLetterChecked(signedUser.newsletter);
    }, [signedUser.newsletter])

    const validationSchema = Yup.object().shape({
        username: Yup.lazy(() =>
          Yup.string()
            .required(t("RequiredUsername"))
        ),
        email: Yup.lazy(() =>
          Yup.string()
            .required(t("RequiredEmail"))
        ),
    });

    const initialProfilePicture = {
        profile_image: ''
    }
    
    const initialProfileBanner = {
        profile_banner: ''
    }

    const handleSubmitForm = async (data) => {
        setOpenLoader(true);
        try {
            if(signedUser.metamask){
                const metadata = {
                    username: data.username,
                    firstName: data.firstname,
                    lastName: data.lastname,
                    newsletter: isNewsLetterChecked,
                }

                const user = await AuthService.updateUser(author.id, metadata);
                setInitialValues({
                    username: user.username,
                    email: user.email,
                    firstname: user.firstName,
                    lastname: user.lastName,
                });
                localStorage.setItem('_user', JSON.stringify({...user, accessToken: signedUser.accessToken}));
                dispatch(actions.setUser({...user, accessToken: signedUser.accessToken}));
                
                if(user.isVerified === false) {
                    history.push({pathname:EPATHS.HOME})
                }
                setUpdate(!isUpdate);
                // history.push({pathname:EPATHS.MY_PROFILE})
                setOpenLoader(false);
                Notification(`Your information have been changed.`, true);
            }
        } catch (error) {
            setOpenLoader(false);
            Notification(`${error.message}`, false);
        }
    }
    
    const handleSubmitProfilePicture = async () => {
        try {
            setOpenLoader(true);
            const formData = new FormData();
            formData.append("picture", profileImage);
            await AuthService.updateUser(author.id, formData);
            const result = await AuthService.fetchCurrentUser(signedUser.metamask);
            localStorage.setItem('_user', JSON.stringify({...result, accessToken: signedUser.accessToken}));
            dispatch(actions.setUser({...result, accessToken: signedUser.accessToken}));
            setOpenLoader(false);
            setProfileImageTemp(null);
            setProfileImage(null);
            Notification(`Your image have been changed.`, true);
        } catch (error) {
            setOpenLoader(false);
            Notification(`${error}`, false);
        }
    }

    const handleSubmitProfileBanner = async () => {
        try {
            setOpenLoader(true);
            const formData = new FormData();
            formData.append("banner", profileBanner);
            await AuthService.updateUser(author.id, formData);
            const result = await AuthService.fetchCurrentUser(signedUser.metamask);
            localStorage.setItem('_user', JSON.stringify({...result, accessToken: signedUser.accessToken}));
            dispatch(actions.setUser({...result, accessToken: signedUser.accessToken}));
            setOpenLoader(false);
            setProfileBanner(null);
            setProfileBannerTemp(null);
            Notification(`Your banner have been changed.`, true);
        } catch (error) {
            setOpenLoader(false);
            Notification(`${error}`, false);
        }
    }

    const handleProfilePicture =async (event) => {
        let file = event.target.files[0];
        setProfileImage(file)
        let reader = new FileReader();
        reader.onloadend = () => {
            setProfileImageTemp(reader.result)
        };
        reader.readAsDataURL(file);
    }
    
    const handleProfileBanner = async(event) => {
        let file = event.target.files[0];
        setProfileBanner(file)
        let reader = new FileReader();
        reader.onloadend = () => {
            setProfileBannerTemp(reader.result)
        };
        reader.readAsDataURL(file);
    }

    const handleChangeNewsLetter=()=>{
        setIsNewsLetterChecked(isNewsLetterChecked ? false : true);
    }
    
    return  (
        <div>
        <Backdrop style={{zIndex:9999}} open={openLoader}>
            <CircularProgress color="inherit" />
        </Backdrop>
            {/* <GlobalStyles/> */}
            <Header className=""/>
            <ReactNotifications />
            <section id='profile_banner' className='jumbotron breadcumb no-bg' style={{backgroundImage: signedUser.id ? `url(${signedUser.banner?`${api.baseUrl}/${encodeURIComponent(signedUser.banner)}`:'../../img/background/subheader.jpg'})` : '', backgroundSize: 'cover'}}>
                <div className='mainbreadcumb'>
                </div>
            </section>
            
            <section id="section-main" aria-label="section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 offset-lg-1 d-flex">
                        <Formik
                            enableReinitialize
                            validationSchema={validationSchema}
                            initialValues={initialValues}
                            validateOnMount={validationSchema.isValidSync(initialValues)}
                            onSubmit={async (values, { setSubmitting, resetForm }) => {
                            setSubmitting(true);
                            await handleSubmitForm(values);
                            setSubmitting(false);
                            resetForm();
                            }}
                        >
                            { 
                                ({ values, isSubmitting, isValid }) => {

                                    return (
                                        <Form className="form-border w-100">
                                            <div className="de_tab tab_simple">
                                            
                                                <div className="mb-4" style={{opacity: 1}} onClick={() => history.push({pathname: EPATHS.MY_PROFILE})}><span className="btn-main"><i className="fa fa-user"></i> {t("Visit My Profile")}</span></div>
                                            
                                            <div className="de_tab_content">                            
                                                <div className="tab-1">
                                                    <div className="row wow fadeIn animated" style={{backgroundSize: 'cover', visibility: 'visible', animationName: 'fadeIn'}}>
                                                        <div className="col-lg-8 mb-sm-20">
                                                            <div className="field-set">
                                                                <h5>{t("Username")}</h5>
                                                                <Field type="text" name="username" id="username" className="form-control" placeholder={t("EnterUsername")}/>

                                                                <h5>{t("Email")}</h5>
                                                                <Field type="text" disabled name="email" id="email" className="form-control" placeholder={t("EnterEmail")}/>
                                                                <p style={{color: '#a706aa', marginTop: -10, marginBottom: 8, cursor: 'pointer', textAlign: 'right', marginRight: 20, fontSize: 13, fontWeight: 600}} onClick={() => {history.push({pathname:EPATHS.CHANGE_EMAIL})}}>Change Email</p>

                                                                <h5>{t("FirstName")}</h5>
                                                                <Field type="text" name="firstname" id="firstname" className="form-control" placeholder={t("EnterFirstName")}/>

                                                                <h5>{t("LastName")}</h5>
                                                                <Field type="text" name="lastname" id="lastname" className="form-control" placeholder={t("EnterLastName")}/>

                                                                <div>
                                                                    <input
                                                                    className='form-check-input'
                                                                    type='checkbox'
                                                                    id='flexCheckDefault'
                                                                    checked={isNewsLetterChecked}
                                                                    onChange={handleChangeNewsLetter}
                                                                    />
                                                                    <label
                                                                    className='form-check-label-profile'
                                                                    htmlFor='flexCheckDefault'
                                                                    >
                                                                        {t("Newsletter")}
                                                                    </label>
                                                                    <div>{"I accept to subscribe to the Earniverse newsletter and receive the latest news from Earniverse and Earninews."}</div>
                                                                    <div className="spacer-20"></div>
                                                                </div>
                                                                {/* 
                                                                <h5>About</h5>
                                                                <Field component="textarea" name="about" id="about" className="form-control" placeholder="Tell the world who you are!"/>
                                                                <ErrorMessage name="about" component="div" />
                                                                <div className="spacer-20"></div> */}

                                                                {/* <h5>Social</h5>
                                                                <Field type="text" name="social" id="social" className="form-control" placeholder="Enter Social URLs like Instagram or Twitter"/>
                                                                <ErrorMessage name="social" component="div" />
                                                                <div className="spacer-20"></div> */}

                                                                {/* <h5>Wallet</h5>
                                                                <Field type="text" name="wallet" id="wallet" className="form-control" placeholder="Enter your Wallet Address"/>
                                                                <ErrorMessage name="wallet" component="div" />
                                                                <div className="spacer-20"></div> */}
                                                            </div>
                                                        </div>                                         
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <input type="submit" id="submit" className="btn-main" value={t("UpdateProfile")}/>
                                        </Form>
                                    )
                                }
                            }
                            </Formik>
                            {/* different form for image and banner */}
                            <div id="sidebar" className="col-lg-4">
                            <Formik
                                initialValues={initialProfilePicture}
                                onSubmit={async (values, { setSubmitting, resetForm }) => {
                                setSubmitting(true);
                                await handleSubmitProfilePicture(profileImage, 'avatar');
                                setSubmitting(false);
                                resetForm();
                                }}
                            >
                                { 
                                ({ values, isSubmitting, isValid }) => {

                                    return (
                                        <Form>
                                            <h5>{t("ProfileImage")} <i className="fa fa-info-circle id-color-2" data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title="Recommend 400 x 400. Max size: 50MB. Click the image to upload." aria-label="Recommend 400 x 400. Max size: 50MB. Click the image to upload."></i></h5>
                                            <img 
                                                src={ profileImageTemp ? profileImageTemp : signedUser.id ? (signedUser.picture ? `${api.baseUrl}/${signedUser.picture}` : "../../img/author/author-11.png") : ''}
                                                id="click_profile_img" 
                                                className="d-profile-img-edit img-fluid" 
                                                alt=""
                                                style={{width: '150px', height: '150px', objectFit: 'cover'}}
                                            />
                                            <div  style={{display:"flex",alignItems:"center"}}>
                                            <label htmlFor="upload_profile_img" className="btn-upload mt-3">{t("SelectProfilePicture")}</label>
                                            <input ref={inputPictureRef} name="profile_image" style={{display:"none"}} type="file" id="upload_profile_img" onChange={(event) => {
                                                handleProfilePicture(event)
                                            }} />
                                             <div style={{marginLeft:5,paddingTop:15}}>{profileImage?profileImage.name:t("NoFileSelected")}</div>
                                            </div>
                                            <p>Please upload only 1:1 Ratio Image.</p>
                                            <input type="submit" className="btn-main mt-3" value={t("SaveProfileImage")} disabled={!profileImageTemp} style={{width: 250}}/>
                                            {(profileImageTemp || signedUser?.picture) && <button
                                                className='btn-main lead mb-5 mr15'
                                                style={{width: 250, marginTop: 10}}
                                                onClick={() => { setProfileImage(null); setProfileImageTemp(null); inputPictureRef.current.value = null; }}
                                            >
                                                {t("Remove Image")}
                                            </button>}
                                        </Form>
                                    )
                                }}
                            </Formik>
                                <div className="spacer-30"></div>
                            <Formik
                                initialValues={initialProfileBanner}
                                onSubmit={async (values, { setSubmitting, resetForm }) => {
                                setSubmitting(true);
                                await handleSubmitProfileBanner(profileBanner, 'banner');
                                setSubmitting(false);
                                resetForm();
                                }}
                            >
                                { 
                                ({ values, isSubmitting, isValid }) => {

                                    return (
                                        <Form>
                                            <h5>{t("ProfileBanner")} <i className="fa fa-info-circle id-color-2" data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title="Recommend 1500 x 500. Max size: 50MB. Click the image to upload." aria-label="Recommend 1500 x 500. Max size: 50MB. Click the image to upload."></i></h5>
                                            <img 
                                                src={profileBannerTemp?profileBannerTemp: signedUser.banner ? `${api.baseUrl}/${signedUser.banner}` : ''}
                                                id="click_banner_img" 
                                                className="d-banner-img-edit img-fluid" 
                                                alt=""
                                            />
                                            <div style={{display:"flex",alignItems:"center"}}>
                                            <label htmlFor="upload_banner_img" className="btn-upload mt-3">{t("ProfileBanner")}</label>
                                                <input ref={inputBannerRef} name="profile_banner" style={{display:"none"}} type="file" id="upload_banner_img" onChange={(event) => {
                                                    handleProfileBanner(event)
                                                }} />
                                                <div style={{marginLeft:5,paddingTop:15}}>{profileBanner?profileBanner.name:t("NoFileSelected")}</div>
                                            </div>
                                            <ErrorMessage name="profile_banner" component="div" />
                                            <p>Please upload only 4:1 Ratio Image.</p>
                                            <input type="submit" className="btn-main mt-3" value={t("SaveProfileBanner")} disabled={!profileBannerTemp} style={{width: 250}}/>
                                            {(profileBannerTemp || signedUser?.banner) && <button
                                                className='btn-main lead mb-5 mr15'
                                                style={{width: 250, marginTop: 10}}
                                                onClick={() => { setProfileBanner(null); setProfileBannerTemp(null); inputBannerRef.current.value = null; }}
                                            >
                                                {t("Remove Banner")}
                                            </button>}
                                        </Form>
                                    )
                                }}
                            </Formik>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default memo(Profile);
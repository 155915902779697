import {
    AmbientLight,
    AnimationMixer,
    AxesHelper,
    Box3,
    Cache,
    DirectionalLight,
    GridHelper,
    HemisphereLight,
    LinearEncoding,
    LoaderUtils,
    LoadingManager,
    PMREMGenerator,
    PerspectiveCamera,
    REVISION,
    Scene,
    SkeletonHelper,
    Vector3,
    WebGLRenderer,
    sRGBEncoding,
    LinearToneMapping,
  } from 'three';
  import Stats from 'three/examples/jsm/libs/stats.module.js';
  import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
  import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
  import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
  import { RoomEnvironment } from 'three/examples/jsm/environments/RoomEnvironment.js';

  
  import { environments } from './environment/index.js';
  
  const DEFAULT_CAMERA = '[default]';
      
  const Preset = {ASSET_GENERATOR: 'assetgenerator'};
  
  Cache.enabled = true;
  
  export class Viewer {
  
    constructor (el, options, clientWidth, clientHeight, ratio) {
      this.el = el;
      this.options = options;
  
      this.lights = [];
      this.content = null;
      this.mixer = null;
      this.clips = [];
      this.gui = null;
      this.ratio = ratio;
  
      this.state = {
        environment: options.preset === Preset.ASSET_GENERATOR
          ? environments.find((e) => e.id === 'footprint-court').name
          : environments[1].name,
        background: false,
        playbackSpeed: 1.0,
        actionStates: {},
        camera: DEFAULT_CAMERA,
        wireframe: false,
        skeleton: false,
        grid: false,
  
        // Lights
        punctualLights: true,
        exposure: 0.0,
        toneMapping: LinearToneMapping,
        textureEncoding: 'sRGB',
        ambientIntensity: 0.3,
        ambientColor: 0xFFFFFF,
        directIntensity: 0.8 * Math.PI, // TODO(#116)
        directColor: 0xFFFFFF,
        bgColor1: '#ffffff',
        bgColor2: '#353535'
      };
  
      this.prevTime = 0;
  
      this.stats = new Stats();
      this.stats.dom.height = '48px';
      [].forEach.call(this.stats.dom.children, (child) => (child.style.display = ''));
  
      this.scene = new Scene();
  
      const fov = options.preset === Preset.ASSET_GENERATOR
        ? 0.8 * 180 / Math.PI
        : 60;
      this.defaultCamera = new PerspectiveCamera( fov, clientWidth ? clientWidth : this.el.parentElement.clientWidth / clientHeight, 0.01, 1000 );
      this.activeCamera = this.defaultCamera;
      this.scene.add( this.defaultCamera );
  
      this.renderer = window.renderer = new WebGLRenderer({antialias: true});
      this.renderer.physicallyCorrectLights = true;
      this.renderer.outputEncoding = sRGBEncoding;
      this.renderer.setClearColor( 0xffffff );
      this.renderer.setPixelRatio( window.devicePixelRatio );
      this.renderer.setSize( clientWidth ? clientWidth : this.el.parentElement.clientWidth, clientHeight );
  
      this.pmremGenerator = new PMREMGenerator( this.renderer );
      this.pmremGenerator.compileEquirectangularShader();
  
      this.neutralEnvironment = this.pmremGenerator.fromScene( new RoomEnvironment() ).texture;
  
      this.controls = new OrbitControls( this.defaultCamera, this.renderer.domElement );
      this.controls.autoRotate = false;
      this.controls.autoRotateSpeed = -10;
      this.controls.screenSpacePanning = true;
  
      this.el.appendChild(this.renderer.domElement);
  
      this.cameraCtrl = null;
      this.cameraFolder = null;
      this.animFolder = null;
      this.animCtrls = [];
      this.morphFolder = null;
      this.morphCtrls = [];
      this.skeletonHelpers = [];
      this.gridHelper = null;
      this.axesHelper = null;
  
      this.addAxesHelper();
      if (options.kiosk) this.gui.close();
  
      this.animate = this.animate.bind(this);
      requestAnimationFrame( this.animate );
      window.addEventListener('resize', this.resize.bind(this), false);
      this.renderer.domElement.style.display = "block";
    }
  
    animate (time) {
  
      requestAnimationFrame( this.animate );
  
      const dt = (time - this.prevTime) / 1000;
  
      this.controls.update();
      this.stats.update();
      this.mixer && this.mixer.update(dt);
      this.render();
  
      this.prevTime = time;
  
    }
  
    render () {
  
      this.renderer.render( this.scene, this.activeCamera );
      if (this.state.grid) {
        this.axesCamera.position.copy(this.defaultCamera.position)
        this.axesCamera.lookAt(this.axesScene.position)
        this.axesRenderer.render( this.axesScene, this.axesCamera );
      }
    }
  
    resize () {
  
      const {clientHeight, clientWidth} = this.el.parentElement;
  
      this.defaultCamera.aspect = clientWidth / clientHeight;
      this.defaultCamera.updateProjectionMatrix();
      this.renderer.setSize(clientWidth, clientHeight);
  
      this.axesCamera.aspect = this.axesDiv.clientWidth / this.axesDiv.clientHeight;
      this.axesCamera.updateProjectionMatrix();
      this.axesRenderer.setSize(this.axesDiv.clientWidth, this.axesDiv.clientHeight);
    }
  
    load ( url ) {
  
      const baseURL = LoaderUtils.extractUrlBase(url);
  
      // Load.
      return new Promise((resolve, reject) => {
  
        // Intercept and override relative URLs.
        // MANAGER.setURLModifier((url, path) => {
  
        //   // URIs in a glTF file may be escaped, or not. Assume that assetMap is
        //   // from an un-escaped source, and decode all URIs before lookups.
        //   // See: https://github.com/donmccurdy/three-gltf-viewer/issues/146
        //   // const normalizedURL = rootPath + decodeURI(url)
        //   //   .replace(baseURL, '')
        //   //   .replace(/^(\.?\/)/, '');
  
        //   // if (assetMap.has(normalizedURL)) {
        //   //   const blob = assetMap.get(normalizedURL);
        //   //   const blobURL = URL.createObjectURL(blob);
        //   //   blobURLs.push(blobURL);
        //   //   return blobURL;
        //   // }
  
        //   return (path || '') + url;
  
        // });
  
        const loader = new GLTFLoader()
          .setCrossOrigin('anonymous')
  
        const blobURLs = [];
  
        loader.load(url, (gltf) => {
  
          window.VIEWER.json = gltf;
  
          const scene = gltf.scene || gltf.scenes[0];
          const clips = gltf.animations || [];
  
          if (!scene) {
            // Valid, but not supported by this viewer.
            throw new Error(
              'This model contains no scene, and cannot be viewed here. However,'
              + ' it may contain individual 3D resources.'
            );
          }
  
          this.setContent(scene, clips);
  
          blobURLs.forEach(URL.revokeObjectURL);
  
          // See: https://github.com/google/draco/issues/349
          // DRACOLoader.releaseDecoderModule();
  
          resolve(gltf);
  
        }, undefined, reject);
  
      });
  
    }
  
    /**
     * @param {THREE.Object3D} object
     * @param {Array<THREE.AnimationClip} clips
     */
    setContent ( object, clips ) {
  
      this.clear();
  
      const box = new Box3().setFromObject(object);
      const size = this.ratio ? box.getSize(new Vector3()).length()*this.ratio : box.getSize(new Vector3()).length()*0.5;
      const center = box.getCenter(new Vector3());
  
      this.controls.reset();
  
      object.position.x += (object.position.x - center.x);
      object.position.y += (object.position.y - center.y);
      object.position.z += (object.position.z - center.z);
      this.controls.maxDistance = size * 10;
      this.defaultCamera.near = size / 100;
      this.defaultCamera.far = size * 100;
      this.defaultCamera.updateProjectionMatrix();
  
      if (this.options.cameraPosition) {
  
        this.defaultCamera.position.fromArray( this.options.cameraPosition );
        this.defaultCamera.lookAt( new Vector3() );
  
      } else {
  
        this.defaultCamera.position.copy(center);
        this.defaultCamera.position.x += size / 2.0;
        this.defaultCamera.position.y += size / 5.0;
        this.defaultCamera.position.z += size / 2.0;
        this.defaultCamera.lookAt(center);
  
      }
  
      this.setCamera(DEFAULT_CAMERA);
  
      this.axesCamera.position.copy(this.defaultCamera.position)
      this.axesCamera.lookAt(this.axesScene.position)
      this.axesCamera.near = size / 100;
      this.axesCamera.far = size * 100;
      this.axesCamera.updateProjectionMatrix();
      this.axesCorner.scale.set(size, size, size);
  
      this.controls.saveState();
  
      this.scene.add(object);
      this.content = object;
  
      this.state.punctualLights = true;
  
      this.content.traverse((node) => {
        if (node.isLight) {
          this.state.punctualLights = false;
        } else if (node.isMesh) {
          // TODO(https://github.com/mrdoob/three.js/pull/18235): Clean up.
          node.material.depthWrite = !node.material.transparent;
        }
      });
  
      this.setClips(clips);
  
      this.updateLights();
      this.updateEnvironment();
      this.updateTextureEncoding();
      this.updateDisplay();
  
      window.VIEWER.scene = this.content;
  
      // this.printGraph(this.content);
    }
  
    printGraph (node) {
  
      console.group(' <' + node.type + '> ' + node.name);
      node.children.forEach((child) => this.printGraph(child));
      console.groupEnd();
  
    }
  
    /**
     * @param {Array<THREE.AnimationClip} clips
     */
    setClips ( clips ) {
      if (this.mixer) {
        this.mixer.stopAllAction();
        this.mixer.uncacheRoot(this.mixer.getRoot());
        this.mixer = null;
      }
  
      this.clips = clips;
      if (!clips.length) return;
  
      this.mixer = new AnimationMixer( this.content );
    }
  
    playAllClips () {
      this.clips.forEach((clip) => {
        this.mixer.clipAction(clip).reset().play();
        this.state.actionStates[clip.name] = true;
      });
    }
  
    /**
     * @param {string} name
     */
    setCamera ( name ) {
      if (name === DEFAULT_CAMERA) {
        this.controls.enabled = true;
        this.activeCamera = this.defaultCamera;
      } else {
        this.controls.enabled = false;
        this.content.traverse((node) => {
          if (node.isCamera && node.name === name) {
            this.activeCamera = node;
          }
        });
      }
    }
  
    updateTextureEncoding () {
      const encoding = this.state.textureEncoding === 'sRGB'
        ? sRGBEncoding
        : LinearEncoding;
      traverseMaterials(this.content, (material) => {
        if (material.map) material.map.encoding = encoding;
        if (material.emissiveMap) material.emissiveMap.encoding = encoding;
        if (material.map || material.emissiveMap) material.needsUpdate = true;
      });
    }
  
    updateLights () {
      const state = this.state;
      const lights = this.lights;
  
      if (state.punctualLights && !lights.length) {
        this.addLights();
      } else if (!state.punctualLights && lights.length) {
        this.removeLights();
      }
  
      this.renderer.toneMapping = Number(state.toneMapping);
      this.renderer.toneMappingExposure = Math.pow(2, state.exposure);
  
      if (lights.length === 2) {
        lights[0].intensity = state.ambientIntensity;
        lights[0].color.setHex(state.ambientColor);
        lights[1].intensity = state.directIntensity;
        lights[1].color.setHex(state.directColor);
      }
    }
  
    addLights () {
      const state = this.state;
  
      if (this.options.preset === Preset.ASSET_GENERATOR) {
        const hemiLight = new HemisphereLight();
        hemiLight.name = 'hemi_light';
        this.scene.add(hemiLight);
        this.lights.push(hemiLight);
        return;
      }
  
      const light1  = new AmbientLight(state.ambientColor, state.ambientIntensity);
      light1.name = 'ambient_light';
      this.defaultCamera.add( light1 );
  
      const light2  = new DirectionalLight(state.directColor, state.directIntensity);
      light2.position.set(0.5, 0, 0.866); // ~60º
      light2.name = 'main_light';
      this.defaultCamera.add( light2 );
  
      this.lights.push(light1, light2);
    }
  
    removeLights () {
  
      this.lights.forEach((light) => light.parent.remove(light));
      this.lights.length = 0;
  
    }
  
    updateEnvironment () {
  
      const environment = environments.filter((entry) => entry.name === this.state.environment)[0];
  
      this.getCubeMapTexture( environment ).then(( { envMap } ) => {
  
 
        this.scene.environment = envMap;
        this.scene.background = this.state.background ? envMap : null;
  
      });
  
    }
  
    getCubeMapTexture ( environment ) {
      const { id, path } = environment;
  
      // neutral (THREE.RoomEnvironment)
      if ( id === 'neutral' ) {
  
        return Promise.resolve( { envMap: this.neutralEnvironment } );
  
      }
  
      // none
      if ( id === '' ) {
  
        return Promise.resolve( { envMap: null } );
  
      }
  
      return new Promise( ( resolve, reject ) => {
  
        new RGBELoader()
          .load( path, ( texture ) => {
  
            const envMap = this.pmremGenerator.fromEquirectangular( texture ).texture;
            this.pmremGenerator.dispose();
  
            resolve( { envMap } );
  
          }, undefined, reject );
  
      });
  
    }
  
    updateDisplay () {
      if (this.skeletonHelpers.length) {
        this.skeletonHelpers.forEach((helper) => this.scene.remove(helper));
      }
  
      traverseMaterials(this.content, (material) => {
        material.wireframe = this.state.wireframe;
      });
  
      this.content.traverse((node) => {
        if (node.isMesh && node.skeleton && this.state.skeleton) {
          const helper = new SkeletonHelper(node.skeleton.bones[0].parent);
          helper.material.linewidth = 3;
          this.scene.add(helper);
          this.skeletonHelpers.push(helper);
        }
      });
  
      if (this.state.grid !== Boolean(this.gridHelper)) {
        if (this.state.grid) {
          this.gridHelper = new GridHelper();
          this.axesHelper = new AxesHelper();
          this.axesHelper.renderOrder = 999;
          this.axesHelper.onBeforeRender = (renderer) => renderer.clearDepth();
          this.scene.add(this.gridHelper);
          this.scene.add(this.axesHelper);
        } else {
          this.scene.remove(this.gridHelper);
          this.scene.remove(this.axesHelper);
          this.gridHelper = null;
          this.axesHelper = null;
          this.axesRenderer.clear();
        }
      }
    }
  
    updateBackground () {
    }
  
    /**
     * Adds AxesHelper.
     *
     * See: https://stackoverflow.com/q/16226693/1314762
     */
    addAxesHelper () {
      this.axesDiv = document.createElement('div');
      this.el.appendChild( this.axesDiv );
      this.axesDiv.classList.add('axes');
  
      const {clientWidth, clientHeight} = this.axesDiv;
  
      this.axesScene = new Scene();
      this.axesCamera = new PerspectiveCamera( 50, clientWidth / clientHeight, 0.1, 10 );
      this.axesScene.add( this.axesCamera );
  
      this.axesRenderer = new WebGLRenderer( { alpha: true } );
      this.axesRenderer.setPixelRatio( window.devicePixelRatio );
      this.axesRenderer.setSize( this.axesDiv.clientWidth, this.axesDiv.clientHeight );
  
      this.axesCamera.up = this.defaultCamera.up;
  
      this.axesCorner = new AxesHelper(5);
      this.axesScene.add( this.axesCorner );
      this.axesDiv.appendChild(this.axesRenderer.domElement);
    }
  
    clear () {
  
      if ( !this.content ) return;
      this.scene.remove( this.content );
      this.renderer.domElement.style.display = "none";
  
      // dispose geometry
      this.content.traverse((node) => {
        
        if ( !node.isMesh ) return;
  
        node.geometry.dispose();
  
      } );
  
      // dispose textures
      traverseMaterials( this.content, (material) => {
  
        for ( const key in material ) {
          // if ( key !== 'envMap' && material[ key ] && material[ key ].isTexture ) {
          if ( material[ key ] && material[ key ].isTexture ) {
  
            material[ key ].dispose();
  
          }
  
        }
  
      } );
  
    }
  
  };
  
  function traverseMaterials (object, callback) {
    object.traverse((node) => {
      if (!node.isMesh) return;
      const materials = Array.isArray(node.material)
        ? node.material
        : [node.material];
      materials.forEach(callback);
    });
  }
  
  // https://stackoverflow.com/a/9039885/1314762
  function isIOS() {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
  }
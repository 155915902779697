import React from 'react';
import { MAP_SEA_COLOR,
  MAP_LAND_COLOR,
  MAP_LAND_LABEL,
  MAP_SEA_LABEL,
  MAP_MY_LAND_COLOR,
  MAP_OVERLAY_COLOR,
  MAP_STAR_COLOR,
  TIER1,
  TIER2,
  TIER3,
  TIER4,
  TIER5,
  MAP_FOR_SALE_COLOR,
  MAP_INFRA_COLOR,
  LAND_PACK
} from './Const';
import { useSelector } from 'react-redux';
import * as selectors from '../../store/selectors';

function LandItem({size, data, x, y, activeParcel, setActiveParcel}) {
  const signedUser = useSelector(selectors.user);

  return (
    <div 
      className={`${data === null
        ? null
        : (data?.TileGroup 
          ? (data?.TileGroup?.Owner?.id ===  signedUser?.id && signedUser?.id !== undefined
            ? null
            : null
          )
          : (data?.ownerId === signedUser?.id && signedUser.id !== undefined
            ? null
            :(data.isInfrastructure
              ? null
              : (data.isForSale 
                ? (data?.User?.metamask === process.env.REACT_APP_COMPANY_WALLET_ADDRESS.toLowerCase() 
                  ? (data?.vvip ? null : (data?.tierId === 1 ? null : (data?.tierId === 2 ? null : (data?.tierId === 3 ? null : (data?.tierId === 4 ? null : (data?.tierId === 5 ? null : null)))))) 
                  : (null)
                  )
                : (data?.User?.metamask === process.env.REACT_APP_COMPANY_WALLET_ADDRESS.toLowerCase()
                  ? (data.tileType === MAP_SEA_LABEL ? null : null)
                  : ('crossed')
                  )
                )
              )
            )
          )}`}
      style={{
        width: size,
        height: size,
        border: 
          data && (data?.id === activeParcel?.id || (data?.tileGroupId === activeParcel?.tileGroupId && data?.tileGroupId)) 
            ? (data?.TileGroup?.Owner?.id ===  signedUser?.id && signedUser?.id !== undefined ? 'solid 2px #A706AA' : 'solid 2px #A706AA') 
            : 'solid 1px rgba(255, 255, 255, 0.8)',
        backgroundColor: data === null
          ? 'white'
          : (data?.TileGroup 
            ? (data?.TileGroup?.Owner?.id ===  signedUser?.id && signedUser?.id !== undefined
              ? MAP_MY_LAND_COLOR
              : LAND_PACK
            )
            : (data?.ownerId === signedUser?.id && signedUser.id !== undefined
              ? MAP_MY_LAND_COLOR
              :(data.isInfrastructure
                ? MAP_INFRA_COLOR
                : (data.isForSale 
                  ? (data?.User?.metamask === process.env.REACT_APP_COMPANY_WALLET_ADDRESS.toLowerCase() 
                    ? (data?.vvip ? TIER5 : (data?.tierId === 1 ? TIER1 : (data?.tierId === 2 ? TIER2 : (data?.tierId === 3 ? TIER3 : (data?.tierId === 4 ? TIER4 : (data?.tierId === 5 ? TIER5 : undefined)))))) 
                    : (MAP_FOR_SALE_COLOR)
                    )
                  : (data?.User?.metamask === process.env.REACT_APP_COMPANY_WALLET_ADDRESS.toLowerCase()
                    ? (data.tileType === MAP_SEA_LABEL ? MAP_SEA_COLOR : MAP_LAND_COLOR)
                    : (MAP_OVERLAY_COLOR)
                    )
                  )
                )
              )
            ),
        display: 'flex',
        alignItems: 'center',
        }}
        onClick={() => setActiveParcel(data)}
      >
      {/* {data?.tileGroupId && <img src={octagonImg} alt="" style={{width: size * 0.5, margin: 'auto'}} />} */}
      {(data?.vvip || data?.tierId === 5) && <i className='fa fa-star' style={{fontSize: size*0.7, margin: 'auto', color: MAP_STAR_COLOR}}/>}
    </div>
  );
}

export default LandItem;

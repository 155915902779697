import { makeStyles, Theme } from '@material-ui/core';
import { ECOLORS } from '../../../utils/constants/colors.enum';

export default makeStyles((theme: Theme) => ({
    root:{
        width:450,
        minHeight:400
    },
    topBlock:{
        height:150,
        backgroundColor:ECOLORS.GREEN_SECONDARY,
        display:"flex",
        justifyContent:"center",
        alignItems:"center"
    },
    icon:{
        color:"white",
        height:80,
        width:80
    },
    bottomBlock:{
        marginTop:20,
        display:"flex",
        flexDirection:"column",
        justifyContent:"center",
        alignItems:"center"
    },
    title:{
        fontWeight:"bold",
        fontSize:18,
        color:ECOLORS.PRIMARY_DARK
    },
    supTitle:{
        fontWeight:"bold",
        fontSize:15,
        color:ECOLORS.PRIMARY_DARK
    },
    supText:{
        fontSize:15,
        color:ECOLORS.PRIMARY_DARK,
        textAlign: 'center',
        marginLeft: 15,
        marginRight: 15
    },
    input:{
        marginTop:20,
        "& .MuiInputLabel-outlined.MuiInputLabel-shrink" :{
            transform: "translate(14px, -6px) scale(0.75)",
            display:"none"
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":{
            borderColor:ECOLORS.GREEN_SECONDARY
        }
    },
    textHelper:{
        textAlign:"left",
        color:ECOLORS.RED
    },
    buttonResend:{
        backgroundColor:ECOLORS.SECONDARY_DARK,
        borderRadius:30,
        marginTop:20,
        boxShadow:"0px 0px 0px 0px",
        color:ECOLORS.PRIMARY,
        marginRight:10,
        '&:hover': {
            backgroundColor: '#a706aaaa !important',
        }
    },
    button:{
        backgroundColor:ECOLORS.GREEN_SECONDARY,
        borderRadius:30,
        marginTop:20,
        boxShadow:"0px 0px 0px 0px",
        color:ECOLORS.PRIMARY,
        '&:hover': {
            backgroundColor: '#abd804aa !important',
        }
    },
    confirmVerification: {
        color: 'green',
        margin: 20
    }
  }));
import {useState, useEffect, useRef} from 'react'
import { useTranslation } from 'react-i18next';
import Web3 from "web3";
import axios from 'axios';
import LoadingOverlay from 'react-loading-overlay-ts';
import { ReactNotifications } from 'react-notifications-component';
import { Store } from 'react-notifications-component';
import { Switch, TextField, Typography, Dialog } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { green } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import 'react-notifications-component/dist/theme.css';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

// import Clock from "../../components/Clock";
import Footer from '../../components/footer';
import useStyles from "../styles/Authors/authors"
import { useSelector, useDispatch } from 'react-redux';
import VariableItem from '../../components/VariableItem';
import TagsDropDown from '../../components/TagsDropDown';
import UtilitiesDropDown from '../../components/UtilitiesDropDown';

import * as selectors from '../../../../store/selectors';
import * as setActions from '../../../../store/actions';

import * as CategoryService from "../../../../services/CategoryService";
import * as DataService from "../../../../services/DataService";
import * as AssetService from "../../../../services/AssetService";
import * as DesignerService from "../../../../services/DesignerService";
import * as UploadService from "../../../../services/UploadService";
import * as TagService from "../../../../services/TagService";
import * as UtilityService from "../../../../services/UtilityService";
import * as VideoService from "../../../../services/VideoService";
import Notification from "../../../../utils/functions/notification";
import Header from '../../menu/header';
import { debounce } from 'lodash';

const server3dntechURL = process.env.REACT_APP_3DNTECH_SERVER;
const ipfsURL = process.env.REACT_APP_IPFS_GATEWAY
const companyWallet = process.env.REACT_APP_COMPANY_WALLET_ADDRESS;

const GreenSwitch = withStyles({
  switchBase: {
    '&$checked': {
      color: green[500],
    },
    '&$checked + $track': {
      backgroundColor: green[500],
    },
  },
  checked: {},
  track: {},
})(Switch);

function CreateMultiple() {
  const {t}=useTranslation();
  const history=useHistory();
  const classes=useStyles();
  const dispatch = useDispatch();

  const [itemAsset, setItemAsset] = useState();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [price, setPrice] = useState(0);
  const [isForBid, setIsForBid] = useState(false);
  const [directory, setDirectory] = useState("");
  const [cidAsset, setCidAsset] = useState();
  const [gltf, setGltf] = useState(null);
  const [isActive, setActive] = useState(false);
  const [is3D, setIs3D] = useState(false);
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState();
  // const [priceUSDT, setPriceUSDT] = useState(0);
  // const [priceRate, setPriceRate] = useState(0);
  const [numberOfItem, setNumberOfItem] = useState(0);
  const [limitDate, setLimitDate] = useState();
  const [isActivate, setActivate] = useState(true);
  const [company, setCompany] = useState(null);
  const [designers, setDesigners] = useState([]);
  const [designer, setDesigner] = useState();
  const [treeData, setTreeData] = useState([]);
  const [newSubCategory, setNewSubCategory] = useState();
  const [selectedNode, setSelectedNode] = useState([]);
  const [selectedNodeName, setSelectedNodeName] = useState();
  const [selectedTree, setSelectedTree] = useState([]);
  const [isUpdate, setUpdate] = useState(false);
  const [showAddNewModal, setShowAddNewModal] = useState(false);
  const [variables, setVariables] = useState([]);
  const [filteredVariables, setFilteredVariables] = useState([]);
  const [variableJson, setVariableJson] = useState([{
    value: "0",
    variable: 'Level'
  }]);
  const [currentVariableItem, setCurrentVariableItem] = useState();
  const [continents, setContinents] = useState([]);
  const [errorMessage, setErrorMessage] = useState();
  const [tags, setTags] = useState([]);
  const [newTag, setNewTag] = useState('');
  const [tagIds, setTagIds] = useState([]);

  const [utilities, setUtilities] = useState([]);
  const [newUtility, setNewUtility] = useState('');
  const [utilityIds, setUtilityIds] = useState([]);


  // const [cidNFT, setCidNFT] = useState();
  const [progressMessage, setProgressMessage] = useState();

  const inputVideoRef = useRef(null);
  const [mediaId, setMediaId] = useState();
  const [videoId, setVideoId] = useState();

  const checkTitleValidation = (value) => {
    if(value) {
      setErrorMessage(null);
      try {
        DataService.fetchAsset(value).then(res => {
          if(res) {
            setErrorMessage('This Asset already exist - Please try again to register with a different title.');
          }
          else {
            setErrorMessage(null);
          }
        }).catch(error => {     
          let msg = (typeof error === 'object') ? error.message : error;
          setErrorMessage(msg);
        });
      }
      catch (error) {     
        let msg = (typeof error === 'object') ? error.message : error;
        setErrorMessage(msg);
      }
    }
  }

  const [debounceCheckTitleValidation] = useState(() => debounce(checkTitleValidation, 1000));

  const signedUser = useSelector(selectors.user);

  useEffect(() => {
    CategoryService.fetchCategory({exclude: 1}).then((res => {
      setCategories(res);
      generateTreeData(res);
    }));

    if (signedUser.id) {
      DataService.fetchCompany(companyWallet).then((res => {
        setCompany(res);
        DesignerService.fetchDesigners(res?.id).then((res => {
          setDesigners(res);
        }));
      }));
    }

    AssetService.fetchAssetVariable().then((res => {
      setVariables(res);
    }));

    TagService.fetchTags().then((res) => {
      setTags(res);
    });
    
    UtilityService.fetchUtilities().then((res) => {
      setUtilities(res);
    });

    DataService.fetchContinents().then((res => {
      setContinents(res);
    }));
    // eslint-disable-next-line
    // fetchPriceRate();
    // const timer = setTimeout(() => {
    //     fetchPriceRate();
    // }, 60000);

    // return () => clearTimeout(timer);
  }, [signedUser.id])

  // const fetchPriceRate = () => {
  //   DataService.fetchExchangeData().then((res => {
  //     setPriceRate(res);
  //   }));
  // }

  useEffect(() => {
    if(description.length > 1000) {
      
    }
  }, [description])

  useEffect(() => {
    if(category) {
      let isNecessaryExist = false;
      for (let i=0; i<variables.length; i++) {
        if(parseInt(variables[i].necessary) === category) {
          isNecessaryExist = true;
        }
      }

      if(isNecessaryExist) {
        setFilteredVariables(variables.filter((item) => parseInt(item.necessary) === category));
      }
      else {
        setFilteredVariables(variables.filter((item) => item.necessary === null));
      }
      setUpdate(!isUpdate);
    }
  }, [category]);

  useEffect(() => {
    if(mediaId) {
      const timer = setTimeout(() => {
        VideoService.getMediaList(mediaId).then(res => {

          if(res.data?.data?.encoded_medias?.length > 1) {
            let fileName = res.data?.data?.encoded_medias[1].id;
            let mimeType = res.data?.data?.encoded_medias[1].mimetype;
            fileName += '.' + mimeType.split(/\//)[1];
            setVideoId(fileName);
          } else {
            let fileName = res.data?.data?.encoded_medias[0].id;
            let mimeType = res.data?.data?.encoded_medias[0].mimetype;
            fileName += '.' + mimeType.split(/\//)[1];
            setVideoId(fileName);
          }
          setActive(false);
        }).catch((error) => {
          setActive(false);
          let msg = (typeof error === 'object') ? error.message : error;
          Notification(msg, false);
        })
      }, 15000); // Delay in milliseconds (e.g., 3000 ms = 3 seconds)
      return () => clearTimeout(timer);
    }
  }, [mediaId])

  const generateTreeData = (data) => {
    const result = data.reduce(function (r, a) {
      r[a.parent] = r[a.parent] || [];
      r[a.parent].push(a);
      return r;
    }, Object.create(null));

    formatData(data, result, true).then((formatedData) => {
      setTreeData(formatedData);
    });
  }

  const addVariable = (value) => {
    const updatedVariableJson = [...variableJson, value];
    let sumOfNumberValue = 0;
    let countOfNumberValue = 0;
    for(let i=1; i<updatedVariableJson.length; i++) {
      if(updatedVariableJson[i].format === 'STAR') {
        sumOfNumberValue += parseInt(updatedVariableJson[i].value / 10);
        countOfNumberValue++;
      }
    }

    updatedVariableJson[0].value = countOfNumberValue === 0 ? 0 : sumOfNumberValue;
    setVariableJson(updatedVariableJson);
  }

  const addTag = (value) => {
    dispatch(setActions.setLoading(true));
    TagService.createTag({name: value}).then((res) => {
      setTags(res);
      setNewTag('');
      dispatch(setActions.setLoading(false));
      Notification('Your Tag has been properly added to the Tag list.', true);
    }).catch(error => {
      dispatch(setActions.setLoading(false));
      let msg = (typeof error === 'object') ? error.message : error;
      Notification(msg, false);
    });
  }

  const addUtility = (value) => {
    dispatch(setActions.setLoading(true));
    UtilityService.createUtility({name: value}).then((res) => {
      setUtilities(res);
      setNewUtility('');
      dispatch(setActions.setLoading(false));
      Notification('Your Utility has been properly added to the Utility list.', true);
    }).catch(error => {
      dispatch(setActions.setLoading(false));
      let msg = (typeof error === 'object') ? error.message : error;
      Notification(msg, false);
    });
  }

  const removeVariable = (id) => {
    setVariableJson(variableJson.filter((item) => item.id !== id));
  }

  const updateVariable = (id, value) => {
    setVariableJson(current =>
      current.map(obj => {
        if (obj.id === id) {
          return {...obj, "value": value};
        }

        return obj;
      })
    )
  }

  const formatData = async (data, obj, isInit) => {
    let result = [];
    for(let i=0; i<data.length; i++) {
      if(!isInit || (isInit && data[i]['parent'] === 0)) {
        if(obj[data[i]['id']]) {
          result.push({
            id: data[i]['id'],
            name: data[i]['name'],
            parent: data[i]['parent'],
            children: await formatData(obj[data[i]['id']], obj, false)
          });
        }
        else {
          result.push({
            id: data[i]['id'],
            name: data[i]['name'],
            parent: data[i]['parent'],
            children: []
          })
        }
      }
    }

    return result;
  }

  const renderTree = (nodes, level, available) => (
    <TreeItem key={nodes.id} nodeId={nodes.id + ''} label={
        <div>
          <input type="radio" name={'name' + nodes['parent']} value={nodes['id']} id={nodes['name'] + nodes['id']} onChange={(e) => changeTreeSelection(e, level, nodes.id) } disabled={!available} checked={!available ? false : null}/>
          <label htmlFor={nodes['name'] + nodes['id']}>&nbsp;{nodes.name}</label>
        </div>
      }
      onClick={
        () => handleTreeItemClick(nodes.name)
      }
      >
      {Array.isArray(nodes.children)
        ? nodes.children.map((node) => renderTree(node, level + 1, checkChildrenAvailable(level, nodes['id'])))
        : null}
    </TreeItem>
  );

  const addNewSubCategory = async () => {
    setShowAddNewModal(false);
    dispatch(setActions.setLoading(true));
    const data = {
      'name': newSubCategory,
      'parent': selectedNode.length === 0 ? 0 : selectedNode,
    }
    try {
      const result = await CategoryService.createCategory(data);
      setNewSubCategory('');
      let temp = categories;
      temp.push(result);
      setCategories(temp);
      generateTreeData(temp);
      dispatch(setActions.setLoading(false));

      Notification('Your Sub-Category has been properly added to the Category list.', true);
    }
    catch(error) {
      dispatch(setActions.setLoading(false));
      let msg = (typeof error === 'object') ? error.message : error;
      Notification(msg, false);
    }
  }

  const changeTreeSelection = (e, level, id) => {
    if(e.target.checked) {
      let temp = selectedTree.filter((item) => item.level < level);
      temp.push({level: level, id: id});
      setCategory(id);
      setUpdate(!isUpdate);
      setSelectedTree(temp);
    }
  }

  const handleTreeItemClick = (name) => {
      setSelectedNodeName(name);
  }

  const checkChildrenAvailable = (level, id) => {
    for(let i=0; i<selectedTree.length; i++) {
      if(selectedTree[i].level === level && selectedTree[i].id === id) {
        return true;
      }
    }
    return false;
  }

  const handleShow = ()=>{
    document.getElementById("tab_opt_1").classList.add("show");
    document.getElementById("tab_opt_1").classList.remove("hide");
    document.getElementById("btn1").classList.add("active");
    document.getElementById("btn3").classList.remove("active");
    setIsForBid(false);
  }

  const handleAsset = async(event) => {
    let file = event.target.files[0];

    if(!file) {
      return;
    }

    setItemAsset(file);
    setActive(true);
    setProgressMessage('Your Asset is being uploaded...');

    const formData = new FormData();
    formData.append("asset", file, file.name);
    try {
      const res = await UploadService.uploadAsset(formData);
      setActive(false);
      if (res.status) {
        setDirectory(file.name.split('.')[0]);
        setCidAsset(res.cid);
        setIs3D(res.threeD);
  
        if (res.threeD) {
          setGltf(res.name);
        }
      } else {
        setActive(false);
        Notification(`${res.message}`, false);
      }
    } catch(error) {
      setActive(false)
      let msg = (typeof error === 'object') ? error.message : error;
      Notification(msg, false);
    }
  };

  const onMintPressed = async () => {
    setActive(true);
    setProgressMessage('Your Asset is being registered...');
    var selectedDesigner = designers.find((item) => item.name === designer);

    let designerId;
    if(selectedDesigner) {
      designerId = selectedDesigner.id;
    } else if (designer) {
      const data = {
        'name': designer,
        'companyId': company.id,
      }

      const newDesigner = await DesignerService.createDesigner(data);
      designerId = newDesigner.id;
      let tempDesigners = designers;
      tempDesigners.push(newDesigner);
      setDesigners(tempDesigners)
    }
    const amount = Web3.utils.toWei(price, 'gwei') / 1000;

    const metadata = {
      'title': title,
      'description': description,
      'price': amount,
      // 'priceInUSDT': Math.round(amount * priceRate),
      'royalty': 5,
      'isForBid': isForBid,
      'directory': directory,
      'cid': cidAsset,
      'gltf': gltf,
      'is3D': is3D,
      'video': videoUrl,
      'companyId': 1,
      'categoryId': category,
      'numberOfItems': numberOfItem,
      'limitDate': limitDate,
      'authorId': signedUser.id,
      'listedAsset': isActivate,
      'designerId': designerId,
      'variable': variableJson,
      'tag': JSON.stringify(tagIds),
      'utility': JSON.stringify(utilityIds),
      'media': mediaId,
      'mediaFile': videoId
    };

    try {      
      if (is3D) {
        const formData = new FormData();
        formData.append("directory", metadata['directory']);
        await UploadService.uploadConfirm(formData);
      }

      const asset = await AssetService.createAsset(metadata);
      const server3dntech = {
        'title': title,
        'description': description,
        'cid': cidAsset,
        'directory': directory,
        'companyId': 1,
        'authorId': signedUser.id,
        'clientId': asset.id
      }
      await AssetService.create3dntechAsset(server3dntech);

      setActive(false);
      setProgressMessage(null);
      Notification("Your Item has been properly added to the Marketplace.", true);
      setTimeout(function() { //Start the timer
        history.push({pathname:`/createOptions`}) //After 1 second, set render to true
        // eslint-disable-next-line
      }.bind(this), 5000)
    }
    catch(error) {
      setActive(false);
      setProgressMessage(null);
      Notification(`${error}`, false);
      return false
    }
  }

  const onFileChangeCapture = (e) => {
    setActive(true);
    var formData = new FormData();
    formData.append('file', e.target.files[0]);
    formData.append('folder', process.env.REACT_APP_MEDIA_FOLDER);
    VideoService.uploadVideo(formData).then(res => {
      setMediaId(res.data.data.id);
    }).catch((error) => {
      setActive(false);
      let msg = (typeof error === 'object') ? error.message : error;
      Notification(msg, false);
    })
  };

  return (
    <div>
      <ReactNotifications />
      <Header className="" />
      <section className='jumbotron breadcumb no-bg' style={{backgroundImage: `url(${'./img/background/subheader.jpg'})`}}>
        <div className='mainbreadcumb'>
          <div className='container'>
            <div className='row m-10-hor'>
              <div className='col-12'>
                <h1 className='text-center'>Add 3D Asset</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <LoadingOverlay
        active={isActive}
        spinner
        text={progressMessage}
        className='centeredOverlay'>
      <section className='container'>
      <div className="row">
        <div className="col-lg-10 offset-lg-1 mb-5">
            <form id="form-create-item" className="form-border" action="#">
                <div className="field-set">

                    <h5>{t("SelectMethod")}</h5>
                    <div className="de_tab tab_methods">
                        <ul className="de_nav">
                            <li id='btn1' className="active" onClick={handleShow}><span><i className="fa fa-tag"></i>{t("FixedPrice")}</span>
                            </li>
                            {/* <li id='btn3' onClick={handleShow1}><span><i className="fa fa-users"></i>{t("OpenForBids")}</span>
                            </li> */}
                        </ul>

                        <div className="de_tab_content pt-3">
                  
                          <div id="tab_opt_1">
                                {/* <h5>{t("Price")}</h5>
                                <input type="text" name="item_price" id="item_price" className="form-control" placeholder={t("PriceETH")} /> */}
                          </div>

                            <div id="tab_opt_2" className='hide'>
                                <h5>{t("MinimumBid")}</h5>
                                <input type="text" name="item_price_bid" id="item_price_bid" className="form-control" placeholder={t("EnterMinimumBid")} />

                                <div className="spacer-20"></div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <h5>{t("StartingDate")}</h5>
                                        <input type="date" name="bid_starting_date" id="bid_starting_date" className="form-control" min="1997-01-01" />
                                    </div>
                                    <div className="col-md-6">
                                        <h5>{t("ExpirationDate")}</h5>
                                        <input type="date" name="bid_expiration_date" id="bid_expiration_date" className="form-control" />
                                    </div>
                                </div>
                            </div>

                            <div id="tab_opt_3">
                            </div>

                        </div>

                    </div>

                    <div className="spacer-20"></div>

                    <h5>{t("3D ASSET DETAILS")}</h5>

                    <div className="spacer-10"></div>

                    <h5>{t("Not Activated / Activated")}</h5>
                    <GreenSwitch checked={isActivate} onChange={(e) => setActivate(e.target.checked)} />

                    <div className="spacer-10"></div>

                    <h5>{t("Title")}</h5>
                    <input type="text" name="item_title" id="item_title" className="form-control" placeholder={`${t("EG")}" "${t("CryptoFunk")}`} onChange={(event) => {
                      setTitle(event.target.value);
                      debounceCheckTitleValidation(event.target.value);
                    }}/>
                    {errorMessage && <p className='error-input'>{errorMessage}</p>}

                    <div className="spacer-10"></div>

                    <h5>{t("Description")}</h5>
                    <textarea data-autoresize name="item_desc" id="item_desc" className="form-control" placeholder={`${t("EG")}" "${t("VeryLimitedItem")}`} onChange={(event) => setDescription(event.target.value)}></textarea>
                    <p style={{marginTop: -20}}>{`${description.length}/1000`}</p>
                    {description.length > 1000 && <p style={{color: 'red'}}>Description can not exceed 1000 characters.</p>}

                    <div className="spacer-10"></div>

                    <h5>{t("Category")}</h5>
                    {treeData.map((tree, index) => (
                      <TreeView
                        key={index}
                        multiSelect={false}
                        aria-label="rich object"
                        defaultCollapseIcon={<ExpandMoreIcon />}
                        defaultExpandIcon={<ChevronRightIcon />}
                        sx={{ height: 110, flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
                        selected={selectedNode}
                        onNodeSelect={(event, nodeIds) => setSelectedNode(nodeIds)}
                      >
                        {renderTree(tree, 0, true)}
                      </TreeView>
                    ))}
                    
                    <input type="text" name="item_price_usdt" className="form-control newSubCategory" placeholder={selectedNodeName ? t("Input new sub-category") : t("Input new category")} value={newSubCategory} onChange={(event) => setNewSubCategory(event.target.value)}/>
                    <button className='btn-main lead mb-5 mr15' onClick={(e) => {e.preventDefault(); setShowAddNewModal(true)}} disabled={!newSubCategory}>Add</button>
                    <div className="spacer-10"></div>

                    <h5>{t("Variables")}</h5>
                    {
                      variableJson.map((item) => (
                        <VariableItem key={item['id']} item={item} isCompleted={true} removeVariable={removeVariable} continents={continents} updateVariable={updateVariable} disabled={true}/>
                      ))
                    }

                    <VariableItem item={currentVariableItem} isCompleted={false} setCurrentVariableItem={setCurrentVariableItem} addVariable={addVariable} variableList={filteredVariables.filter((item) => {
                      const idArray = variableJson.map((el) => {return el.id;});
                      return !idArray.includes(item['id']);
                    })} continents={continents} />

                    <div className="spacer-10"></div>

                    <h5>{t("Tags")}</h5>

                    <TagsDropDown tags={tags} newTag={newTag} setNewTag={setNewTag} addTag={addTag} tagIds={tagIds} setTagIds={setTagIds}/>

                    <div className="spacer-10"></div>

                    <h5>{t("Utilities")}</h5>

                    <UtilitiesDropDown utilities={utilities} newUtility={newUtility} setNewUtility={setNewUtility} addUtility={addUtility} utilityIds={utilityIds} setUtilityIds={setUtilityIds} />

                    <div className="spacer-10"></div>

                    <h5>{t("Youtube Video URL")}</h5>
                    <input type="url" name="item_video_url" id="item_video_url" className="form-control" placeholder={t("Youtube Video URL")} onChange={(event) => {setVideoUrl(event.target.value)}} value={videoUrl}/>

                    <div className="spacer-10"></div>

                    <h5>{t("Upload Video")}</h5>
                    <input type="file" onChange={onFileChangeCapture} style={{visibility:"hidden"}} ref={inputVideoRef} />
                    <button className='btn-main lead mb-5 mr15' onClick={(e) => {
                      e.preventDefault();
                      inputVideoRef.current.click();
                    }}>Upload</button>

                    {videoId && mediaId && <iframe frameborder="0" width="800" height="450"  src={`https://play.vod2.infomaniak.com/single/${mediaId}/${process.env.REACT_APP_MEDIA_PLAYER}/${videoId}`} allowfullscreen></iframe>}

                    <div className="spacer-10"></div>

                    <h5>{t("Price in USDC")}</h5>
                    <input type="number" step="1" name="item_price" id="item_price" className="form-control" placeholder={t("Price in USDC")} onChange={(event) => {setPrice(event.target.value)}} value={price}/>

                    <div className="spacer-10"></div>

                    {/* <h5>{t("Price in USDC")}</h5>
                    <input type="number" step="1" name="item_price" id="item_price" className="form-control" placeholder={t("Price in USDC")} onChange={(event) => {setPrice(event.target.value); setPriceUSDT((event.target.value * priceRate).toFixed(2))}} value={price}/> */}

                    <div className="spacer-10"></div>

                    <h5>{t("Number of Item to sell")}</h5>
                    <input type="number" step="1" name="number_of_item" id="number_of_item" className="form-control" placeholder={t("Number of Item to sell")} min={signedUser.roleId === 1 ? '0' : '1'} onChange={(event) => setNumberOfItem(event.target.value)} value={numberOfItem}/>

                    {numberOfItem == 0 && <><div className="spacer-10"></div>

                    <h5>{t("Limit Date")}</h5>
                    <input type="date" name="limit_date" id="limit_date" className="form-control" placeholder={t("Limit Date")} onChange={(event) => setLimitDate(event.target.value)} value={limitDate}/></>}

                    <div className="spacer-10"></div>

                    <h5>{t("Designers")}</h5>
                    <Autocomplete
                      id="free-solo-demo"
                      freeSolo
                      value={designer}
                      getOptionLabel={(option)=>{
                        // Value selected with enter, right from the input
                        if (typeof option === 'string') {
                          return option;
                        }
                        if (!option) {
                          return '';
                        }
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                        return option.name;
                      }}
                      options={designers.map((option) => option?.name)}
                      onInputChange={(event, newInputValue) => {
                        setDesigner(newInputValue);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} margin="normal" variant="outlined" className="form-control" />
                      )}
                    />

                    <div className="spacer-10"></div>
                    <h5>{t("UploadFile")}</h5>

                    <div className="d-create-file">
                        <p id="file_name">PNG, JPG, GIF, WEBP or MP4. Max 200mb.</p>
                        {/* {this.state.files.map(x => 
                        <p key="{index}">PNG, JPG, GIF, WEBP or MP4. Max 200mb.{x.name}</p>
                        )} */}
                        <div className='browse'>
                          <label htmlFor="get_file" style={{ background: "#f8f9fa", padding:"5px 10px", borderRadius: 5, border: '1px solid grey' }}>
                            Browse
                          </label>
                          <input type="file" id="get_file" className="btn-main" onChange={(event) => {handleAsset(event)}} style={{visibility:"hidden"}}/>
                          {/* <input id='upload_file' type="file" multiple onChange={this.onChange} /> */}
                        </div>                        
                    </div>

                    <div className="spacer-10"></div>

                    <h5>{t("PreviewItem")}</h5>
                    <div className="nft__item_create m-0">
                        {/* <div className="de_countdown">
                          <Clock deadline="December, 30, 2021" />
                        </div> */}
                        <div className="author_list_pp">
                            <span>                                    
                                <img className="lazy" src="/img/author/author-11.png" alt=""/>
                                <i className="fa fa-check"></i>
                            </span>
                        </div>
                        <div className="nft__item_wrap_create">
                            <span>
                              {directory && gltf && cidAsset && is3D && 
                                // <GLTFModel src={`${serverURL}/${directory}/${gltf}`} width="250" height="150" texPath={`${serverURL}/${directory}`}>
                                //   <AmbientLight color={0xffffff}/>
                                //   <DirectionLight color={0xffffff} position={{x:100,y:200,z:100}}/>
                                //   <DirectionLight color={0xff00ff} position={{x:-100,y:200,z:-100}}/>
                                // </GLTFModel>}
                                <img src={server3dntechURL + '/' + directory + '/' + directory + '.png'} className="collection__preview_img" alt=""/>}
                              {cidAsset && !is3D &&
                                <img src={`${ipfsURL}${cidAsset}`} className="lazy nft__item_preview" alt=""/>}
                              {!cidAsset &&
                                <img src="./img/collections/coll-item-3.jpg" id="get_file_2" className="lazy nft__item_preview" alt=""/>}
                            </span>
                        </div>
                        <div className="nft__item_info_create">
                            <span >
                                <h4>{title ? title : "Pinky Ocean"}</h4>
                            </span>
                            <p>{description}</p>
                            <div className="nft__item_price">
                                {price ? price : 0.08} USDC<span>0/{numberOfItem?numberOfItem:20}</span>
                            </div>                    
                        </div> 
                    </div>

                    <input type="button" id="submit" className="add_asset_btn btn-main" value={t("Add 3D Asset to Marketplace")} onClick={onMintPressed} disabled={!title || !price || !itemAsset || description.length > 1000 || errorMessage}/>
                </div>
            </form>
        </div>

        <div className="col-lg-3 col-sm-6 col-xs-12">
                
            </div>                                         
      </div>
    </section>
    <Dialog
        open={showAddNewModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className='alert-modal'
      >
        <div className={classes.rootModal}>
          <div className={classes.topBlock}>
              <ErrorOutlineIcon className={classes.icon} />
          </div>  
          <div className={classes.bottomBlock}>
              <Typography className={classes.title}>
                  {t("Attention")}
              </Typography>
              <Typography className={classes.supTitle}>         
                {selectedNodeName ? `Are you sure you want to add ${newSubCategory} to ${selectedNodeName} Category?` : `Are you sure you want to add ${newSubCategory} as a main Category?`}
              </Typography>
              <div className={classes.buttonBlock}>
                <button
                    className="btn-main-cancel"
                    onClick={()=>setShowAddNewModal(false)}
                >
                {t("Cancel")} 
                </button>
                <button
                    className="btn-main"
                    onClick={() => addNewSubCategory()}
                >
                    {t("OK")}
                </button>
              </div>
          </div>
        </div>
      </Dialog>
    </LoadingOverlay>

      <Footer />
    </div>
  )
}

export default CreateMultiple
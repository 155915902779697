import { combineReducers } from 'redux';
import nftReducer from './nfts';
import hotCollectionsReducer from './hotCollections';
import authorListReducer from './authorList';
import filterReducer from './filters';
import blogPostsReducer from './blogs';
import mapImagesReducer from '../slices/mapImagesSlice';
import statusReducer from '../slices/statusSlice';
import openModalNftDetailsReducer from './openModalNftDetailsReducer';
import nftDetailsReducer from './nftDetailsReducer';
import notificationsReducer from './notifications';
import loadingReducer from './loadingReducer';
import userReducer from './user';

export const rootReducer = combineReducers({
  NFT: nftReducer,
  hotCollection: hotCollectionsReducer,
  authors: authorListReducer,
  filters: filterReducer,
  blogs: blogPostsReducer,
  mapImagesLoaded: mapImagesReducer,
  gameStatus: statusReducer,
  openModalNftDetails:openModalNftDetailsReducer,
  NftDetails:nftDetailsReducer,
  notifications: notificationsReducer,
  loadings: loadingReducer,
  user: userReducer,
});

const reducers = (state, action) => rootReducer(state, action);

export default reducers;
import { Canceler } from '../../../core/axios';
import * as actions from '../../actions';
import * as NotificationService from "../../../services/NotificationService";


export const fetchNotifications = () => async (dispatch) => {

  dispatch(actions.fetchNotifications.request(Canceler.cancel));
  
  try {
    dispatch(actions.setLoading(true));
    const data = await NotificationService.fetchNotifications();

    dispatch(actions.setLoading(false));
    dispatch(actions.fetchNotifications.success(data));
  } catch (err) {
    dispatch(actions.fetchNotifications.failure(err));
  }
};

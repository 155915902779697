import { getType } from 'typesafe-actions';
import * as actions from '../actions';
import { initEntityState } from '../utils';

export const defaultState = {
  isActive: initEntityState(false),
  showRegisterModal: false,
};

const states = (state = defaultState, action) => {
  switch (action.type) {
    case getType(actions.setLoading):
      return { ...state, isActive: action.payload};
    case getType(actions.setShowRegisterModal):
      return { ...state, showRegisterModal: action.payload};

    default:
      return state;
  }
};

export default states;
const serverURL = process.env.REACT_APP_SERVER;
const ipfsURL = process.env.REACT_APP_IPFS_GATEWAY;
const server3dnTechURL = process.env.REACT_APP_3DNTECH_SERVER

const api = {
    // baseUrl: '/mock_data', //mock data base folder
    baseUrl: serverURL,
    server3dnTech: server3dnTechURL,
    ipfsURL: ipfsURL,
    nfts: '/nfts.json',
    nftShowcases: '/nft_showcases.json',
    authors: '/authors.json',
    authorsSales: '/author_ranks.json',
    hotCollections: '/hot-collections.json',
    contactUs: '/contact-forms',
    blogs: '/blog-posts',
    recent: '/blog-posts/recent.json',
    comments: '/blog-posts/comments.json',
    tags: '/blog-posts/tags.json',
}

export const openseaApi = {
    base: 'https://testnets.opensea.io',
    api: 'https://testnets-api.opensea.io',
}

export default api;
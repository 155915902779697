import { useState } from 'react';
import { Box } from '@material-ui/core';
import VariableItemAssetDetail from './VariableItemAssetDetail';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';
import TableCell from '@material-ui/core/TableCell';
import { withStyles } from '@material-ui/core/styles';
import api from '../../../core/api';
import { convertDateFormat } from '../../../utils/functions/convertDateFormat';
import Web3 from "web3";

const styles = {
  container: {
    display: 'flex',
    padding: 20,
    border: '1px solid #DDD',
    borderRadius: 15
  },
  tabText: {
    lineHeight: 0.8,
    fontSize: 23,
    cursor: 'pointer'
  },
  tabTextActive: {
    lineHeight: 1,
    fontSize: 23,
    color: '#a706aa',
    cursor: 'pointer'
  },
  divider: {
    borderLeft: '2px solid #a706aa',
    marginLeft: 20,
    marginRight: 20
  },
  description: {
    // maxWidth: '32vw'
  },
  ratingContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  ratingLabel: {
    marginRight: 10,
    width: 75
  },
  descriptionText: {
    whiteSpace: 'pre-wrap',
  },
  nftId: {
    color: '#34c77b'
  }
}

const StyledTableCell = withStyles((theme) => ({
  head: {
      backgroundColor: '#30166e',
      color: theme.palette.common.white,
  },
  body: {
      fontSize: 14,
  },
}))(TableCell);

const extractVideoId = (url) => {
  const match = url.match(/[?&]v=([^?&]+)/);
  return match ? match[1] : null;
}

function AssetCardFooter({description, variable, continents, histories, preview, video, media, mediaFile, isAsset=false, seller={}}) {
  const [tab, setTab] = useState(0);
  
  return (
    <Box style={styles.container}>
      <Box>
        <p onClick={() => setTab(0)} style={tab === 0 ? styles.tabTextActive : styles.tabText}>VIDEO</p>
        <p onClick={() => setTab(1)} style={tab === 1 ? styles.tabTextActive : styles.tabText}>DESCRIPTION</p>
        <p onClick={() => setTab(2)} style={tab === 2 ? styles.tabTextActive : styles.tabText}>IMAGE</p>
        <p onClick={() => setTab(3)} style={tab === 3 ? styles.tabTextActive : styles.tabText}>CAPACITIES</p>
        <p onClick={() => setTab(4)} style={tab === 4 ? styles.tabTextActive : styles.tabText}>HISTORY</p>
      </Box>
      <Box style={styles.divider}></Box>
      <Box>
        {tab === 0 && <Box style={styles.description}>
          {video ? <iframe
            width="800"
            height="450"
            src={`https://www.youtube.com/embed/${extractVideoId(video)}`}
            frameBorder="0"
            allowFullScreen
          ></iframe> : media && mediaFile && <iframe
              width="800"
              height="450"
              src={`https://play.vod2.infomaniak.com/single/${media}/${process.env.REACT_APP_MEDIA_PLAYER}/${mediaFile}`}
              frameBorder="0"
              allowFullScreen
            ></iframe>}
          </Box>}
        {tab === 1 && <Box style={styles.description}>
          <p style={styles.descriptionText}>{description}</p>
        </Box>}
        {tab === 2 && <Box style={styles.description}>
          {preview && <PhotoProvider>
            <PhotoView src={preview}>
              <img src={preview} alt="" style={{maxWidth: 350, cursor: 'pointer'}} />
            </PhotoView>
          </PhotoProvider>}
        </Box>}
        {tab === 3 && <Box>
          {variable && variable.map((item, index) => 
            <Box key={index} style={styles.ratingContainer}>
              <VariableItemAssetDetail item={item} continents={continents} isLabel={true} />
            </Box>
          )}
        </Box>}
        {tab === 4 && 
          <Box>
            {isAsset && histories && <div>
              <table className='table de-table table-history'>
                <thead>
                  <tr>
                    <th>NFT ID</th>
                    <th>From</th>
                    <th>Price</th>
                    <th>To</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  {histories.map((history, index) => (
                    <tr key={index}>
                      <td><span style={styles.nftId}># {history.tokenId}</span></td>
                      <td>
                        <div className='coll_list_pp'>
                          { seller.picture ?
                            <img
                              className='lazy'
                              src={
                                `${api.baseUrl}/${seller.picture}`
                              }
                              alt=''
                            />
                            // <i className='fa fa-check'></i>
                            :
                            <img className="lazy" src="/img/author/author-11.png" alt="" />
                          }
                        </div>
                        <span>{seller.username}</span>
                      </td>
                      <td>{history.price ? Web3.utils.fromWei(history.price, 'gwei') * 1000: '0'} USDC</td>
                      <td>
                        <div className='coll_list_pp'>
                          { history.User.picture ?
                            <img
                              className='lazy'
                              src={
                                `${api.baseUrl}/${history.User.picture}`
                              }
                              alt=''
                            />
                            // <i className='fa fa-check'></i>
                            :
                            <img className="lazy" src="/img/author/author-11.png" alt="" />
                          }
                        </div>
                        <span>{history.User.username}</span>
                      </td>
                      <td>{convertDateFormat(history.createdAt.replace('T', ' ').replace('.000Z', ''))} (UTC)</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              </div>
            }
            {!isAsset && histories && <table className='table de-table table-history'>
                <thead>
                  <tr>
                    <th>From</th>
                    <th>Price</th>
                    <th>Type of Tx</th>
                    <th>To</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  {histories.map((history, index) => (
                    <tr key={index}>
                      <td>
                        <div className='coll_list_pp'>
                          { history.sender.picture ?
                            <img
                              className='lazy'
                              src={
                                `${api.baseUrl}/${history.sender.picture}`
                              }
                              alt=''
                            />
                            // <i className='fa fa-check'></i>
                            :
                            <img className="lazy" src="/img/author/author-11.png" alt="" />
                          }
                        </div>
                        <span>{history.sender.username}</span>
                      </td>
                      <td>{history.price ? Web3.utils.fromWei(history.price, 'gwei') * 1000: '0'} USDC</td>
                      <td>{history.typeOfTx}</td>
                      <td>
                        <div className='coll_list_pp'>
                          { history.recipient.picture ?
                            <img
                              className='lazy'
                              src={
                                `${api.baseUrl}/${history.recipient.picture}`
                              }
                              alt=''
                            />
                            // <i className='fa fa-check'></i>
                            :
                            <img className="lazy" src="/img/author/author-11.png" alt="" />
                          }
                        </div>
                        <span>{history.recipient.username}</span>
                      </td>
                      <td>{convertDateFormat(history.createdAt.replace('T', ' ').replace('.000Z', ''))} (UTC)</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            }
          </Box>}
      </Box>
    </Box>
  )
}

export default AssetCardFooter
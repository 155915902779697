import React, { useEffect } from 'react';
// import Footer from "../../components/Footer"
import Footer from '../../../../pages/MarkePlace/components/footer';
import { StyledHeader } from '../../../MarkePlace/Styles';
import Header from '../../../MarkePlace/menu/header';
import PlayEarniverseSection from '../../../../components/GetStarted/playEarniverseSection';
import ChooseSection from '../../../../components/GetStarted/chooseSection';
import EarnSection from '../../../../components/GetStarted/earnSection';

//SWITCH VARIABLE FOR PAGE STYLE
const theme = 'HOME'; //LIGHT, GREY, RETRO

function GetStarted() {
  useEffect(() => {
    window.scrollTo(0,0);
  }, [])
  return <div>
    <StyledHeader theme={theme} />
    <Header className="HOME"/>
    <PlayEarniverseSection/>
    <ChooseSection/>
    <EarnSection />
    <Footer/>
  </div>;
}

export default GetStarted;

import React, { memo, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as selectors from '../../../store/selectors';
import * as actions from '../../../store/actions/thunks';
import { clearTiles, clearFilter, showLoadMoreTiles } from '../../../store/actions';
import TileCard from './TileCard';
import NftMusicCard from './NftMusicCard';
import { shuffleArray } from '../../../store/utils';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import { useHistory } from 'react-router';
import { EPATHS } from '../../../routes/paths.enum';
import Pagination from '@material-ui/lab/Pagination';
import useStyles from '../pages/styles/Authors/authors';

//react functional component
const ColumnTileRedux = ({ continent, trigger, posX, posY, posZ, shuffle=false, authorId=null, isOwned=false, isForSale=false, orderBy=false, tier}) => {
    const {t}=useTranslation();
    const dispatch = useDispatch();
    const tileItems = useSelector(selectors.tileItems);
    const tileCount = useSelector(selectors.tileCount);
    const loadingState = useSelector(selectors.loadingState);
    const tiles = tileItems ? shuffle ? shuffleArray(tileItems) : tileItems : [];
    const [page, setPage] = useState(0);
    const [height, setHeight] = useState(350);
    const history = useHistory();
    const classes = useStyles();

    const onImgLoad = ({target:img}) => {}

    useEffect(() => {
        if(trigger !== undefined && continent !== undefined) {
            dispatch(showLoadMoreTiles(true));
            dispatch(clearFilter());
            dispatch(clearTiles());
            dispatch(actions.fetchTilesBreakdown(authorId, 0, continent, posX, posY, posZ, isForSale, orderBy, tier));
            setPage(1);  
        }
    }, [trigger, continent, isForSale, orderBy, tier])

    const handleSelectPage=(event,newPage)=>{
        dispatch(clearTiles());
        if(continent) {
          dispatch(actions.fetchTilesBreakdown(authorId, newPage-1, continent, posX, posY, posZ, isForSale, orderBy, tier));
        }
        else {
          dispatch(actions.fetchTilesBreakdown(authorId, newPage-1, continent, posX, posY, posZ, isForSale, orderBy, tier));
        }
        setPage(newPage);
      }

    return (
        <div className='row'>
            {tiles.length === 0 && authorId && !loadingState && <div style={{textAlign: 'center'}}>
                <Typography style={{marginBottom: 10}}>You don’t have any LANDs in your wallet. To start buying LAND, you can visit the LAND marketplace.</Typography>
                <span onClick={() => history.push({pathname: EPATHS.LAND})} className="btn-main lead m-auto">{"Visit LAND"}</span>
            </div>}
            {tiles && tiles.map( (nft, index) => (
                nft.category === 'music' ?
                <NftMusicCard nft={nft} audioUrl={nft.audio_url} key={index} onImgLoad={onImgLoad} height={height} />
                :
                <TileCard nft={nft} key={index} account={authorId} height={200} />
            //    <AssetCard nft={nft} key={index} onImgLoad={onImgLoad} account={authorId} height={height}/>
            ))}
            <div className={classes.root}>
                <Pagination
                    page={page} 
                    count={Math.ceil(tileCount/12)} 
                    onChange={handleSelectPage}
                    variant="outlined"
                    shape="rounded" />
            </div>
        </div>              
    );
};

export default memo(ColumnTileRedux);
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useForm } from "react-hook-form";
import Footer from '../../components/footer';
import { EPATHS } from '../../../../routes/paths.enum';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTranslation } from 'react-i18next';
import CodeVerification from '../../../Modals/codeVerification';
import * as setActions from '../../../../store/actions';
import { useDispatch } from 'react-redux';
import * as AuthService from "../../../../services/AuthService";
import { debounce } from 'lodash';
import { fetchUsername, fetchEmail } from '../../../../services/DataService';
import Header from '../../menu/header';

const fileToDataUri = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.onload = (event) => {
    resolve(event.target.result)
  };
  reader.readAsDataURL(file);
  })

const USER_EXISTS_ERROR = 'This username already exist - Please try again to register with a different username.';
const EMAIL_EXISTS_ERROR = 'This email already exist - Please try again to register with a different email.';

function Register() {
  const {t}=useTranslation();
  const [profilePictureFile, setProfilePictureFile] = useState(null)
  const [profileBannerFile, setProfileBannerFile] = useState(null)
  // eslint-disable-next-line
  const [profilePicture, setProfilePicture] = useState()
  // eslint-disable-next-line
  const [profileBanner, setProfileBanner] = useState()
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isNewsLetterChecked, setIsNewsLetterChecked] = useState(true);
  const [regFormData, setFormData] = useState();
  const [ethAddress, setEthAddress] = useState();
  const [password, setPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const { register, handleSubmit ,formState: { errors }} = useForm();
  const history=useHistory();
  const dispatch = useDispatch();

  const checkUsernameValidation = (value) => {
    if(value) {
      setErrorMessage(null);
      try {
        fetchUsername(value).then(res => {
          if(res) {
            setErrorMessage(USER_EXISTS_ERROR);
          }
          else {
            setErrorMessage(null);
          }
        }).catch(error => {     
          let msg = (typeof error === 'object') ? error.message : error;
          setErrorMessage(msg);
        });
      }
      catch (error) {    
        let msg = (typeof error === 'object') ? error.message : error;
        setErrorMessage(msg);
      }
    }
  }

  const checkEmailValidation = (value) => {
    if(value) {
      setErrorMessage(null);
      try {
        fetchEmail(value).then(res => {
          if(res) {
            setErrorMessage(EMAIL_EXISTS_ERROR);
          }
          else {
            setErrorMessage(null);
          }
        }).catch(error => {     
          let msg = (typeof error === 'object') ? error.message : error;
          setErrorMessage(msg);
        });
      }
      catch (error) {    
        let msg = (typeof error === 'object') ? error.message : error;
        setErrorMessage(msg);
      }
    }
  }

  const [debounceCheckUsernameValidation] = useState(() => debounce(checkUsernameValidation, 1000));
  const [debounceCheckEmailValidation] = useState(() => debounce(checkEmailValidation, 1000));

  const handleRegistration = async(data) =>{
    try {
      const formData = new FormData();
      formData.append('email', data.email);
      formData.append('username', data.username);
      formData.append("firstName", data.firstname);
      formData.append("lastName", data.lastname);
      formData.append("newsletter", isNewsLetterChecked);
      formData.append("metamask", ethAddress);
      formData.append("password", data.password);
      if (profilePictureFile) {
        formData.append("picture", profilePictureFile);
      }
      if (profileBannerFile) {
        formData.append("banner", profileBannerFile);
      }

      dispatch(setActions.setLoading(true));
      await AuthService.signup(formData);
      dispatch(setActions.setLoading(false));

      setOpen(true);
      setFormData(formData);
      setOpen(false);
      setOpenModal(true)
    } catch (error) {
      setOpen(false);
      dispatch(setActions.setLoading(false));
      let msg = (typeof error === 'object') ? error.message : error;
      setErrorMessage(msg);
    }
  }

  useEffect(() => {
    window.ethereum
      .request({ method: 'eth_accounts' })
      .then(async (res) => {
          if(res.length === 0) {
            history.push({pathname: EPATHS.HOME});
          }
          else {
            setEthAddress(res[0]);
          }
      })
      .catch((error) => {});
    //eslint-disable-next-line
  }, []);

  const handleUploadProfilePicture=(event)=>{
  //   if(event.target.files[0].size > 2097152){
  //     alert("File is too big!");
  //  }else {
    setProfilePictureFile(event.target.files[0])
    fileToDataUri(event.target.files[0])
    .then(dataUri => {
      setProfilePicture(dataUri)
    })
  //  }
  }

  const handleUploadProfileBanner=(event)=>{
  //   if(event.target.files[0].size > 2097152){
  //     alert("File is too big!");
  //  }else {
    setProfileBannerFile(event.target.files[0])
    fileToDataUri(event.target.files[0])
    .then(dataUri => {
      setProfileBanner(dataUri)
    })
  //  }
  }

  const handleChangeNewsLetter=()=>{
    setIsNewsLetterChecked(!isNewsLetterChecked);
  }

  return (
    <div>    
      <Backdrop style={{zIndex:9999}} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Header className=""/>
      <section className='jumbotron breadcumb no-bg' style={{backgroundImage: `url(${'./img/background/subheader.jpg'})`}}>
        <div className='mainbreadcumb'>
          <div className='container'>
            <div className='row'>
              <div className="col-md-12 text-center">
                  <h1>{t("Register")}</h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='container'>
        <div className="row">    
          <div className="col-md-8 offset-md-2">
            <h3>{t("RegisterNowAnAccount")}</h3>
            <div className="spacer-10"></div>      
            <form name="contactForm" id='contact_form' className="form-border" onSubmit={handleSubmit(handleRegistration)}>    
              <div className="row">
                  <div className="col-md-6">
                      <div className="field-set">
                          <label>{t("Username")}</label>
                          <input 
                            type='text'
                            name='username'
                            id='username'
                            placeholder={t("EnterUsername")}
                            className="form-control" {...register('username',{required:t("RequiredUsername")})}
                            onChange={(e) => debounceCheckUsernameValidation(e.target.value)}
                          />
                          <p className='error-input'>{errors?.username && errors.username.message}</p>
                      </div>
                  </div>

                  <div className="col-md-6">
                      <div className="field-set">
                          <label>{t("Email")}</label>
                          <input
                            type='email'
                            name='email'
                            id='email'
                            placeholder={t("Email")}
                            className="form-control"
                            {...register('email',{required:t("RequiredEmail")})}
                            onChange={(e) => debounceCheckEmailValidation(e.target.value)}
                          />
                          <p className='error-input'>{errors?.email && errors.email.message}</p>
                      </div>
                  </div>

                  <div className="col-md-6">
                      <div className="field-set">
                          <label>{t("FirstName")}</label>
                          <input type='text' name='firstname' placeholder={t("EnterFirstName")} id='firstname' className="form-control" {...register('firstname',{required:t("RequiredFirstname")})} />
                          <p className='error-input'>{errors?.firstname && errors.firstname.message}</p>
                      </div>
                  </div>

                  <div className="col-md-6">
                      <div className="field-set">
                          <label>{t("LastName")}</label>
                          <input type='text' name='lastname' placeholder={t("EnterLastName")} id='lastname' className="form-control" {...register('lastname',{required:t("RequiredLastname")})} />
                          <p className='error-input'>{errors?.lastname && errors.lastname.message}</p>
                      </div>
                  </div>
                  <div className="col-md-6">
                      <div className="field-set-input">
                          <label>{t("ProfilePicture")}</label>
                          <label htmlFor="profile_picture" className="form-control input-control">{profilePictureFile?profilePictureFile.name:t("SelectProfilePicture")}</label>
                          <input type='file' style={{display:"none"}} name='profile_picture' id='profile_picture'
                            className="form-control"
                            onChange={handleUploadProfilePicture}
                            />
                      </div>
                  </div>
                  <div className="col-md-6">
                      <div className="field-set-input" >
                          <label>{t("ProfileBanner")}</label>
                          <label htmlFor="profile_banner" className="form-control input-control">{profileBannerFile?profileBannerFile.name:t("SelectProfileBanner")}</label>
                          <input type='file'  style={{display:"none"}} name='profile_banner' id='profile_banner'
                            className="form-control" 
                            onChange={handleUploadProfileBanner}
                            />
                      </div>
                  </div>
                  <div className="col-md-6">
                      <div className="field-set">
                          <label>Password</label>
                          <input
                            value={password}
                            type='password'
                            name='password'
                            id='password'
                            placeholder="Password"
                            className="form-control"
                            {...register('password',{required:t("RequiredPassword")})}
                            onChange={(e) => {
                              setPassword(e.target.value);
                              if(e.target.value !== confirmPassword) {
                                setErrorMessage("Passwords do not match.");
                              } else {
                                setErrorMessage(null);
                              }
                            }}
                            />
                          <p className='error-input'>{errors?.password && errors.password.message}</p>
                      </div>
                  </div>
                  <div className="col-md-6">
                      <div className="field-set">
                          <label>Confirm Password</label>
                          <input
                            value={confirmPassword}
                            type='password'
                            name='confirm_password'
                            id='confirm_password'
                            placeholder="Confirm Password"
                            className="form-control"
                            {...register('confirm_password',{required:t("RequiredConfirmPassword")})}
                            onChange={(e) => {
                              setConfirmPassword(e.target.value);
                              if(e.target.value !== password) {
                                setErrorMessage("Passwords do not match.");
                              } else {
                                setErrorMessage(null);
                              }
                            }}
                          />
                          <p className='error-input'>{errors?.confirm_password && errors.confirm_password.message}</p>
                      </div>
                  </div>
                  <div className="col-md-12">
                      <p className='password-input'>Password should be at least 8 characters long and contain at least one symbol, one high caps letter and one number.</p>
                  </div>
                  <div className="col-md-6">
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox"  checked={isNewsLetterChecked}
                            onChange={handleChangeNewsLetter} id="flexCheckDefault"/>
                      <label className="form-check-label" htmlFor="flexCheckDefault">
                        {t("Newsletter")}
                      </label>
                    </div>
                  </div>                  
                  <div className="col-md-12">
                      <span>{"I accept to subscribe to the Earniverse newsletter and receive the latest news from Earniverse and Earninews."}</span>
                      <div className="spacer-20"></div>
                  </div>
                  {
                    errorMessage && <div className="col-md-12">
                      <p className='error-input'>{errorMessage}</p>
                    </div>
                  }
                  <div className="col-md-12">
                      <div id='submit' className="pull-left">
                          <input type="submit"  id='send_message' value={t("RegisterNow")} className="btn-main lead" disabled={errorMessage === USER_EXISTS_ERROR || password !== confirmPassword} />
                      </div>
                      <div className="clearfix"></div>                      
                  </div>
              </div>
            </form>
          </div>    
        </div>
      </section>

      <Footer />
      <CodeVerification open={openModal} onClose={()=>setOpenModal(false)} formData={regFormData} isRegister={true}/>
    </div>
  )
}

export default Register
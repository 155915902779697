import { axiosWithToken } from '../utils/functions/apiCallWithToken';

const serverURL = process.env.REACT_APP_SERVER;

export const setNftLike = async (assetId, nftId) => {
    const result = await axiosWithToken(`${serverURL}/nft/like/${assetId}/${nftId}`, 'post', {});
    return result;
}

export const fetchNftLike = async (page) => {
    const result = await axiosWithToken(`${serverURL}/nft/like/?page=${page}`, 'get', {});
    return result;
}

export const setNftDislike = async (nftId) => {
    const result = await axiosWithToken(`${serverURL}/nft/dislike/${nftId}`, 'delete', {});
    return result;
}

export const setNftWishlist = async (assetId, nftId) => {
    const result = await axiosWithToken(`${serverURL}/nft/wishlist/${assetId}/${nftId}/`, 'post', {});
    return result;
}

export const fetchNftWishlist = async (page) => {
    const result = await axiosWithToken(`${serverURL}/nft/wishlist/?page=${page}`, 'get', {});
    return result;
}

export const setNftDiswishlist = async (nftId) => {
    const result = await axiosWithToken(`${serverURL}/nft/dis-wishlist/${nftId}/`, 'delete', {});
    return result;
}

export const fetchNftBids = async () => {
    const result = await axiosWithToken(`${serverURL}/nft/bid/`, 'get', {});
    return result;
}

export const createNFT = async (assetId, data) => {
    const result = await axiosWithToken(`${serverURL}/nft/create/${assetId}/`, 'post', data);
    return result;
}

export const admintNFT = async (assetId, data) => {
    const result = await axiosWithToken(`${serverURL}/nft/admint/${assetId}/`, 'post', data);
    return result;
}

export const fetchNFT = async (nftId) => {
    const result = await axiosWithToken(`${serverURL}/nft/${nftId}/`, 'get', {});
    return result;
}

export const fetchNFTs = async (params) => {
    const result = await axiosWithToken(`${serverURL}/nft/`, 'get', {}, params);
    return result;
}

export const addNFTListing = async (id, data) => {
    const result = await axiosWithToken(`${serverURL}/nft/add-listing/${id}/`, 'put', data);
    return result;
}

export const cancelNFTListing = async (id, data) => {
    const result = await axiosWithToken(`${serverURL}/nft/cancel-listing/${id}/`, 'put', data);
    return result;
}

export const buyNFT = async (id, data) => {
    const result = await axiosWithToken(`${serverURL}/nft/buy/${id}/`, 'put', data);
    return result;
}

export const transferNFT = async (id, data) => {
    const result = await axiosWithToken(`${serverURL}/nft/transfer/${id}/`, 'put', data);
    return result;
}

export const burnNFT = async (data) => {
    const result = await axiosWithToken(`${serverURL}/nft/burn/`, 'post', data);
    return result;
}

export const cancelNFT = async (data) => {
    const result = await axiosWithToken(`${serverURL}/nft/cancel/`, 'post', data);
    return result;
}
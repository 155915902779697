import React, { useState } from 'react'
import { Typography, Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { useTranslation } from 'react-i18next';

import useStyles from "../../styles/components/Servererror/modalError"

function ModalUELogin({open, user, onClose}) {
    const classes=useStyles();
    const {t}=useTranslation();

  return (
    <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    className='alert-modal'
  >
      <div className={classes.root}>
        <div className={classes.ueLoginTopBlock}>
            <HighlightOffIcon className={classes.icon} onClick={() => onClose()} />
        </div>  
        <div className={classes.bottomBlock}>
            <Typography className={classes.supTitle}>
              { !user.uecode ? <p>No UE login code is currently available. Please make sure to renew your demand on Earniverse Game Interface. For any issue, feel free to create a ticket on <a href='https://support.earniverse.io'>https://support.earniverse.io</a></p> : <p>{user.uecode}</p>}
            </Typography>
        </div>
      </div>
  </Dialog>
  )
}

export default ModalUELogin;
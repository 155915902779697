import { makeStyles, Theme } from '@material-ui/core';


export default makeStyles((theme: Theme) => ({  
    root:{
        width:"100%",
        height:"65vw",
        display:"flex",
        flexDirection:"column",
        justifyContent:"center",
        alignItems:"flex-end",
        overflow:"hidden",
        position: "relative",    
        zIndex: 1,
        [theme.breakpoints.down(1320)]: {
            height:"65vw",
        },
    },
    img:{
        position: "absolute",
        left: 0,
        top: 85,
        width: "100%",
        height: "auto",
    },
}));
import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import ColumnTileRedux from '../../components/ColumnTileRedux';
import ColumnTileGroupRedux from '../../components/ColumnTileGroupRedux';
import Footer from '../../components/footer';
import { StyledHeader } from '../../Styles';
import Select from 'react-select';
import MenuItem from '@material-ui/core/MenuItem';
import * as selectors from '../../../../store/selectors';
import * as actions from '../../../../store/actions';
import { useSelector, useDispatch } from 'react-redux';
import { 
  TextField,
  Select as MUISelect,
 } from '@material-ui/core';
import Header from '../../menu/header';
import * as DataService from "../../../../services/DataService";

const theme = 'GREY'; //LIGHT, GREY, RETRO

function Land() {
    // const [priceRate, setPriceRate] = useState(0);
    const [continents, setContinents] = useState([]);
    const [tiers, setTiers] = useState([]);
    const [tier, setTier] = useState(0);
    const [type, setType] = useState(0);
    const [posX, setPosX] = useState();
    const [posY, setPosY] = useState();
    const [posZ, setPosZ] = useState();
    const [trigger, setTrigger] = useState(false);
    const continent = useSelector(selectors.continent);
    const dispatch = useDispatch();
    const [orderBy, setOrderBy] = useState(0);

    const orderOptions = [
      {label: '', value: 0},
      {label: 'Lowest Price', value: 1},
      {label: 'Highest Price', value: 2},
    ];

    const customStyles = {
      option: (base, state) => ({
          ...base,
          background: "#fff",
          color: "#333",
          borderRadius: state.isFocused ? "0" : 0,
          "&:hover": {
              background: "#eee",
          }
      }),
      menu: base => ({
          ...base,
          borderRadius: 0,
          marginTop: 0
      }),
      menuList: base => ({
          ...base,
          padding: 0
      }),
      control: (base, state) => ({
          ...base,
          padding: 2,
          height: 61
      })
    };

    useEffect(() => {
      // DataService.fetchExchangeData().then((res => {
      //   setPriceRate(res);
      // }))

      DataService.fetchContinents().then((res => {
        setContinents(res.filter(c => c.name !== 'Earnicity'));
      }))

      DataService.fetchTiers().then((res => {
        setTiers(res);
      }))
      // eslint-disable-next-line
    }, [])

    const { t } = useTranslation();
  return (
    <div>
    <StyledHeader theme={theme} />
    <Header className="" />
      <section className='jumbotron breadcumb no-bg' style={{backgroundImage: `url(${'./img/background/subheader.jpg'})`}}>
        <div className='mainbreadcumb'>
        <div className='container'>
            <div className='row m-10-hor'>
            <div className='col-12'>
                <h1 className='text-center'>{t("Land")}</h1>
            </div>
            </div>
        </div>
        </div>
      </section>
        <section className='container'>
          <div className='row'>
            <div className='col-lg-12' style={{display: 'flex', alignItems: 'center', marginBottom: 20}}>
              <MUISelect
                value={type}
                onChange={(e) => setType(e.target.value)}
                variant="outlined"
                style={{minWidth: 150}}
              >
                <MenuItem value={0}>Single Land</MenuItem>
                <MenuItem value={1}>Pack of Lands</MenuItem>
              </MUISelect>
              <MUISelect
                className='ml-20'
                value={continent}
                onChange={(e) => dispatch(actions.setContinent(e.target.value))}
                variant="outlined"
                style={{minWidth: 150}}
              >
                <MenuItem key={0} value={0}>All Continents</MenuItem>
                {continents.map((item, i) => (
                  <MenuItem key={i} value={item.id}>{item.name}</MenuItem>
                ))}
              </MUISelect>
              <div className='ml-20'>
                <div>
                  <p style={{color: '#a706aa'}}>Order By</p>
                </div>
                <div>
                  <div style={{marginTop: -18, width: '100%', minWidth: 150}}>
                    <div className='dropdownSelect one'>
                      <Select
                        value={orderOptions.find((option) => option.value === orderBy)}
                        styles={customStyles} 
                        menuContainerStyle={{'zIndex': 999}}
                        options={orderOptions.filter((option) => option.value !== 0)}
                        onChange={(option) => setOrderBy(option.value)}
                      />
                      <p style={{color: '#a706aa', marginBottom: 8, cursor: 'pointer', textAlign: 'right', marginRight: 20, fontSize: 13, fontWeight: 600}} onClick={() => setOrderBy(0)}>Clear</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='ml-20'>
                <div>
                  <p style={{color: '#a706aa'}}>Tier</p>
                </div>
                <div>
                  <div style={{marginTop: -18, width: '100%', minWidth: 150}}>
                    <div className='dropdownSelect one'>
                      <MUISelect
                        className='mt-1'
                        value={tier}
                        onChange={(e) => setTier(e.target.value)}
                        variant="outlined"
                        style={{minWidth: 150}}
                      >
                        <MenuItem key={0} value={0}>All Tiers</MenuItem>
                        {tiers.map((item, i) => (
                          <MenuItem key={i} value={item.id}>{item.name}</MenuItem>
                        ))}
                      </MUISelect>
                      <p style={{color: '#a706aa', marginBottom: 8, cursor: 'pointer', textAlign: 'right', marginRight: 20, fontSize: 13, fontWeight: 600}} onClick={() => setTier(0)}>Clear</p>
                    </div>
                  </div>
                </div>
              </div>
              {
                type === 0 && <React.Fragment>
                  <TextField
                    className='ml-20'
                    id="outlined-number"
                    type="number"
                    variant="outlined"
                    placeholder='X pos'
                    value={posX}
                    onChange={(e) => setPosX(e.target.value)}
                    style={{width: 150}}
                  />
                  <TextField
                    className='ml-20'
                    id="outlined-number"
                    type="number"
                    variant="outlined"
                    placeholder='Y pos'
                    value={posY}
                    onChange={(e) => setPosY(e.target.value)}
                    style={{width: 150}}
                  />
                  <TextField
                    className='ml-20'
                    id="outlined-number"
                    type="number"
                    variant="outlined"
                    placeholder='Z pos'
                    value={posZ}
                    onChange={(e) => setPosZ(e.target.value)}
                    style={{width: 150}}
                  />
                </React.Fragment>
              }
              {type === 0 && <span onClick={() => setTrigger(!trigger)} className="btn-main ml-20">{t("Filter")}</span>}
            </div>
          </div>
          {
            type === 0 ? <ColumnTileRedux continent={continent} trigger={trigger} posX={posX} posY={posY} posZ={posZ} isForSale={true}  orderBy={orderBy} tier={tier}/> 
            : <ColumnTileGroupRedux continent={continent} isForSale={true} orderBy={orderBy} tier={tier}/>
          }
        </section>
    <Footer />
    </div>
  )
}

export default Land;
import { memo, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import Footer from '../../components/footer';
import { StyledHeader } from '../../Styles';
import { useTranslation } from "react-i18next";
import * as actions from "../../../../store/actions/thunks";
import Header from "../../menu/header";

//SWITCH VARIABLE FOR PAGE STYLE
const theme = 'GREY'; //LIGHT, GREY, RETRO

function CollectionHistory() {
    const { assetId } = useParams();
    const {t}=useTranslation();
    const dispatch = useDispatch();
    let nft = useSelector(state=>state.NFT.assetDetail.data);

    useEffect(() => {
      dispatch(actions.fetchAssetDetail(assetId));
      console.log(nft)
      // eslint-disable-next-line
    }, [dispatch, assetId]);
  
  return (
   <div>
      {/* <GlobalStyles/> */}
      <StyledHeader theme={theme} />
      <Header className="" />
      <section className='jumbotron breadcumb no-bg' style={{backgroundImage: `url(${'/img/background/subheader.jpg'})`}}>
        <div className='mainbreadcumb'>
          <div className='container'>
            <div className='row m-10-hor'>
              <div className='col-12'>
                <h1 className='text-center'>{t("NFT History")}</h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='container'>
        <div className='row'>
          <div className='col-lg-12 collection__table_wrapper'>
            <table className="table de-table table-rank">
              <thead>
                <tr>
                  <th scope="col">{"No"}</th>
                  <th scope="col">{"Date"}</th>
                  <th scope="col">{"From"}</th>
                  <th scope="col">{"To"}</th>
                  <th scope="col">{"Price"}</th>
                  <th scope="col">{"Currency"}</th>
                </tr>
                <tr></tr>
              </thead>
              <tbody>
                {/* {nft && nft.SalesHistories.map((history, index) => (
                    <tr key={index}>
                        <td>{index + 1}</td>
                        <td><strong>{history.createdAt}</strong></td>
                        <td><strong>{history.Seller.username}</strong></td>
                        <td><strong>{history.Buyer.username}</strong></td>
                        <td><strong>{Web3.utils.fromWei(history.buyingPrice)}</strong></td>
                        <td><strong>{history.buyingCurrency}</strong></td>
                    </tr>
                ))} */}
              </tbody>
            </table>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
}

export default memo(CollectionHistory);
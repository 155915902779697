import React, { memo, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ColumnProfileRedux from '../../components/ColumnProfileRedux';
import Footer from '../../components/footer';
import * as selectors from '../../../../store/selectors';
import api from '../../../../core/api';
import { useTranslation } from 'react-i18next';
import { Box, Tab, Tabs } from '@material-ui/core';
import { EPATHS } from '../../../../routes/paths.enum';
import Header from '../../menu/header';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function MyProfile() {
  const { t } = useTranslation();
  const history = useHistory();
  const [tabValue, setTabValue] = React.useState(0);
  const [openMenu, setOpenMenu] = useState(true);
  const [openMenu1, setOpenMenu1] = useState(false);
  const [openMenu2, setOpenMenu2] = useState(false);
  const [priceRate, setPriceRate] = useState(0);
  const serverURL = process.env.REACT_APP_SERVER;
  const signedUser = useSelector(selectors.user);

  const handleBtnClick = () => {
    setTabValue(0);
    setOpenMenu(!openMenu);
    setOpenMenu1(false);
    setOpenMenu2(false);
    document.getElementById('Mainbtn').classList.add('active');
    document.getElementById('Mainbtn1').classList.remove('active');
  };
  const handleBtnClick1 = () => {
    setTabValue(0);
    setOpenMenu1(!openMenu1);
    setOpenMenu2(false);
    setOpenMenu(false);
    document.getElementById('Mainbtn1').classList.add('active');
    document.getElementById('Mainbtn').classList.remove('active');
  };

  const dispatch = useDispatch();

  useEffect(() => {
    // fetchPriceRate();
    // eslint-disable-next-line
  }, [dispatch]);

  // const fetchPriceRate = () => {
  //   axios.get(`${serverURL}/data/exchange/`, { headers: authHeader() }).then((res => {
  //     setPriceRate(res.data.rate);
  //   }));
  // }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div>
      <Header className=""/>
      <section id='profile_banner'
        className='jumbotron breadcumb no-bg'
        style={{
          backgroundImage: 
            signedUser.id
              ? signedUser.banner
                ? `url(${api.baseUrl}/${encodeURIComponent(signedUser.banner)})`
                : '../../img/background/subheader.jpg'
              : '',
          backgroundSize: 'cover'}}>
        <div className='mainbreadcumb'>
        </div>
      </section>
      <section className='container no-bottom'>
        <div className='row'>
          <div className='col-md-12 de-flex'>
            <div className='d_profile de-flex'>
              <div className='de-flex-col'>
                <div className='profile_avatar'>
                  {
                    signedUser && (
                      <img src={signedUser.accessToken ? (signedUser.picture? `${api.baseUrl}/${signedUser.picture}` : "../../img/author/author-11.png") : ''} alt=''
                      />
                    )
                    // <img src={api.baseUrl + author.avatar.url} alt="" style={{backgroundImage: `url(${api.baseUrl + author.banner.url})`}}/>
                  }
                  <i className='fa fa-check'></i>
                  <div className='profile_name'>
                    <h4>
                      {/* {author.username}                                           */}
                      {signedUser ? signedUser.firstName : ''}{' '}
                      {signedUser ? signedUser.lastName : ''}
                      {/* <span className="profile_username">{author.social}</span> */}
                      <div style={{display: 'flex'}}>
                        <span className='profile_username'>
                          {signedUser ? signedUser.email : ''} 
                        </span>
                        <span className='profile_password' onClick={() => history.push({pathname: EPATHS.RESET_PASSWORD})}>
                          Forgot Password
                        </span>
                      </div>
                      <div style={{display: 'grid'}}>
                        <span id='wallet' className='profile_wallet'>
                          {signedUser.metamask}
                        </span>
                        {/* <button id="btn_copy" title="Copy Text">Copy</button> */}
                        <span className='profile_username'>
                          {signedUser ? signedUser.username : ''}
                        </span>
                      </div>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <button className='btn-main' onClick={() => history.push({ pathname: EPATHS.EDIT_PROFILE})}>Edit Profile</button>
            </div>
          </div>
        </div>
      </section>

      <section className='container no-top'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='items_filter'>
              <ul className='de_nav text-left'>
                <li id='Mainbtn' className='active'>
                  <span onClick={handleBtnClick}>{t('Liked')}</span>
                </li>
                <li id='Mainbtn1' className=''>
                  <span onClick={handleBtnClick1}>{t('Wishlist')}</span>
                </li>
                {/* <li id='Mainbtn2' className=''>
                  <span onClick={handleBtnClick2}>{t('My Royalties')}</span>
                </li>
                <li id='Mainbtn2' className=''>
                  <span onClick={() => history.push({pathname: EPATHS.MY_BIDS})}>{t('My Bids')}</span>
                </li> */}
              {/* {user.get("newsletter") &&  <li id='Mainbtn3' className=''>
                  <span onClick={handleBtnClick3}>{t('Newsletter')}</span>
                </li>} */}
              </ul>
            </div>
          </div>
        </div>
        {openMenu && signedUser.id && (
          <div id='zero1' className='onStep fadeIn'>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                <Tab label="Tile" {...a11yProps(0)} />
                <Tab label="Asset" {...a11yProps(1)} />
                <Tab label="NFT" {...a11yProps(2)} />
              </Tabs>
            </Box>
            <TabPanel value={tabValue} index={0}>
              <ColumnProfileRedux shuffle priceRate={priceRate} authorId={signedUser.id} itemType={'tile'} votedType={'like'} />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <ColumnProfileRedux shuffle priceRate={priceRate} authorId={signedUser.id} itemType={'asset'} votedType={'like'} />
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
              <ColumnProfileRedux shuffle priceRate={priceRate} authorId={signedUser.id} itemType={'nft'} votedType={'like'} />
            </TabPanel>
          </div>
        )}
        {openMenu1 && signedUser.id && (
          <div id='zero2' className='onStep fadeIn'>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                <Tab label="Tile" {...a11yProps(0)} />
                <Tab label="Asset" {...a11yProps(1)} />
                <Tab label="NFT" {...a11yProps(2)} />
              </Tabs>
            </Box>
            <TabPanel value={tabValue} index={0}>
              <ColumnProfileRedux shuffle priceRate={priceRate} authorId={signedUser.id} itemType={'tile'} votedType={'wishlist'} />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <ColumnProfileRedux shuffle priceRate={priceRate} authorId={signedUser.id} itemType={'asset'} votedType={'wishlist'} />
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
              <ColumnProfileRedux shuffle priceRate={priceRate} authorId={signedUser.id} itemType={'nft'} votedType={'wishlist'} />
            </TabPanel>
          </div>
        )}
        {openMenu2 && (
          <div id='zero3' className='onStep fadeIn'>
            {/* <ColumnNewRedux shuffle priceRate={priceRate} category={false} query={null} trigger={null} showLoadMore={false} /> */}
          </div>
        )}
      </section>
      <Footer />
    </div>
  );
}

export default memo(MyProfile);

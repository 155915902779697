import React, { useState } from 'react';
import { Button, Menu, MenuItem,Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import LOGO from "../../../assets/images/Logo_EIV_Gradient-Icone.png"
import UK_FLAG from "../../../assets/images/uk-flag.png"
import FR_FLAG from "../../../assets/images/fr-flag.png"

import useStyles from "../../../styles/components/Footer/"

function Footer(){
    const classes=useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const {t,i18n}=useTranslation();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const changeLanguage=(value)=>{
        i18n.changeLanguage(value)
        setAnchorEl(null);
    }

  return(<footer className="footer-light">
            {/* <div className="container">
                <div className="row">
                    <div className="col-md-3 col-sm-6 col-xs-1">
                        <div className="widget">
                            <h5>Marketplace</h5>
                            <ul>
                                <li><Link to="">All NFTs</Link></li>
                                <li><Link to="">Art</Link></li>
                                <li><Link to="">Music</Link></li>
                                <li><Link to="">Domain Names</Link></li>
                                <li><Link to="">Virtual World</Link></li>
                                <li><Link to="">Collectibles</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-6 col-xs-1">
                        <div className="widget">
                            <h5>Resources</h5>
                            <ul>
                                <li><Link to="">Help Center</Link></li>
                                <li><Link to="">Partners</Link></li>
                                <li><Link to="">Suggestions</Link></li>
                                <li><Link to="">Discord</Link></li>
                                <li><Link to="">Docs</Link></li>
                                <li><Link to="">Newsletter</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-6 col-xs-1">
                        <div className="widget">
                            <h5>Community</h5>
                            <ul>
                                <li><Link to="">Community</Link></li>
                                <li><Link to="">Documentation</Link></li>
                                <li><Link to="">Brand Assets</Link></li>
                                <li><Link to="">Blog</Link></li>
                                <li><Link to="">Forum</Link></li>
                                <li><Link to="">Mailing List</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-6 col-xs-1">
                        <div className="widget">
                            <h5>Newsletter</h5>
                            <p>Signup for our newsletter to get the latest news in your inbox.</p>
                            <form action="#" className="row form-dark" id="form_subscribe" method="post" name="form_subscribe">
                                <div className="col text-center">
                                    <input className="form-control" id="txt_subscribe" name="txt_subscribe" placeholder="enter your email" type="text" /> 
                                    <Link to="" id="btn-subscribe">
                                      <i className="arrow_right bg-color-secondary"></i>
                                    </Link>
                                    <div className="clearfix"></div>
                                </div>
                            </form>
                            <div className="spacer-10"></div>
                            <small>Your email is safe with us. We don't spam.</small>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="subfooter">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="de-flex">
                                <div className="de-flex-col">
                                {/* <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} className={classes.changeLg}>
                                    {localStorage.getItem("i18nextLng")==="en"? "English" : "Français"} <ArrowDropDownIcon/>
                                </Button> */}
                                <Menu
                                    id="simple-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                >
                                    <MenuItem onClick={()=>changeLanguage("en")} >
                                        <img src={UK_FLAG} alt={UK_FLAG} className={classes.flag} />
                                        English</MenuItem>
                                    <MenuItem onClick={()=>changeLanguage("fr")}>
                                        <img src={FR_FLAG} alt={FR_FLAG} className={classes.flag} />
                                        Frensh</MenuItem>
                                </Menu>          
                                    <span onClick={()=> window.open("", "_self")}>
                                        <img alt="" className="f-logo d-1" src={LOGO} style={{width:30,height:30}} />
                                        <img alt="" className="f-logo d-3" src={LOGO} style={{width:30,height:30}} />
                                        <img alt="" className="f-logo d-4" src={LOGO} style={{width:30,height:30}}  />
                                        <span className="copy">&copy; Copyright {new Date().getFullYear()} - <a href='https://earniverse.io' target="_blank" rel="noreferrer">Earniverse.io</a> All Rights Reserved - <a href='https://earniverse.io/privacy-policy/' target="_blank">Privacy Policy</a> | <a href="http://earniverse.io/terms-of-service/" target="_blank">Terms of Services</a></span>
                                    </span>
                                </div>
                                <div className="de-flex-col">
                                    <div className="social-icons">
                                        <span  onClick={()=> window.open("https://twitter.com/Earniverse_io", "_blank")}><i className="fa fa-twitter fa-lg"></i></span>
                                        <span onClick={()=> window.open("https://www.facebook.com/earniverseofficial", "_blank")}><i className="fa fa-facebook fa-lg"></i></span>
                                        <span onClick={()=> window.open("https://www.instagram.com/earniverse_official/", "_blank")}><i className="fa fa-instagram fa-lg"></i></span>
                                        <span onClick={()=> window.open("https://t.me/earniverse", "_blank")}><i className="fa fa-telegram fa-lg"></i></span>
                                        <span onClick={()=> window.open("https://t.me/earniverse_official_chat", "_blank")}><i className="fa fa-comment fa-lg"></i></span>
                                        <span onClick={()=> window.open("https://www.youtube.com/@Earniverse", "_blank")}><i className="fa fa-youtube fa-lg"></i></span>
                                        {/* <span onClick={()=> window.open("", "_self")}><i className="fa fa-linkedin fa-lg"></i></span>
                                        <span onClick={()=> window.open("", "_self")}><i className="fa fa-pinterest fa-lg"></i></span>
                                        <span onClick={()=> window.open("", "_self")}><i className="fa fa-rss fa-lg"></i></span> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>)
}
export default Footer;
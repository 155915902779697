import { Store } from 'react-notifications-component';

const Notification = (msg, isSucess) => {
    Store.addNotification({
        title: isSucess ? "Success" : "Failed",
        message: msg,
        type: isSucess ? "success" : "danger",
        insert: "top",
        container: "bottom-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          onScreen: true,
          showIcon: true
        }
      });
}

export default Notification;
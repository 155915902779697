import { Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useHistory } from 'react-router-dom';

import VRHEADSET from "../../assets/images/VR_HEADSET.png"
import { EPATHS } from '../../routes/paths.enum';

import useStyles from "../../styles/components/Home/exploreSection"
import DefaultButton from '../buttons/defaultButton';


function ExploreSection() {
  const classes=useStyles();
  const {t}=useTranslation();
  const history=useHistory();

  return <div className={classes.root}>
       <div className={classes.blockText}>
        <Typography variant='h1'>{t("Explore")}</Typography>
        <Typography variant='h2' style={{maxWidth: 475}}>
          {t("LoseYourself")}
        </Typography>
        <NavLink to={EPATHS.GET_STARTED} className={classes.buttonContainer} onClick={() => history.push({pathname:EPATHS.GET_STARTED})}>
          <DefaultButton color="primary" variant='outlined' text={t("GetStarted")} fontSize={18} />
        </NavLink>
      </div>
      <div className={classes.imageContainer}>
        <img src={VRHEADSET} alt={VRHEADSET} className={classes.img}/>
      </div>
  </div>;
}

export default ExploreSection;

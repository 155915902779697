import { makeStyles, Theme } from '@material-ui/core';
import { ECOLORS } from '../../../utils/constants/colors.enum';

export default makeStyles((theme: Theme) => ({  
    root:{
        display:"flex",
        justifyContent:"space-between",
        alignItems:"center",
        height:"36vw",
        backgroundColor:ECOLORS.PRIMARY,
        padding:"0px 10vw 0px 10vw",
        overflow:"hidden"
    },
    imageContainer: {
        marginRight: '-10vw'
    },
    img:{
        width:"35vw",
    },
    blockText:{
        display:"flex",
        justifyContent:"center",
        flexDirection:"column",
        paddingLeft: '13vw',
        // textTransform:"capitalize",
            "& h1":{
                textAlign:"center",
                textTransform:"capitalize",
                fontFamily:"tahoma",
                fontSize:48,
                fontWeight: "bold",
                marginBottom:20,
                color:ECOLORS.PRIMARY_DARK,
                [theme.breakpoints.down(1700)]: {
                    fontSize:44,
                },              
                [theme.breakpoints.down(1550)]: {
                    fontSize:42,
                },              
                [theme.breakpoints.down(1400)]: {
                    fontSize:40,
                },  
                [theme.breakpoints.down(1250)]: {
                    fontSize:38,
                },             
                [theme.breakpoints.down(1150)]: {
                    fontSize:36,
                },             
                [theme.breakpoints.down(960)]: {
                    fontSize:34,
                },    
                [theme.breakpoints.down(890)]: {
                    fontSize:30,
                }  
        },
        "& h2":{
             fontFamily:"tahoma",
            fontSize:20,
            fontWeight:"bold",
            color:ECOLORS.SECONDARY_DARK,
            width:"40vw",
            textAlign:"center",
            [theme.breakpoints.down(1700)]: {
                fontSize:19,
            },
            [theme.breakpoints.down(1550)]: {
                fontSize:18,
            },
            [theme.breakpoints.down(1400)]: {
                fontSize:17,
            },   
            [theme.breakpoints.down(1250)]: {
                fontSize:16,
            }, 
            [theme.breakpoints.down(1150)]: {
                fontSize:16,
            },  
            [theme.breakpoints.down(960)]: {
                fontSize:14,
            }, 
        },
        "& h4":{
            fontFamily:"tahoma",
            fontSize:20,
            fontWeight:"bold",
            marginBottom:20,
            color:ECOLORS.SECONDARY_DARK,
            width:"40vw",
            textAlign:"center",
            [theme.breakpoints.down(1700)]: {
                fontSize:19,
            },
            [theme.breakpoints.down(1550)]: {
                fontSize:18,
            },
            [theme.breakpoints.down(1400)]: {
                fontSize:17,
            },   
            [theme.breakpoints.down(1250)]: {
                fontSize:16,
            }, 
            [theme.breakpoints.down(1150)]: {
                fontSize:16,
            },  
            [theme.breakpoints.down(960)]: {
                fontSize:14,
            },  
        }
    },
    buttonContainer:{
        alignSelf:"center",
        width:250,
        height:50,
        marginTop:20,
        [theme.breakpoints.down('md')]: {
            width:200,
            height:40,
        },
        [theme.breakpoints.down(960)]: {
            width:180,
            height:35,
        }
    }

}));
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import ModalNoMetamask from "../../../components/Servererror/modalNoMetamask";
import ModalAvax from '../../../components/Servererror/modalAvax';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../store/actions/thunks';
import * as setActions from '../../../store/actions';
import * as selectors from '../../../store/selectors';
import { login, register } from '../../../utils/functions/authenticate';

const Wallet= () =>{
    const signedUser = useSelector(selectors.user);
    const [serverError, setServerError] = useState(false);
    const [avaxModalVisiblity, setAvaxModalVisiblity] = useState(false);

    const history=useHistory();
    const {t}=useTranslation();
    const CHAINID = Number(process.env.REACT_APP_AVAX_CHAIN_ID).toString();
    const dispatch = useDispatch();

    // eslint-disable-next-line
    useEffect(async () => {
        if (window.ethereum) {
            window.ethereum.on('chainChanged', function(networkId) {
				if(networkId === CHAINID) {
					setAvaxModalVisiblity(false)
				}
				else {
					setAvaxModalVisiblity(true)
				}
            });

            try {
                await window.ethereum.request({ method: 'eth_requestAccounts' });
            } catch (error) { console.log(error);}

            if(window.ethereum.networkVersion && window.ethereum.networkVersion !== CHAINID){
                setAvaxModalVisiblity(true)
            }else {
                setAvaxModalVisiblity(false)
            }
            
            login(dispatch, setActions.setLoading, setActions.setUser, history, setServerError);
        }
        // eslint-disable-next-line
    }, []);
    
    const handleCloseModal=()=>{
        setServerError(false)
    }


 return ( <div className="row">
    <div className="col-lg-3 mb30" style={{cursor:"pointer"}} onClick={() => {
            if(!signedUser.id) {
                login(dispatch, setActions.setLoading, setActions.setUser, history, setServerError);
            }
        }}>
        <span className="box-url">
            <span className="box-url-label">{t("MostPopular")}</span>
            <img src="./img/wallet/1.png" alt="" className="mb20"/>
            <h4>{t("Metamask")}</h4>
            <p>{t("StartExploring")}</p>
        </span>
    </div>

    <ModalNoMetamask open={serverError} onClose={handleCloseModal}/>
    <ModalAvax open={avaxModalVisiblity} onClose={()=>setAvaxModalVisiblity(false)} />
</div>)
};
export default Wallet;
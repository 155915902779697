import { axiosWithToken } from '../utils/functions/apiCallWithToken';

const serverURL = process.env.REACT_APP_SERVER;


export const fetchCoupon = async (code) => {
    const result = await axiosWithToken(`${serverURL}/coupon/${code}/`, 'get', {});
    return result;
}

export const fetchCoupons = async () => {
    const result = await axiosWithToken(`${serverURL}/coupon/`, 'get', {});
    return result;
}

export const createCoupon = async (data) => {
    const result = await axiosWithToken(`${serverURL}/coupon/create/`, 'post', data);
    return result;
}

export const removeCoupon = async (id) => {
    const result = await axiosWithToken(`${serverURL}/coupon/remove/${id}`, 'delete', {});
    return result;
}
import React from 'react';
import { Viewer } from './viewer.js';
import LoadingOverlay from 'react-loading-overlay-ts';
window.VIEWER = {};

export default class App extends React.Component {

  constructor (props) {
    super(props);
    this.options = {};
    this.state = {
      isActive: true,
      progressMessage: "Please wait..."
    }
    this.viewer = null;
    this.viewerEl = null;
  }

  componentDidMount() {
    this.view(this.props.src, document.getElementById('viewer'));
  }
  /**
   * Sets up the view manager.
   * @return {Viewer}
   */
  createViewer (element) {
    this.viewer = new Viewer(element, this.options, this.props.width, this.props.height, this.props.ratio);
    return this.viewer;
  }

  componentWillUnmount() {
    this.viewer.clear();
  }

  /**
   * Loads a fileset provided by user action.
   * @param  {Map<string, File>} fileMap
   */
  // load (fileMap) {
  //   let rootFile;
  //   let rootPath;
  //   Array.from(fileMap).forEach(([path, file]) => {
  //     if (file.name.match(/\.(gltf|glb)$/)) {
  //       rootFile = file;
  //       rootPath = path.replace(file.name, '');
  //     }
  //   });

  //   if (!rootFile) {
  //     this.onError('No .gltf or .glb asset found.');
  //   }

  //   this.view(rootFile, rootPath, fileMap);
  // }

  /**
   * Passes a model to the viewer, given file and resources.
   * @param  {File|string} rootFile
   * @param  {string} rootPath
   */
  view (rootFile, el) {

    if (this.viewer) this.viewer.clear();

    const viewer = this.viewer || this.createViewer(el);

    const fileURL = typeof rootFile === 'string'
      ? rootFile
      : URL.createObjectURL(rootFile);

    const cleanup = () => {
      if (typeof rootFile === 'object') URL.revokeObjectURL(fileURL);
    };

    if (this.viewer) {
      viewer
      .load(rootFile)
      .catch((e) => this.onError(e))
      .then((gltf) => {
        this.setState({
          isActive: false
        })
        // this.mount.current.appendChild(gltf);
        cleanup();
      });
    }
    
  }

  /**
   * @param  {Error} error
   */
  onError (error) {
    let message = (error||{}).message || error.toString();
    if (message.match(/ProgressEvent/)) {
      message = 'Unable to retrieve this file. Check JS console and browser network tab.';
    } else if (message.match(/Unexpected token/)) {
      message = `Unable to parse file content. Verify that this file is valid. Error: "${message}"`;
    } else if (error && error.target && error.target instanceof Image) {
      message = 'Missing texture: ' + error.target.src.split('/').pop();
    }
    window.alert(message);
    console.error(error);
  }

  render() {
    const viewerStyle = {      
      backgroundColor: this.props.color || 'white',
      width: this.props.width,
      height: this.props.height,
      margin: 'auto',
      maxWidth: '100%',
      overflow: 'hidden',
      borderRadius: 15
    }

    return (
      <LoadingOverlay
        active={this.state.isActive}
        className="NotCenteredOverlay NftThreeCard"
        spinner
        text={this.state.progressMessage}>
          <div className='viewer' id="viewer" style={viewerStyle} />
      </LoadingOverlay>
    )
  }
}

// document.addEventListener('DOMContentLoaded', () => {

//   const app = new App(document.body, location);

//   window.VIEWER.app = app;

//   console.info('[glTF Viewer] Debugging data exported as `window.VIEWER`.');

// });
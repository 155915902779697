import { makeStyles, Theme } from '@material-ui/core';
import { ECOLORS } from '../../../utils/constants/colors.enum';


export default makeStyles((theme: Theme) => ({  
    root:{
        width:"100%",
        height:"42vw",
        display:"flex",
        flexDirection:"column",
        justifyContent:"center",
        alignItems:"flex-end",
        overflow:"hidden",
        position: "relative",    
        zIndex: 1,
        [theme.breakpoints.down(1320)]: {
            height:"42vw",
        },
    },
    img:{
        position: "absolute",
        left: 0,
        top: 85,
        width: "100%",
        height: "auto",
    },
    blockText:{
        marginRight:"14vw",
        display:"flex",
        justifyContent:"center",
        flexDirection:"column",
        zIndex: 2,
        [theme.breakpoints.down('md')]: {
               paddingRight:"10vw",
        },
        [theme.breakpoints.down(1600)]: {
            marginRight:"10vw",
        },
         [theme.breakpoints.down(1280)]: {
            marginRight:"4vw",
        },
         [theme.breakpoints.down(1000)]: {
            marginRight:"9vw",
        }, 
        "& h1":{
                textTransform:"capitalize",
                fontFamily:"tahoma",
                fontSize:48,
                fontWeight: "bold",
                marginBottom:20,
                [theme.breakpoints.down(1700)]: {
                    fontSize:44,
                },              
                [theme.breakpoints.down(1550)]: {
                    fontSize:42,
                },              
                [theme.breakpoints.down(1350)]: {
                    fontSize:40,
                },  
                [theme.breakpoints.down(1280)]: {
                    fontSize:38,
                },             
                [theme.breakpoints.down(1000)]: {
                    fontSize:36,
                },   
                [theme.breakpoints.down(860)]: {
                    fontSize:33,
                } 
        },
        "& h2":{
              fontFamily:"tahoma",
            fontSize:20,
            fontWeight:"bold",
            color:ECOLORS.SECONDARY_DARK,
            width:"28vw",
            textAlign:"center",
            [theme.breakpoints.down(1855)]: {
                fontSize:19,
                 width:"29vw",
            },
            [theme.breakpoints.down(1700)]: {
                fontSize:19,
                 width:"31vw",
            },
            [theme.breakpoints.down(1590)]: {
                fontSize:18,
                 width:"31vw",
            },
            [theme.breakpoints.down(1550)]: {
                fontSize:18,
                 width:"32vw",
            },
            [theme.breakpoints.down(1460)]: {
                fontSize:17,
                 width:"33vw",
            },   
            [theme.breakpoints.down(1350)]: {
                fontSize:16,
                 width:"32vw",
            }, 
            [theme.breakpoints.down(1280)]: {
                fontSize:16,
                width:"35vw",
            },  
            [theme.breakpoints.down(1000)]: {
                fontSize:15,
                width:"43vw",
            }, 
            [theme.breakpoints.down(860)]: {
                fontSize:14,
                width:"44vw",
            },    
        }
    },
    title: {
        color: 'white'
    },
    subtitle: {
        color: 'white',
        fontSize: 20,
        width: 450
    }
}));
import {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import ColumnNewRedux from '../../components/ColumnNewRedux';
import Footer from '../../components/footer';
import { StyledHeader } from '../../Styles';
import Select from 'react-select';
import { TextField } from '@material-ui/core';
import Header from '../../menu/header';
import * as TagService from "../../../../services/TagService";
import * as UtilityService from "../../../../services/UtilityService";
import * as CategoryService from "../../../../services/CategoryService";
import TagsDropDownFilter from '../../components/TagsDropDownFilter';
import UtilitiesDropDownFilter from '../../components/UtilitiesDropDownFilter';

const theme = 'GREY'; //LIGHT, GREY, RETRO

function Explore() {
  const [priceRate, setPriceRate] = useState(0);
  const [category, setCategory] = useState(); //Default is `Transportation`
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);
  const [tagIds, setTagIds] = useState([]);
  const [utilities, setUtilities] = useState([]);
  const [utilityIds, setUtilityIds] = useState([]);
  const [categoryFilterList, setCategoryFilterList] = useState([]);
  const [isUpdate, setUpdate] = useState(false);
  const [query, setQuery] = useState('');
  const [trigger, setTrigger] = useState(false);
  const [orderBy, setOrderBy] = useState(0);
  const [isNotSold, setIsNotSold] = useState(false);

  const orderOptions = [
    {label: '', value: 0},
    {label: 'A to Z', value: 1},
    {label: 'Lowest Price', value: 2},
    {label: 'Highest Price', value: 3},
  ]

  useEffect(() => {
    // DataService.fetchExchangeData().then((res => {
    //   setPriceRate(res);
    // }))

    CategoryService.fetchCategory().then((res => {
      //except Land & Land_layout
      const filteredCategories = res;
      setCategories(filteredCategories);
      generateOptionsById(0, filteredCategories).then((result) => {
          setCategoryFilterList([
            {level: 0, parent: 0, selected: null, options: result},
          ]);
      })
    }));

    TagService.fetchTags().then(res => {
      setTags(res);
    });

    UtilityService.fetchUtilities().then(res => {
      setUtilities(res);
    });
    // eslint-disable-next-line
  }, []);

  const generateOptionsById = async (id, data) => {
    let options = [];
    const tempOptions = data.filter((item) => item.parent === id);
    for(let i=0; i<tempOptions.length; i++) {
      options.push({
        value: tempOptions[i].id,
        label: tempOptions[i].name
      });
    }

    return options;
  }

  const handleFilterItemSelect = (level, value) => {
    let currentFilterItem = categoryFilterList.find((item) => item.level === level);
    currentFilterItem.selected = value;
    let tempCategoryFilterList = categoryFilterList.filter((item) => item.level < level);
    tempCategoryFilterList.push(currentFilterItem);
    setCategoryFilterList(tempCategoryFilterList);
    setUpdate(!isUpdate);

    if(value) {
      generateOptionsById(value, categories).then((result) => {
        if(result.length > 0 && value !== currentFilterItem.parent) {
          tempCategoryFilterList.push({level: level + 1, parent: value, selected: null, options: result});
          setCategoryFilterList(tempCategoryFilterList);
        }
        setUpdate(!isUpdate);
        setCategory(value);
      });
    }
    else {
      setCategoryFilterList(tempCategoryFilterList);
      setUpdate(!isUpdate);
      setCategory(false);
    }
    
  }

  const { t } = useTranslation();

  const defaultValue = {
    value: null,
    label: t("SelectFilter")
  };

  const customStyles = {
    option: (base, state) => ({
        ...base,
        background: "#fff",
        color: "#333",
        borderRadius: state.isFocused ? "0" : 0,
        "&:hover": {
            background: "#eee",
        }
    }),
    menu: base => ({
        ...base,
        borderRadius: 0,
        marginTop: 0
    }),
    menuList: base => ({
        ...base,
        padding: 0
    }),
    control: (base, state) => ({
        ...base,
        padding: 2
    })
  };

  return (
    <div>
      <StyledHeader theme={theme} />
      <Header className=""/>
      <section className='jumbotron breadcumb no-bg' style={{backgroundImage: `url(${'./img/background/subheader.jpg'})`}}>
          <div className='mainbreadcumb'>
          <div className='container'>
              <div className='row m-10-hor'>
              <div className='col-12'>
                  <h1 className='text-center'>{t("Explore")}</h1>
              </div>
              </div>
          </div>
          </div>
      </section>

      <section className='container'>
        <div className='row'>
          {categoryFilterList.length > 0 && <div className='col-lg-12 flex-display mb-20' style={{justifyContent: 'right', alignItems: 'flex-start'}}>
            <div>
              <TextField
                id="outlined-number"
                type="text"
                variant="outlined"
                placeholder='Search'
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                inputProps={{
                  style: {
                    height: 0,
                  },
                }}
          
              />
              <p style={{color: '#a706aa', marginBottom: 8, cursor: 'pointer', textAlign: 'right', marginRight: 20, fontSize: 13, fontWeight: 600}} onClick={() => {setQuery(''); setTrigger(!trigger);}}>Clear</p>
            </div>
            <span onClick={() => setTrigger(!trigger)} className="btn-main ml-20">{t("Search")}</span>
          </div>}
          <div className='col-lg-12' style={{display: 'flex'}}>
            <div>
              <div>
                <p style={{color: '#a706aa'}}>Category</p>
              </div>
              <div>
                <div className="items_filter">
                  {
                    categoryFilterList.map((filterItem, index) => (
                      <div className='dropdownSelect one' key={index}>
                        <Select
                          value={filterItem.options.find((option) => option.value === filterItem.selected)}
                          styles={customStyles} 
                          menuContainerStyle={{'zIndex': 999}}
                          options={[index === 0 ? defaultValue : {
                            value: filterItem.parent,
                            label: 'All'
                          }, ...filterItem.options]}
                          onChange={(option) => handleFilterItemSelect(index, option.value)}
                        />
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>
            <div>
              <div>
                <p style={{color: '#a706aa', marginBottom: 8}}>Tags</p>
              </div>
              <div className='mb-20'>
                <TagsDropDownFilter tags={tags} tagIds={tagIds} setTagIds={setTagIds} />
                <p style={{color: '#a706aa', marginBottom: 8, cursor: 'pointer', textAlign: 'right', marginRight: 30, fontSize: 13, fontWeight: 600}} onClick={() => setTagIds([])}>Clear</p>
              </div>
            </div>
            <div>
              <div>
                <p style={{color: '#a706aa', marginBottom: 8}}>Utilities</p>
              </div>
              <div className='mb-20'>
                <UtilitiesDropDownFilter utilities={utilities} utilityIds={utilityIds} setUtilityIds={setUtilityIds} />
                <p style={{color: '#a706aa', marginBottom: 8, cursor: 'pointer', textAlign: 'right', marginRight: 30, fontSize: 13, fontWeight: 600}} onClick={() => setUtilityIds([])}>Clear</p>
              </div>
            </div>
            <div>
              <div>
                <p style={{color: '#a706aa'}}>Order By</p>
              </div>
              <div>
                <div className="items_filter">
                  <div className='dropdownSelect one'>
                    <Select
                      value={orderOptions.find((option) => option.value === orderBy)}
                      styles={customStyles} 
                      menuContainerStyle={{'zIndex': 999}}
                      options={orderOptions.filter((option) => option.value !== 0)}
                      onChange={(option) => setOrderBy(option.value)}
                    />
                    <p style={{color: '#a706aa', marginBottom: 8, cursor: 'pointer', textAlign: 'right', marginRight: 20, fontSize: 13, fontWeight: 600}} onClick={() => setOrderBy(0)}>Clear</p>
                  </div>
                </div>
              </div>
            </div>
            <div style={{paddingTop: 35}}>
              <input
                className='form-check-input'
                type='checkbox'
                id='flexCheckDefault'
                checked={isNotSold}
                onChange={() => setIsNotSold(!isNotSold)}
              />
              <label
                className='form-check-label-profile'
                htmlFor='flexCheckDefault'
                style={{color: '#a706aa'}}
              >
                {"Not Sold Out"}
              </label>
            </div>
          </div>
        </div>
        <ColumnNewRedux priceRate={priceRate} category={category ? category : false} query={query} trigger={trigger} tagIds={tagIds} utilityIds={utilityIds} orderBy={orderBy} isNotSold={isNotSold} />
      </section>
      <Footer />
    </div>
  )
}

export default Explore
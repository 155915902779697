import React, { useState } from 'react'
import { Typography, Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { useTranslation } from 'react-i18next';

import useStyles from "../../styles/components/Servererror/modalError"

function ModalNoMetamask({open, onClose}) {
    const classes=useStyles();
    const {t}=useTranslation();
  return (
    <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    className='alert-modal'
  >
      <div className={classes.root}>
        <div className={classes.topBlock}>
            <HighlightOffIcon className={classes.icon} onClick={() => onClose()} />
        </div>  
        <div className={classes.bottomBlock}>
            <Typography className={classes.supTitle}>
                In order to use this application, you need to install Metamask wallet.
            </Typography>
            <Button
                variant="contained"
                color="secondary"
                className={classes.button}
                onClick={() => {
                    var win = window.open('https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en', '_blank');
                    win.focus();
                }}
            >
                Install Metamask
            </Button>
        </div>
      </div>
  </Dialog>
  )
}

export default ModalNoMetamask;
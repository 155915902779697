import React, { useEffect, useState } from 'react'
import { Typography,Button,TextField, FormHelperText  } from '@material-ui/core';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import * as actions from '../../store/actions/thunks';
import * as AuthService from "../../services/AuthService";

import useStyles from "../../styles/pages/Modals/codeVerification"
import { EPATHS } from '../../routes/paths.enum';

interface IModalCodeVerification{
    open:boolean;
    onClose:()=>void,
    formData: any,
    isRegister: boolean
}

const serverURL = process.env.REACT_APP_SERVER;


function CodeVerification({open,onClose,formData, isRegister}:IModalCodeVerification) {
    const classes=useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const {t}=useTranslation();
    const [input, setInput] = useState("")
    const [isNotMatched, setIsNotMatched] = useState(false)
    const [isEmailVerified, setEmailVerified] = useState(false);
    const [isResent, setIsResent] = useState(false);
    
    const handleVerifiyEmail = async () => {
        const data = {
            metamask: formData.get('metamask'),
            code: input.toString().trim(),
            isRegister: isRegister
        }
        try {
            const res = await AuthService.verify(data);
            setIsNotMatched(false)
            setEmailVerified(true);
            localStorage.setItem('_user', JSON.stringify(res));
            dispatch(actions.setUser(res));
        } catch(err) {
            setIsNotMatched(true)
        }
    }

    useEffect(() => {
        setIsResent(false);
    }, [open])

    const confirmRegister = async() => {
        onClose();
        // history.push({pathname:EPATHS.EXPLORE});
        setEmailVerified(false);
        window.location.href = '/';
    }

    const handleResend = async () => {
        const stroageUser = localStorage.getItem('_user') === null ? '{}' : localStorage.getItem('_user') + '';
        await AuthService.resend({metamask: JSON.parse(stroageUser).metamask});
        setIsResent(true);
    }

  return (
    <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    className='alert-modal'
  >
      <div className={classes.root}>
        <div className={classes.topBlock}>
            <CheckCircleOutlinedIcon className={classes.icon} />
        </div>  
        <div className={classes.bottomBlock}>
            <Typography className={classes.title}>
                {t("WelcomeToEarniverse")}
            </Typography>
            {isEmailVerified ?
                <React.Fragment>
                    <Button
                        variant="contained"
                        color="inherit"
                        className={classes.buttonResend}
                        onClick={confirmRegister}
                    >
                        Continue
                    </Button>
                    </React.Fragment>
                :
                    <React.Fragment>
                        <Typography className={classes.supTitle}>
                            {t("VerifyEmail")}
                        </Typography>
                        <Typography className={classes.supText}>
                        Please check your spam Inbox and Whitelist the domain to make sure to receive future communications from Earniverse
                        </Typography>
                        <TextField
                            label="Code"
                            id="outlined-size-small"
                            variant="outlined"
                            size="small"
                            className={classes.input}
                            onChange={(e)=>setInput(e.target.value)}
                            />
                        {isNotMatched && <FormHelperText className={classes.textHelper}>{t("CodeVerification")}</FormHelperText>}
                        <div>
                            <Button
                                variant="contained"
                                color="inherit"
                                className={classes.buttonResend}
                                onClick={handleResend}
                            >
                                {t("Resend")}
                            </Button>
                            <Button
                                variant="contained"
                                color="inherit"
                                className={classes.button}
                                onClick={handleVerifiyEmail}
                            >
                                {t("Verify")}
                            </Button>
                        </div>
                        {isResent && <p className={classes.confirmVerification}>A new email verification has been sent to your email address. Make sure to enter verification code from the latest email received</p>}
                </React.Fragment>
            }
        </div>
    </div>
</Dialog>
)
}

export default CodeVerification
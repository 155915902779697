import * as React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import { ListSubheader, TextField, InputAdornment } from '@material-ui/core';
import { Search } from '@material-ui/icons';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: '50%',
      width: 250,
    },
  },
};

const containsText = (text, searchText) =>
  text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

export default function UtilitiesDropDownFilter({utilities, utilityIds, setUtilityIds}) {
  const [searchText, setSearchText] = React.useState('');

  const displayedUtilities = React.useMemo(
    () => utilities.filter((utility) => containsText(utility.name, searchText)),
    [searchText]
  );

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setUtilityIds(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const getUtilityNameList = (selectedIds) => {
    let names = [];
    for(let i=0; i<selectedIds.length; i++) {
      if(utilities.find(utility => utility.id === selectedIds[i]))
        names.push(utilities.find(utility => utility.id === selectedIds[i]).name);
    }
    return names.join(', ');
  }

  return (
    <div>
      <FormControl size="small">
        <Select
          multiple
          variant="outlined"
          value={utilityIds}
          onChange={handleChange}
          renderValue={(selected) => getUtilityNameList(selected)}
          MenuProps={MenuProps}
          style={styles.utilitySelector}
        >
          <ListSubheader style={{marginTop: 10, background: 'white', lineHeight: '30px'}}>
            <TextField
              size="small"
              // Autofocus on textfield
              autoFocus
              placeholder="Type to search..."
              fullWidth
              variant='outlined'
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
                style: {
                  height: 30
                }
              }}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key !== "Escape") {
                  // Prevents autoselecting item while typing (default Select behaviour)
                  e.stopPropagation();
                }
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
              style={{height: 30}}
            />
          </ListSubheader>
          {(searchText ? displayedUtilities : utilities).map((utility) => (
            <MenuItem key={utility.id} value={utility.id} style={{paddingBottom: 0, paddingTop: 0}}>
              <Checkbox color="primary" checked={utilityIds.indexOf(utility.id) > -1} style={{paddingBottom: 0, paddingTop: 0}} />
              <ListItemText primary={<span style={{fontSize: 14}}>{utility.name}</span>} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

const styles = {
  utilitySelector: {
      width: 200,
      marginRight: 10,
      height: 36
  },
  button: {
    marginTop: 20
  },
  newUtilityInput: {
    marginTop: 20
  }
}
import { makeStyles, Theme } from '@material-ui/core';
import { ECOLORS } from '../../../utils/constants/colors.enum';

export default makeStyles((theme: Theme) => ({ 
    root:{
        display:"flex",
        justifyContent:"space-between",
        alignItems:"center",
        overflow:"hidden",
        padding:"0px 13vw 5px 13vw",
        [theme.breakpoints.down(1500)]: {
            padding:"0px 2vw 5px 2vw",
        }, 
        "& ul":{
            display:"flex"
        },
        "& li":{
            textTransform:"capitalize",
            fontFamily:"Inconsolata,Consolas,Droid Sans Mono,Monaco,Courier New,monospace",
            fontSize:14,
            cursor: "pointer",
            color:ECOLORS.PRIMARY_DARK,
            padding:"0px 0.5vw 0px 0.5vw",
            listStyleType:"none",
            [theme.breakpoints.down('md')]: {
                fontSize:12,
            },
            [theme.breakpoints.down(870)]: {
              fontSize:11,
              padding:"0px 0.2vw 0px 0.2vw",
            } 
          },
    },
    changeLg:{
        marginRight:30,
        textTransform:"capitalize",
            fontFamily:"Inconsolata,Consolas,Droid Sans Mono,Monaco,Courier New,monospace",
            fontSize:14,
            cursor: "pointer",
            color:ECOLORS.PRIMARY_DARK,
            padding:"0px 0.5vw 0px 0.5vw",
            listStyleType:"none",
            [theme.breakpoints.down('md')]: {
                fontSize:12,
            },
            [theme.breakpoints.down(870)]: {
              fontSize:11,
              padding:"0px 0.2vw 0px 0.2vw",
            } 
    },
    flag:{
        height:15,
        width:15,
        marginRight:5
    }
 }));
import { getType } from 'typesafe-actions';
import * as actions from '../actions';
import { initEntityState, entityLoadingStarted, entityLoadingSucceeded, entityLoadingFailed } from '../utils';

export const defaultState = {
  notifications: initEntityState(null),
};

const states = (state = defaultState, action) => {
  switch (action.type) {
    case getType(actions.fetchNotifications.request):
      return { ...state, notifications: entityLoadingStarted(state.notifications, action.payload) };
    case getType(actions.fetchNotifications.success):
      return { ...state, notifications: entityLoadingSucceeded(state.notifications, action.payload) };
    case getType(actions.fetchNotifications.failure):
      return { ...state, notifications: entityLoadingFailed(state.notifications) };
    
    default:
      return state;
  }
};

export default states;

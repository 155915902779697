import { 
    createAction as action, 
    createAsyncAction as asyncAction 
} from 'typesafe-actions';

export const getAssetBreakdown = asyncAction(
    'nft/GET_ASSET_BREAKDOWN',
    'nft/GET_ASSET_BREAKDOWN_SUCCESS',
    'nft/GET_ASSET_BREAKDOWN_FAIL'
)();

export const getNftLikeBreakdown = asyncAction(
    'nft/GET_NFT_LIKE_BREAKDOWN',
    'nft/GET_NFT_LIKE_BREAKDOWN_SUCCESS',
    'nft/GET_NFT_LIKE_BREAKDOWN_FAIL'
)();

export const getAssetLikeBreakdown = asyncAction(
    'nft/GET_ASSET_LIKE_BREAKDOWN',
    'nft/GET_ASSET_LIKE_BREAKDOWN_SUCCESS',
    'nft/GET_ASSET_LIKE_BREAKDOWN_FAIL'
)();

export const getTileLikeBreakdown = asyncAction(
    'nft/GET_TILE_LIKE_BREAKDOWN',
    'nft/GET_TILE_LIKE_BREAKDOWN_SUCCESS',
    'nft/GET_TILE_LIKE_BREAKDOWN_FAIL'
)();


export const getNftWishlistBreakdown = asyncAction(
    'nft/GET_NFT_WISHLIST_BREAKDOWN',
    'nft/GET_NFT_WISHLIST_BREAKDOWN_SUCCESS',
    'nft/GET_NFT_WISHLIST_BREAKDOWN_FAIL'
)();

export const getAssetWishlistBreakdown = asyncAction(
    'nft/GET_ASSET_WISHLIST_BREAKDOWN',
    'nft/GET_ASSET_WISHLIST_BREAKDOWN_SUCCESS',
    'nft/GET_ASSET_WISHLIST_BREAKDOWN_FAIL'
)();

export const getTileWishlistBreakdown = asyncAction(
    'nft/GET_TILE_WISHLIST_BREAKDOWN',
    'nft/GET_TILE_WISHLIST_BREAKDOWN_SUCCESS',
    'nft/GET_TILE_WISHLIST_BREAKDOWN_FAIL'
)();

export const getNftBreakdown = asyncAction(
    'nft/GET_NFT_BREAKDOWN',
    'nft/GET_NFT_BREAKDOWN_SUCCESS',
    'nft/GET_NFT_BREAKDOWN_FAIL'
)();

export const getTileBreakdown = asyncAction(
    'nft/GET_TILE_BREAKDOWN',
    'nft/GET_TILE_BREAKDOWN_SUCCESS',
    'nft/GET_TILE_BREAKDOWN_FAIL'
)();

export const getTileGroupBreakdown = asyncAction(
    'nft/GET_TILE_GROUP_BREAKDOWN',
    'nft/GET_TILE_GROUP_BREAKDOWN_SUCCESS',
    'nft/GET_TILE_GROUP_BREAKDOWN_FAIL'
)();

export const getNftShowcase = asyncAction(
    'nft/GET_NFT_SHOWCASE',
    'nft/GET_NFT_SHOWCASE_SUCCESS',
    'nft/GET_NFT_SHOWCASE_FAIL'
)();

export const getAssetDetail = asyncAction(
    'nft/GET_ASSET_DETAIL',
    'nft/GET_ASSET_DETAIL_SUCCESS',
    'nft/GET_ASSET_DETAIL_FAIL'
)();

export const getNftDetail = asyncAction(
    'nft/GET_NFT_DETAIL',
    'nft/GET_NFT_DETAIL_SUCCESS',
    'nft/GET_NFT_DETAIL_FAIL'
)();

export const getTileDetail = asyncAction(
    'nft/GET_TILE_DETAIL',
    'nft/GET_TILE_DETAIL_SUCCESS',
    'nft/GET_TILE_DETAIL_FAIL'
)();

export const getTileGroupDetail = asyncAction(
    'nft/GET_TILE_GROUP_DETAIL',
    'nft/GET_TILE_GROUP_DETAIL_SUCCESS',
    'nft/GET_TILE_GROUP_DETAIL_FAIL'
)();

export const getHotCollections = asyncAction(
    'nft/GET_HOT_COLLECTIONS',
    'nft/GET_HOT_COLLECTIONS_SUCCESS',
    'nft/GET_HOT_COLLECTIONS_FAIL'
)();

export const getAuthorList = asyncAction(
    'nft/GET_AUTHOR_LIST',
    'nft/GET_AUTHOR_LIST_SUCCESS',
    'nft/GET_AUTHOR_LIST_FAIL'
)();

export const getCurrentAuthor = asyncAction(
    'nft/GET_CURRENT_AUTHOR',
    'nft/GET_CURRENT_AUTHOR_SUCCESS',
    'nft/GET_CURRENT_AUTHOR_FAIL'
)();

export const getAuthorRanking = asyncAction(
    'nft/GET_AUTHOR_RANKING',
    'nft/GET_AUTHOR_RANKING_SUCCESS',
    'nft/GET_AUTHOR_RANKING_FAIL'
)();

export const getBlogPosts = asyncAction(
    'nft/GET_BLOG_POSTS',
    'nft/GET_BLOG_POSTS_SUCCESS',
    'nft/GET_BLOG_POSTS_FAIL'
)();

export const getRecentPosts = asyncAction(
    'nft/GET_RECENT_POSTS',
    'nft/GET_RECENT_POSTS_SUCCESS',
    'nft/GET_RECENT_POSTS_FAIL'
)();

export const getTags = asyncAction(
    'nft/GET_TAGS',
    'nft/GET_TAGS_SUCCESS',
    'nft/GET_TAGS_FAIL'
)();

export const getComments = asyncAction(
    'nft/GET_COMMENTS',
    'nft/GET_COMMENTS_SUCCESS',
    'nft/GET_COMMENTS_FAIL'
)();

export const fetchNotifications = asyncAction(
    'nft/FETCH_NOTIFICATIONS',
    'nft/FETCH_NOTIFICATIONS_SUCCESS',
    'nft/FETCH_NOTIFICATIONS_FAIL'
)();

export const setLoading = action('nft/SET_LOADING')();
export const setShowRegisterModal = action('nft/SET_REGISTER_MODAL')();

export const clearAssets = action('nft/CLEAR_ALL_ASSETS')();
export const clearNfts = action('nft/CLEAR_ALL_NFTS')();
export const clearNftLikes = action('nft/CLEAR_ALL_NFT_LIKES')();
export const clearAssetLikes = action('nft/CLEAR_ALL_ASSET_LIKES')();
export const clearTileLikes = action('nft/CLEAR_ALL_TILE_LIKES')();
export const clearNftWishlists = action('nft/CLEAR_ALL_NFT_WISHLISTS')();
export const clearAssetWishlists = action('nft/CLEAR_ALL_ASSET_WISHLISTS')();
export const clearTileWishlists = action('nft/CLEAR_ALL_TILE_WISHLISTS')();
export const clearTiles = action('nft/CLEAR_ALL_TILES')();
export const clearTileGroups = action('nft/CLEAR_ALL_TILE_GROUPS')();
export const clearFilter = action('nft/CLEAR_FILTER')();
export const filterCategories = action('nft/FILTER_CATEGORIES')();
export const filterStatus = action('nft/FILTER_STATUS')();
export const filterItemsType = action('nft/FILTER_ITEMS_TYPE')();
export const filterCollections = action('nft/FILTER_COLLECTIONS')();
export const filterNftTitle = action('nft/FILTER_NFT_TITLE')();
export const showLoadMoreAssets = action('nft/SHOW_LOAD_MORE_ASSET')();
export const showLoadMoreNFTs = action('nft/SHOW_LOAD_MORE_NFT')();
export const showLoadMoreNFTLike = action('nft/SHOW_LOAD_MORE_NFT_LIKE')();
export const showLoadMoreAssetLike = action('nft/SHOW_LOAD_MORE_ASSET_LIKE')();
export const showLoadMoreTileLike = action('nft/SHOW_LOAD_MORE_TILE_LIKE')();
export const showLoadMoreNFTWishlist = action('nft/SHOW_LOAD_MORE_NFT_WISHLIST')();
export const showLoadMoreAssetWishlist = action('nft/SHOW_LOAD_MORE_ASSET_WISHLIST')();
export const showLoadMoreTileWishlist = action('nft/SHOW_LOAD_MORE_TILE_WISHLIST')();
export const showLoadMoreTiles = action('nft/SHOW_LOAD_MORE_TILE')();
export const showLoadMoreTileGroups = action('nft/SHOW_LOAD_MORE_TILE_GROUP')();
export const setNFTCount = action('nft/SET_NFT_COUNT')();
export const setNFTLikeCount = action('nft/SET_NFT_LIKE_COUNT')();
export const setAssetLikeCount = action('nft/SET_ASSET_LIKE_COUNT')();
export const setTileLikeCount = action('nft/SET_TILE_LIKE_COUNT')();
export const setNFTWishlistCount = action('nft/SET_NFT_WISHLIST_COUNT')();
export const setAssetWishlistCount = action('nft/SET_ASSET_WISHLIST_COUNT')();
export const setTileWishlistCount = action('nft/SET_TILE_WISHLIST_COUNT')();
export const setAssetCount = action('nft/SET_ASSET_COUNT')();
export const setTileCount = action('nft/SET_TILE_COUNT')();
export const setTileGroupCount = action('nft/SET_TILE_GROUP_COUNT')();
export const setBalance = action('nft/GET_BALANCE')();
export const setContinent = action('nft/SET_CONTINENT')();
export const setUser = action('nft/SET_USER')();
export const setIsTimeout = action('nft/SET_IS_TIMEOUT')();
import React, { memo, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Pagination from '@material-ui/lab/Pagination';
import { Moralis } from 'moralis';
import { useTranslation } from 'react-i18next';
import api from "../../../../core/api";
import Footer from '../../components/footer';
import { StyledHeader } from '../../Styles';
import useStyles from '../styles/Authors/authors';
import { 
  Typography,
  Dialog,
  Snackbar,
  Select,
  MenuItem } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import authHeader from "../../../../services/auth-header";
import axios from 'axios';
import { fetchAuthorList } from "../../../../store/actions/thunks";

import * as selectors from '../../../../store/selectors';
import * as DataService from "../../../../services/DataService";
import Notification from "../../../../utils/functions/notification";
import Header from '../../menu/header';

//SWITCH VARIABLE FOR PAGE STYLE
const theme = 'GREY'; //LIGHT, GREY, RETRO

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function UsersList() {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [indexPage, setIndexPage] = useState(0);
  const [selectedPage, setSelectedPage] = useState(1);
  const [isChecked, setIsChecked] = useState(true);
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedUserInfo, setSelectedUserInfo] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [roles, setRoles] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedRole, setSelectedRole] = useState();
  const startPageIndex = indexPage * 5;
  const endPageIndex = indexPage * 5 + 5;
  const serverURL = process.env.REACT_APP_SERVER;

  const authorsState = useSelector(selectors.authorsState);
  const authors = authorsState.data ? authorsState.data : [];

  const getAllRoles = async () => {
    const result = await DataService.fetchRoles();
    setRoles(result);
  }
  useEffect(() => {
    getAllRoles();
    dispatch(fetchAuthorList());
    // eslint-disable-next-line
  }, []);

  const handleSelectPage = (event, newPage) => {
    setSelectedPage(newPage);
    setIndexPage(newPage - 1);
  };

  // const handleRedirectToCreateProfile = () => {
  //   history.push({ pathname: EPATHS.CREATE_PROFILE });
  // };

  const findAndSetSelectedRole = (role, type) => {
    const currentRole = type === 'name' ?  roles.find(element => element.name === role) : roles.find(element => element.id === role);
    setSelectedRole(currentRole);
  }
  
  const handleUpdateRoleUser = async() => {
        try {
          const result = await Moralis.Cloud.run('getUserById',{selectedUser});
          setSelectedUserInfo(result)
          findAndSetSelectedRole(result[0]?.attributes?.role, 'name');
          setIsModalOpen(true)
        } catch (error) {
            console.log("error ===>",error)
        } 
  };

 const handleEditRole=async()=>{
    try {
      const role= selectedRole.name
      await Moralis.Cloud.run('updateUserById',{selectedUser,role});
      axios.put(`${serverURL}/data/user/${selectedUserInfo[0].get("accounts")}`, {
        roleId: selectedRole.id
      }, { headers: authHeader() });
      dispatch(fetchAuthorList());
      setIsModalOpen(false)
      setOpen(true);
    } catch (error) {
      console.log("error ===>",error)
    }
  }

  const filterMetamaskAdress = (event) => {
    if (event.target.value.length !== 0) {
      // eslint-disable-next-line
      const response = authors.filter((_user) => {
        if (_user.attributes?.accounts) {
          // console.log("_user ====>",_user)
          return _user
            .get('accounts')
            .toString()
            .toLowerCase()
            .includes(event.target.value.toLowerCase());
        }
      });
    } else {
      alert('here!')
    }
  };

  const handleChangeSelectUser=(e)=>{
    setSelectedUser(e.target.value)
    if(e.target.value.length!==0){
      setIsChecked(false)
    }  
  }
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <div>
      <StyledHeader theme={theme} />
      <Header className="" />
      <section
        className='jumbotron breadcumb no-bg'
        style={{ backgroundImage: `url(${'./img/background/subheader.jpg'})` }}
      >
        <div className='mainbreadcumb'>
          <div className='container'>
            <div className='row m-10-hor'>
              <div className='col-12'>
                <h1 className='text-center'>{t('ProfileManagement')}</h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='d_profile de-flex'>
              <div className='de-flex-col' />
              <div className='profile_follow de-flex'>
                <div
                  className='row form-dark'
                  id='form_quick_search'
                  name='form_quick_search'
                >
                  <div className='col'>
                    <input
                      className='form-control'
                      id='name_1'
                      name='name_1'
                      placeholder='Search by wallet address'
                      type='text'
                      onChange={filterMetamaskAdress}
                    />
                    <button id='btn-submit'>
                      <i className='fa fa-search bg-color-secondary'></i>
                    </button>
                    <div className='clearfix'></div>
                  </div>
                </div>
                <div
                  className='de-flex-col'
                  style={{ marginTop: 10 }}
                  onClick={handleUpdateRoleUser}
                >
                  {/* <span className="btn-main">{t("CreateProfile")}</span> */}
                  {/* <span className='btn-main' >Edit Role</span> */}
                  <button className={isChecked?'btn-main-disabled':'btn-main'} disabled={isChecked}>Edit Role</button>
                </div>
              </div>
            </div>
            <table className='table de-table table-rank'>
              <thead>
                <tr>
                  <th scope='col'></th>
                  <th scope='col'>{t('Username')}</th>
                  <th scope='col'>{t('Role')}</th>
                  <th scope='col'>{t('MetamaskAddress')}</th>
                  <th scope='col'>{t('Email')}</th>
                  {/* <th scope='col'>{t('Status')}</th> */}
                </tr>
                <tr></tr>
              </thead>
              <tbody>
                {authors &&
                  authors
                    .slice(startPageIndex, endPageIndex)
                    .map((user, index) => (
                      <tr key={index}>
                        <td>
                          <input
                            className='form-check-input'
                            type='radio'
                            onChange={(e) =>
                              handleChangeSelectUser(e)
                            }
                            name='radio_box'
                            value={user.id}
                            checked={user.id===selectedUser}
                          />
                        </td>
                        <th scope='row'>
                          <div className='coll_list_pp'>
                            { user.picture ?
                              <img className="lazy" src={api.ipfsURL + user.picture} alt=""/>
                              : <img className="lazy" src="../../img/author/author-11.png" alt=""/>
                            }
                            <i className='fa fa-check'></i>
                          </div>
                          {user.username}
                        </th>
                        <td>{user.roleId ? user.roleId : '-'}</td>
                        <td>
                          {user.metamask
                            ? `${user
                                .metamask
                                .toString()
                                .substring(0, 14)}...`
                            : '-'}
                        </td>
                        <td>{user.email ? user.email : '-'}</td>
                        {/* <td
                          className={
                            user.verified === true ? 'd-plus' : 'd-min'
                          }
                        >
                          {user.verified === true
                            ? 'Verified'
                            : 'Not Verified'}
                        </td> */}
                      </tr>
                    ))}
              </tbody>
            </table>
            {Math.ceil(authors.length / 5) > 1 && (
              <div className={classes.root}>
                <Pagination
                  page={selectedPage}
                  count={Math.ceil(authors.length / 5)}
                  onChange={handleSelectPage}
                  variant='outlined'
                  shape='rounded'
                />
              </div>
            )}
            <div className='spacer-double'></div>
          </div>
        </div>
      </section>
      <Footer />
      <Dialog
        open={isModalOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className='alert-modal'
      >
        <div className={classes.rootModal}>
        <div className={classes.topBlock}>
            <ErrorOutlineIcon className={classes.icon} />
        </div>  
        <div className={classes.bottomBlock}>
            <div>
              <span><strong>Role:  </strong></span>
              <Select
                className={classes.rolesDropdown}
                id="demo-simple-select"
                value={selectedRole?.id}
                onChange={(e) => {
                  findAndSetSelectedRole(e.target.value, 'id') 
                }}
              >
                {roles.map((role, index) => (<MenuItem key={index} value={role.id}>{role.name}</MenuItem>))}
              </Select>
            </div>
            <Typography className={classes.title}>
                {t("Attention")}
            </Typography>
            <Typography className={classes.supTitle}>         
                   {t("EditRoleText")} : <span style={{color:"red"}}>{selectedUserInfo && selectedUserInfo[0].attributes?.accounts && selectedUserInfo[0].get("accounts")}</span> {selectedRole?.name} ?!
            </Typography>
            <div className={classes.buttonBlock}>
              <button
                  className="btn-main-cancel"
                  onClick={()=>setIsModalOpen(false)}
              >
              {t("Cancel")} 
              </button>
              <button
                  className="btn-main"
                  onClick={handleEditRole}
              >
                  {t("Edit")}
              </button>
            </div>
        </div>
      </div>
    </Dialog>
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
        {t("RoleEditedSuccessfully")}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default memo(UsersList);

import { useState } from 'react';
import Footer from '../components/footer';
import { StyledHeader } from '../Styles';
import Header from "../menu/header";
import { debounce } from 'lodash';
import { fetchEmail } from '../../../services/DataService';
import * as AuthService from "../../../services/AuthService";

const theme = 'GREY';

const EMAIL_ERROR = 'This email does not exist.';

function ResetPassword() {
  const [email, setEmail] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const checkEmailValidation = (value) => {
    if(value) {
      setErrorMessage(null);
      try {
        fetchEmail(value).then(res => {
          if(!res) {
            setErrorMessage(EMAIL_ERROR);
          }
          else {
            setErrorMessage(null);
          }
        }).catch(error => {     
          let msg = (typeof error === 'object') ? error.message : error;
          setErrorMessage(msg);
        });
      }
      catch (error) {    
        let msg = (typeof error === 'object') ? error.message : error;
        setErrorMessage(msg);
      }
    }
  }

  const [debounceCheckEmailValidation] = useState(() => debounce(checkEmailValidation, 1000));

  const resetPassword = async () => {
    try {
      await AuthService.resetPassword({email: email});
      setEmailSent(true);
    }
    catch(error) {
      let msg = (typeof error === 'object') ? error.message : error;
      setErrorMessage(msg)
    }
  }

  return (
    <div>
      {/* <GlobalStyles/> */}
      <StyledHeader theme={theme} />
      <Header className=""/>
      <section className='jumbotron breadcumb no-bg' style={{backgroundImage: `url(${'./img/background/subheader.jpg'})`}}>
        <div className='mainbreadcumb'>
          <div className='container'>
            <div className='row'>
              <div className="col-md-12 text-center">
                  <h1>Reset Password</h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='container'>
        {emailSent ? <div className="row">
          <div className="col-md-6 offset-md-3">
              <h3>Email Sent!</h3>
              <p className='success-input'>A reset password link has been sent to your email, make sure to follow that link to reset your password</p>
          </div>
        </div> 
        : <div className="row">
          <div className="col-md-6 offset-md-3">
              <h3>Enter your email</h3>
              <div className="field-set">
                <input type='email'
                  value={email}
                  className="form-control"
                  placeholder="Enter your email"
                  onChange={(e) => { setEmail(e.target.value); debounceCheckEmailValidation(e.target.value)}}/>
              </div>
              {
                errorMessage && <div className="col-md-12">
                  <p className='error-input'>{errorMessage}</p>
                </div>
              }

              <div id='submit'>
                <button className="btn-main" onClick={() => resetPassword()}>Submit</button>
              </div>

          </div>
        </div>}
      </section>

      <Footer />
    </div>
  )
};

export default ResetPassword;
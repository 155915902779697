import { useState } from 'react';
import Footer from '../components/footer';
import { StyledHeader } from '../Styles';
import { useHistory } from 'react-router';
import { ReactNotifications } from 'react-notifications-component';
import { useLocation } from 'react-router-dom';
import Header from "../menu/header";
import LoadingOverlay from 'react-loading-overlay-ts';
import { EPATHS } from '../../../routes/paths.enum';

import * as AuthService from "../../../services/AuthService";
import Notification from '../../../utils/functions/notification';

import 'react-notifications-component/dist/theme.css';

const theme = 'GREY';

function ConfirmPassword() {
  const history=useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  
  const token = queryParams.get("token")

  const [isActive, setActive] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);

  const handleSetPassword = async () => {
    setActive(true);
    try {
      await AuthService.confirmPassword({
        token: token,
        password: password
      });
      setActive(false);
      Notification(`Your Password has been changed.`, true);
      history.push({pathname: EPATHS.MY_PROFILE});
    }
    catch(e) {
      setErrorMessage(e.message);
      setActive(false);
    }
  }

  return (
    <div>
      <LoadingOverlay
        style={{zIndex: 3000}}
        active={isActive}
        spinner
        text="Please wait while updating your password">
        <StyledHeader theme={theme} />
        <Header className=""/>
        <ReactNotifications />
        <section className='jumbotron breadcumb no-bg' style={{backgroundImage: `url(${'./img/background/subheader.jpg'})`}}>
          <div className='mainbreadcumb'>
            <div className='container'>
              <div className='row'>
                <div className="col-md-12 text-center">
                    <h1>Update Your Password</h1>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className='container'>
          <div className="row">
            <div className="col-md-6 offset-md-3">
                <h3>Enter your password</h3>
                <div className="field-set">
                  <label>New password</label>
                  <input type='password'
                    value={password}
                    className="form-control"
                    placeholder="Enter your password"
                    onChange={(e) => {
                      setPassword(e.target.value);
                      if (e.target.value !== confirmPassword) {
                        setErrorMessage("Passwords do not match.");
                      } else {
                        setErrorMessage(null);
                      }
                    }}/>
                  <p className='password-input'>Password should be at least 8 characters long and contain at least one symbol, one high caps letter and one number.</p>
                </div>
                <div className="field-set">
                <label>Confirm New Password</label>
                  <input type='password'
                    value={confirmPassword}
                    className="form-control"
                    placeholder="Confirm your password"
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                      if (e.target.value !== password) {
                        setErrorMessage("Passwords do not match.");
                      } else {
                        setErrorMessage(null);
                      }
                    }}/>
                </div>
                {
                  errorMessage && <div className="col-md-12">
                    <p className='error-input'>{errorMessage}</p>
                  </div>
                }

                <div id='submit'>
                  <button className="btn-main" onClick={() => handleSetPassword()} disabled={password !== confirmPassword}>Submit</button>
                </div>

            </div>
          </div>
        </section>
        <Footer />
      </LoadingOverlay>
    </div>
  )
};

export default ConfirmPassword;
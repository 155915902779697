import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ColumnNftRedux from '../../components/ColumnNftRedux';
import ColumnTileRedux from '../../components/ColumnTileRedux';
import ColumnTileGroupRedux from '../../components/ColumnTileGroupRedux';
import Footer from '../../components/footer';
import { StyledHeader } from '../../Styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import * as selectors from '../../../../store/selectors';
import { useSelector } from 'react-redux';
import { TextField } from '@material-ui/core';
import Header from "../../menu/header";
import * as DataService from "../../../../services/DataService";

const theme = 'GREY'; //LIGHT, GREY, RETRO

function MyNFTs() {
    const [priceRate, setPriceRate] = useState(0);
    const [type, setType] = useState(0);
    const [isForSale, setIsForSale] = useState(false);
    const [continents, setContinents] = useState([]);
    const [posX, setPosX] = useState();
    const [posY, setPosY] = useState();
    const [posZ, setPosZ] = useState();
    const [trigger, setTrigger] = useState(false);
    const [continent, setContinent] = useState(0);
    const signedUser = useSelector(selectors.user);

    useEffect(() => {
        if (signedUser.id) {
            // DataService.fetchExchangeData().then((res => {
            //     setPriceRate(res);
            // }));

            DataService.fetchContinents().then((res => {
                //Hide Earnicity contient value.
                setContinents(res.filter(c => c.name !== 'Earnicity'));
            }))
        }
    }, [signedUser.id])

    const { t } = useTranslation();
    return (
        <div>
            <StyledHeader theme={theme} />
            <Header className=""/>
            <section className='jumbotron breadcumb no-bg' style={{backgroundImage: `url(${'./img/background/subheader.jpg'})`}}>
                <div className='mainbreadcumb'>
                    <div className='container'>
                        <div className='row m-10-hor'>
                            <div className='col-12'>
                                <h1 className='text-center'>My NFTs</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='container'>
            <div className='row'>
                <div className='col-lg-12' style={{display: 'flex', alignItems: 'center', marginBottom: 20}}>
                    <Select
                        value={type}
                        onChange={(e) => setType(e.target.value)}
                        variant="outlined"
                        style={{minWidth: 200}}
                        >
                        <MenuItem value={0}>3D Assets</MenuItem>
                        <MenuItem value={1}>Single Land</MenuItem>
                        <MenuItem value={2}>Pack of Lands</MenuItem>
                    </Select>
                    {(type === 1 || type === 2) && <Select
                        className='ml-20'
                        value={continent}
                        onChange={(e) => setContinent(e.target.value)}
                        variant="outlined"
                        style={{minWidth: 200}}
                    >
                        <MenuItem key={0} value={0}>All Continents</MenuItem>
                        {continents.map((item, i) => (
                        <MenuItem key={i} value={item.id}>{item.name}</MenuItem>
                        ))}
                    </Select>}
                    {
                        type === 1 && <React.Fragment>
                        <TextField
                            className='ml-20'
                            id="outlined-number"
                            type="number"
                            variant="outlined"
                            placeholder='X pos'
                            value={posX}
                            onChange={(e) => setPosX(e.target.value)}
                            style={{width: 150}}
                        />
                        <TextField
                            className='ml-20'
                            id="outlined-number"
                            type="number"
                            variant="outlined"
                            placeholder='Y pos'
                            value={posY}
                            onChange={(e) => setPosY(e.target.value)}
                            style={{width: 150}}
                        />
                        <TextField
                            className='ml-20'
                            id="outlined-number"
                            type="number"
                            variant="outlined"
                            placeholder='Z pos'
                            value={posZ}
                            onChange={(e) => setPosZ(e.target.value)}
                            style={{width: 150}}
                        />
                        </React.Fragment>
                    }
                    {type === 1 && <span onClick={() => setTrigger(!trigger)} className="btn-main ml-20">{t("Filter")}</span>}
                </div>
            </div>
            <div className=''>
                <input
                className='form-check-input'
                type='checkbox'
                id='flexCheckDefault'
                checked={isForSale}
                onChange={() => setIsForSale(!isForSale)}
                />
                <label
                className='form-check-label-profile'
                htmlFor='flexCheckDefault'
                >
                    {"For Sale"}
                </label>
                <div className="spacer-20"></div>
            </div>
            {signedUser.id && <React.Fragment>
                { type === 0 && <ColumnNftRedux  priceRate={priceRate} authorId={signedUser.id} isForSale={isForSale}/> }
                { type === 1 && <ColumnTileRedux  priceRate={priceRate} authorId={signedUser.id} continent={continent} trigger={trigger} posX={posX} posY={posY} posZ={posZ} isForSale={isForSale}/> }
                { type === 2 && <ColumnTileGroupRedux  priceRate={priceRate} authorId={signedUser.id} continent={continent}  isForSale={isForSale}/> }
                </React.Fragment>
            }
        </section>
        <Footer />
    </div>
    )
}

export default MyNFTs
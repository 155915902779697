import { memo, useEffect, useState, useRef } from 'react';
import Web3 from "web3";
import styled from "styled-components";
import { useTranslation } from 'react-i18next';
import Clock from "./Clock";
import api from '../../../core/api';
import { NavLink, useHistory } from 'react-router-dom';
import Nft3dCard from '../../../components/Cards/nft3DCard';
import { Typography,Dialog,Button } from '@material-ui/core';
import useStyles from '../../../styles/components/Cards/nftCard';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import ModalNoMetamask from "../../../components/Servererror/modalNoMetamask";
import * as selectors from '../../../store/selectors';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../../store/actions/thunks';
import * as setActions from '../../../store/actions';
import { login, register } from '../../../utils/functions/authenticate';
import { setAssetLike,
    setAssetDislike,
    setAssetWishlist,
    setAssetDiswishlist
} from '../../../services/AssetService';
import SoldOutImg from '../../../assets/images/sold-out.png';
import groupImg from '../../../assets/images/group.png';
import { formatNumberWithSpaceSeparator } from '../../../utils/functions/utils';

const Outer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 8px;
`;

//react functional component
const AssetCard = ({priceRate, nft, className = 'd-item col-lg-3 col-md-6 col-sm-6 col-xs-12 mb-4', clockTop = true, height, onImgLoad, account }) => {
    const {t}=useTranslation();
    const history=useHistory();
    const classes=useStyles();
    const [previewType, setPreviewType] = useState('2d');
    const [countLike, setCountLike] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [render, setRender] = useState(false);
    const [actionType, setActionType] = useState();
    const [showAlertModal, setShowAlertModal] = useState(false);
    const [serverError, setServerError] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [limitDate, setLimitDate] = useState();
    const signedUser = useSelector(selectors.user);

    const isVoted = useRef(false);
    const isWishlisted = useRef(false);

    useEffect(() => {
        if(nft.Likes) {
            for(let i=0; i<nft.Likes.length; i++) {
                if(signedUser?.metamask === nft.Likes[i].User?.metamask?.toLowerCase()) {
                    isVoted.current = true;
                }
            }
        }

        if(nft.Wishlists) {
            for(let i=0; i<nft.Wishlists.length; i++) {
                if(signedUser?.metamask === nft.Wishlists[i].User?.metamask?.toLowerCase()) {
                    isWishlisted.current = true;
                }
            }
        }

        if(nft.Nfts && nft.Likes) {
            let totalLikes = nft.Likes.length;
            for(let i=0; i<nft.Nfts.length; i++) {
                totalLikes += nft.Nfts[i].NftLikes.length
            }
            setCountLike(totalLikes);
        }

        if(nft?.limitDate) {
            const dateParts = nft?.limitDate.split('-');
            const year = parseInt(dateParts[0]);
            const month = parseInt(dateParts[1]) - 1; // Month is 0-based in JavaScript
            const day = parseInt(dateParts[2]);
            const targetDate = new Date(year, month, day);
            setLimitDate(targetDate);
        }
    }, [nft]);

    useEffect(() => {
        setRender(!render);
    }, [isVoted.current, isWishlisted.current]);

    const dispatch = useDispatch();

    const handleRedirectToItem=(id)=>{
        history.push({pathname:`/3D_Asset/${id}`})
        localStorage.setItem("id",id)
    }

    const handleLike = async (userData) => {
        setActionType();
        if(nft.Likes) {
            for(let i=0; i<nft.Likes.length; i++) {
                if(userData.metamask === nft.Likes[i].User.metamask.toLowerCase()) {
                    isVoted.current = true;
                }
            }
        }

        if(!isVoted.current) {
            dispatch(setActions.setLoading(true));
            try {
                const res = await setAssetLike(nft?.id);
                let totalLikes = res.likes;
                for(let i=0; i<nft.Nfts.length; i++) {
                    totalLikes += nft.Nfts[i].NftLikes.length
                }
                setCountLike(totalLikes);
                setShowAlertModal(true);
                setAlertMessage('Your like has been added.');
                isVoted.current = true;
                dispatch(actions.fetchNotifications());
                dispatch(actions.fetchAssetLikeBreakdown(userData.id, 0));
            } catch (e) {
                alert(e);
            }
            dispatch(setActions.setLoading(false));
        }
        else {
            dispatch(setActions.setLoading(true));
            try {
                const res = await setAssetDislike(nft.id);
                let totalLikes = res.likes;
                for(let i=0; i<nft.Nfts.length; i++) {
                    totalLikes += nft.Nfts[i].NftLikes.length
                }
                setCountLike(totalLikes);
                dispatch(actions.fetchNotifications());
                setShowAlertModal(true);
                isVoted.current = false;
                setAlertMessage('Your like has been removed.');
                dispatch(actions.fetchAssetLikeBreakdown(userData.id, 0));
            } catch(e) {
                alert(e);
            }
            dispatch(setActions.setLoading(false));
        }
    }

    const onClickLike = () => {
        if(signedUser.id) {
            handleLike(signedUser);
        }
        else {
            setShowModal(true);
            setActionType('like');
        }
    }

    const onClickBasket = () => {
        if(signedUser.id) {
            handleWishlist(signedUser);
        }
        else {
            setShowModal(true);
            setActionType('wishlist');
        }
    }

    const handleWishlist = async (userData) => {
        setActionType();

        if(nft.Wishlists) {
            for(let i=0; i<nft.Wishlists.length; i++) {
                if(userData.metamask === nft.Wishlists[i].User.metamask.toLowerCase()) {
                    isWishlisted.current = true;
                }
            }
        }

        if(!isWishlisted.current) {
            dispatch(setActions.setLoading(true));
            try {
                const res = await setAssetWishlist(nft?.id);
                dispatch(actions.fetchNotifications());
                setShowAlertModal(true);
                isWishlisted.current = true;
                setAlertMessage('This Asset has been added to your wishlist.');
                dispatch(actions.fetchAssetWishlistBreakdown(userData.id, 0));
            } catch(e) {
                alert(e);
            }
            dispatch(setActions.setLoading(false));
        }
        else {
            dispatch(setActions.setLoading(true));
            try {
                const res = await setAssetDiswishlist(nft.id);
                dispatch(actions.fetchNotifications());
                setShowAlertModal(true);
                isWishlisted.current = false;
                setAlertMessage('This Asset has been removed from your wishlist.');
                dispatch(actions.fetchAssetWishlistBreakdown(userData.id, 0));
            } catch(e) {
                alert(e);
            }
            dispatch(setActions.setLoading(false));
        }
    }

    const handleAuth = async () => {
        const userData = await login(dispatch, setActions.setLoading, setActions.setUser, history, setServerError);
        setShowModal(false);
        if(userData && actionType === 'like') {
            handleLike(userData);
        }
        if(userData && actionType === 'wishlist') {
            handleWishlist(userData);
        }
    }

    return (
        <div className={className}>
            <div className="nft__item m-0">
            { nft.item_type === 'single_items' ? (
             <div className='icontype'><i className="fa fa-bookmark"></i></div>   
             ) : (  
             <div className={isWishlisted.current ? "icontype_selected" :"icontype"}><i className="fa fa-shopping-basket" onClick={() => onClickBasket()}/></div>
                )
            }
                { nft.deadline && clockTop &&
                    <div className="de_countdown">
                        <Clock deadline={nft.deadline} />
                    </div>
                }
                <div className="author_list_pp">
                {/* onClick={()=> navigateTo(nft.author_link)} */}
                    { nft.User && nft.User.picture ?
                        <NavLink to={`/author/${nft.User.id}`} >
                            <img className="lazy" src={nft.User ? `${api.baseUrl}/${nft.User.picture}` : ""} alt="" onClick={() => history.push({pathname:`/author/${nft.User.id}`})}/>
                            <i className="fa fa-check"></i>
                        </NavLink>
                        :
                        <NavLink to={`/author/${nft.User.id}`}>
                            <img className="lazy" src="" alt="" onClick={() => history.push({pathname:`/author/${nft.User.id}`})}/>
                        </NavLink>
                    }
                </div>
                {nft?.Nfts?.length > 0 && <div style={{color: '#A706AA', position: 'absolute', right: 25, top: 60}}><img src={groupImg} style={{width: 18}} alt="" /><span style={{marginLeft: 10, fontSize: 15, fontWeight: 600}}>{formatNumberWithSpaceSeparator(nft?.Nfts?.length)}</span></div>}
                <div className="nft__item_wrap" style={{height: `${height}px`, position: 'relative', marginLeft: 'auto', marginRight: 'auto'}}>
                <Outer>
                    <span style={{position: 'relative'}}>
                        { nft.gltf ?
                            (previewType === '3d' ? 
                                <Nft3dCard src={`${api.server3dnTech}/${nft.directory}/${nft.gltf}`} width={250} height={145} />
                                :
                                <NavLink to={`/3D_Asset/${nft.id}`}>
                                    <img onLoad={onImgLoad} src={api.server3dnTech + '/' + nft.directory + '/' + nft.directory + '.png'} className="lazy nft__item_preview" alt="" />
                                </NavLink>
                            )
                            :
                            <NavLink to={`/3D_Asset/${nft.id}`}>
                                <img onLoad={onImgLoad} src={api.ipfsURL + nft.cid} className="lazy nft__item_preview" alt="" />
                            </NavLink>
                        }
                        {((nft?.numberOfItems === 0 && nft?.limitDate && new Date() > limitDate) || (nft?.numberOfItems !== 0 && nft?.numberOfItems === nft?.AllNFT.length)) && <img src={SoldOutImg} style={{position: 'absolute', top: 0, left: 0, width: 80}}/>}
                    </span>
                </Outer>
                </div>
                { nft.deadline && !clockTop &&
                    <div className="de_countdown">
                        <Clock deadline={nft.deadline} />
                    </div>
                }
                <div className="nft__item_info">
                    {/* <span onClick={() => navigateTo(`${nft.nft_link}/${nft.id}`)}> */}
                    <NavLink to={`/3D_Asset/${nft.id}`}>
                        <span>
                            <h4>{nft.title}</h4>
                        </span>
                    </NavLink>
                    { nft.status === 'has_offers' &&
                        <div className="has_offers">
                            <NavLink to={`/3D_Asset/${nft.id}`}>
                                <p><span className='through'>{nft.priceover}</span> {Web3.utils.fromWei(nft.price, 'gwei') * 1000} USDC (1/30)</p>
                            </NavLink>
                        </div>}
                    { nft.status !== 'has_offers' &&
                        <div className="nft__item_price">
                            <NavLink to={`/3D_Asset/${nft.id}`}>
                                <p className={classes.cardPrice}>{Web3.utils.fromWei(nft.price, 'gwei') * 1000} USDC</p>
                            </NavLink>
                            {/* <p className={classes.cardPrice} onClick={() => handleRedirectToItem(nft.id)}>{(Web3.utils.fromWei(nft.price, 'ether') * priceRate * 1000).toFixed(2)} USDT</p> */}
                            <p className={classes.cardPrice}>NFT {nft?.numberOfItems === 0 ? (nft?.limitDate ? (new Date() < limitDate ? `Mints: Until ${limitDate?.getMonth() + 1}/${limitDate?.getDate()}/${limitDate?.getFullYear()}` : `Minted: ${nft?.AllNFT.length}`) : 'Mints: Unlimited' ) : `Remaining: ${nft?.numberOfItems - nft?.AllNFT.length} / ${nft?.numberOfItems}` }</p>

                            {/* {nft.numberOfItems ? <p className={classes.cardPrice}>NFT remaining: {nft.numberOfItems - nft.Nfts.length} / {nft.numberOfItems}</p> : <p className={classes.cardPrice}></p>} */}
                            { nft.status === 'on_auction' && 
                                <span>{nft.bid}/{nft.max_bid}</span>
                            }
                            <p className={classes.cardPrice}>{nft?.variable && nft?.variable?.find(item => item.variable === 'Level') && <>Level: {nft?.variable?.find(item => item.variable === 'Level')?.value}</>}</p>
                        </div>}
                    {/* { !account && nft && nft?.User?.metamask !== signedUser.metamask && <div className="nft__item_action">
                        <span onClick={() => handleRedirectToItem(nft.id)}>{ nft.status === 'on_auction' ? t("PlaceBid") : t("BuyNow") }</span>
                    </div>} */}
                    <div className={isVoted.current? "nft__item_liked" :"nft__item_like"}>
                        <i className="fa fa-heart" onClick={() => onClickLike()}></i><span>{countLike}</span>
                    </div>                            
                </div>
                { nft.gltf &&
                    <NavLink to={`/3D_Asset/${nft.id}`}>
                        <button className='btn-main lead mb-2 nft-card-button'>{previewType === '2d' ? 'View as 3D' : 'View as 2D'}</button>
                    </NavLink>
                }
            </div>
            <Dialog
                open={showModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className='alert-modal'
            >
                <div className={classes.root}>
                    <div className={classes.topBlock}>
                        <CheckCircleOutlinedIcon className={classes.icon} />
                    </div>  
                    <div className={classes.bottomBlock}>
                        <Typography className={classes.title}>
                            {t("You need to connect your wallet first!")}
                        </Typography>
                        <div className={classes.buttonBlock}>
                            <button
                                className="btn-main"
                                onClick={()=>setShowModal(false)}
                            >
                                {t("Close")} 
                            </button>
                            <button
                                className="btn-main"
                                onClick={handleAuth}
                            >
                                {t("Connect")}
                            </button>
                            
                        </div>
                    </div>
                </div>
            </Dialog>
            <Dialog
                open={showAlertModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className='alert-modal'
            >
                <div className={classes.root}>
                    <div className={classes.topBlock}>
                        <CheckCircleOutlinedIcon className={classes.icon} />
                    </div>  
                    <div className={classes.bottomBlock}>
                        <Typography className={classes.title}>
                            {alertMessage}
                        </Typography>
                        <div className={classes.buttonCancelBlock}>
                            <button
                                className="btn-main"
                                onClick={()=>{setShowAlertModal(false)}}
                            >
                                {t("Close")} 
                            </button>
                        </div>
                    </div>
                </div>
            </Dialog>
            <ModalNoMetamask open={serverError} onClose={() => {setServerError(false);}}/>
        </div>             
    );
};

export default memo(AssetCard);
import { useState } from 'react';
import Footer from '../components/footer';
import { StyledHeader } from '../Styles';
import Header from "../menu/header";
import { debounce } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { EPATHS } from '../../../routes/paths.enum';
import { useHistory } from 'react-router-dom';
import * as selectors from '../../../store/selectors';
import * as actions from '../../../store/actions/thunks';
import { fetchEmail } from '../../../services/DataService';
import * as AuthService from "../../../services/AuthService";

const theme = 'GREY';

const EMAIL_ERROR = 'This email already exists.';

function ChangeEmail() {
  const history=useHistory();

  const [email, setEmail] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const signedUser = useSelector(selectors.user);
  const dispatch = useDispatch();

  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const checkEmailValidation = (value) => {
    if(value) {
      setErrorMessage(null);
      try {
        if(!validateEmail(value)) {
          setErrorMessage('Please input valid email address');
        }
        else {
          // setErrorMessage(null);
          fetchEmail(value).then(res => {
            if(res) {
              setErrorMessage(EMAIL_ERROR);
            }
            else {
              setErrorMessage(null);
            }
          }).catch(error => {     
            let msg = (typeof error === 'object') ? error.message : error;
            setErrorMessage(msg);
          });
        }
      }
      catch (error) {    
        let msg = (typeof error === 'object') ? error.message : error;
        setErrorMessage(msg);
      }
    }
  }

  const [debounceCheckEmailValidation] = useState(() => debounce(checkEmailValidation, 1000));

  const changeEmail = async () => {
    const formData = new FormData();
    formData.append("email", email);
    AuthService.updateUser(signedUser.id, formData).then(res => {
        localStorage.setItem('_user', JSON.stringify({...res, accessToken: signedUser.accessToken}));
        dispatch(actions.setUser({...res, accessToken: signedUser.accessToken}));
        setEmailSent(true);
        setTimeout(function() {
          history.push({pathname: EPATHS.HOME});
          // eslint-disable-next-line
        }.bind(this), 4000);
      }).catch((e) => {
        setErrorMessage(e.message);
      }
    );
  }

  return (
    <div>
      {/* <GlobalStyles/> */}
      <StyledHeader theme={theme} />
      <Header className=""/>
      <section className='jumbotron breadcumb no-bg' style={{backgroundImage: `url(${'./img/background/subheader.jpg'})`}}>
        <div className='mainbreadcumb'>
          <div className='container'>
            <div className='row'>
              <div className="col-md-12 text-center">
                  <h1>Change Email</h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='container'>
        {emailSent ? <div className="row">
          <div className="col-md-6 offset-md-3">
              <h3>New Email registered!</h3>
              <p>Your email has been updated. You need to verify your email.</p>
          </div>
        </div> 
        : <div className="row">
          <div className="col-md-6 offset-md-3">
              <h3>Enter your new email</h3>
              <div className="field-set">
                <input type='email'
                  value={email}
                  className="form-control"
                  placeholder="Enter your email"
                  onChange={(e) => { setEmail(e.target.value); debounceCheckEmailValidation(e.target.value)}}/>
              </div>
              {
                errorMessage && <div className="col-md-12">
                  <p className='error-input'>{errorMessage}</p>
                </div>
              }

              <div id='submit'>
                <button disabled={email === '' || email === null || !validateEmail(email)} className="btn-main" onClick={() => changeEmail()}>Submit</button>
              </div>
          </div>
        </div>}
      </section>

      <Footer />
    </div>
  )
};

export default ChangeEmail;
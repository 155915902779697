import React, { memo, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as selectors from '../../../store/selectors';
import * as actions from '../../../store/actions/thunks';
import { clearTileGroups, clearFilter, showLoadMoreTileGroups } from '../../../store/actions';
import TileGroupCard from './TileGroupCard';
import NftMusicCard from './NftMusicCard';
import { shuffleArray } from '../../../store/utils';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import { useHistory } from 'react-router';
import { EPATHS } from '../../../routes/paths.enum';
import Pagination from '@material-ui/lab/Pagination';
import useStyles from '../pages/styles/Authors/authors';

//react functional component
const ColumnTileGroupRedux = ({ priceRate, continent, shuffle=false, authorId=null, isForSale=false, orderBy=false, tier }) => {
    const {t}=useTranslation();
    const dispatch = useDispatch();
    const tileGroupItems = useSelector(selectors.tileGroupItems);
    const tileGroupCount = useSelector(selectors.tileGroupCount);
    const loadingState = useSelector(selectors.loadingState);
    const tileGroups = tileGroupItems ? shuffle ? shuffleArray(tileGroupItems) : tileGroupItems : [];
    const [page, setPage] = useState(0);
    const [height, setHeight] = useState(350);
    const history = useHistory();
    const classes = useStyles();

    const onImgLoad = ({target:img}) => {
        // let currentHeight = height;
        // if(currentHeight < img.offsetHeight) {
        //     setHeight(img.offsetHeight);
        // }
    }

    useEffect(() => {
        dispatch(showLoadMoreTileGroups(true));
        dispatch(clearFilter());
        dispatch(clearTileGroups());
        dispatch(actions.fetchTileGroupsBreakdown(authorId, 0, continent, isForSale, orderBy, tier));
        setPage(1);
    }, [continent, isForSale, orderBy, tier]);

    const handleSelectPage=(event,newPage)=>{
        dispatch(clearTileGroups());
        if(continent) {
          dispatch(actions.fetchTileGroupsBreakdown(authorId, newPage-1, continent, isForSale, orderBy, tier));
        }
        else {
          dispatch(actions.fetchTileGroupsBreakdown(authorId, newPage-1, continent, isForSale, orderBy, tier));
        }
        setPage(newPage);
      }

    const loadMore = () => {
        dispatch(actions.fetchTileGroupsBreakdown(authorId, page, continent, isForSale, orderBy, tier));
        setPage(page + 1);
    }

    return (
        <div className='row'>
            {tileGroups.length === 0 && authorId && !loadingState && <div style={{textAlign: 'center'}}>
                <Typography style={{marginBottom: 10}}>You don’t have any Pack of Lands in your wallet. To start buying Pack of Lands, you can visit the LAND marketplace.</Typography>
                <span onClick={() => history.push({pathname: EPATHS.LAND})} className="btn-main lead m-auto">{"Visit LAND"}</span>
            </div>}
            {tileGroups && tileGroups.map( (nft, index) => (
                nft.category === 'music' ?
                <NftMusicCard nft={nft} audioUrl={nft.audio_url} key={index} onImgLoad={onImgLoad} height={height} />
                :
                <TileGroupCard priceRate={priceRate} nft={nft} key={index} account={authorId} height={200}/>
            //    <AssetCard priceRate={priceRate} nft={nft} key={index} onImgLoad={onImgLoad} account={authorId} height={height}/>
            ))}
            <div className={classes.root}>
                <Pagination
                    page={page} 
                    count={Math.ceil(tileGroupCount/12)} 
                    onChange={handleSelectPage}
                    variant="outlined"
                    shape="rounded" />
            </div>
        </div>              
    );
};

export default memo(ColumnTileGroupRedux);
import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import FIRSTBACKGROUND from "../../assets/images/Backround_Frame3.png"
import SECONDBACKGROUND from "../../assets/images/Image_Frame4.jpg"
import THIRDBACKGROUND from "../../assets/images/EIV_BACK_COVER_5.jpg";
import { NavLink, useHistory } from 'react-router-dom';

import useStyles from "../../styles/components/GetStarted/earnSection"
import DefaultButton from '../buttons/defaultButton';
import { EPATHS } from '../../routes/paths.enum';

function EarnSection() {
  const classes=useStyles();
  const history = useHistory();

  return <div className={classes.root}> 
    <div className={classes.blockText}>
      <Grid container spacing={3} className={classes.topContainer}>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12} className={classes.foregroundImageContainer}>
          <img src={FIRSTBACKGROUND} alt={FIRSTBACKGROUND} style={{width: '100%'}}/>
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12} className={classes.firstContainer}>
          <div style={{width: '100%'}}>
            <Typography variant='h1' className={classes.title}>Start playing & earn</Typography>
            <Typography variant='h1' className={classes.title}>$USDC and 3D NFTs</Typography>
            <br/>
            <NavLink to={EPATHS.EXPLORE} className={classes.buttonContainer} onClick={() => history.push({pathname: EPATHS.EXPLORE})}>
              <DefaultButton color="primary" variant='outlined' text="Visit Marketplace"  fontSize={18} outline='white'/>
            </NavLink>
          </div>
        </Grid>
      </Grid>
      <div className={classes.backImageContainer}>
        <img src={THIRDBACKGROUND} alt={THIRDBACKGROUND} style={{width: '100%'}}/>
      </div>
      <div className={classes.backImageContainer}>
        <img src={SECONDBACKGROUND} alt={FIRSTBACKGROUND} style={{width: '100%'}}/>
      </div>
    </div>
  </div>;
}

export default EarnSection;

export enum ECOLORS{
    PRIMARY="#ffffff",
    PRIMARY_DARK="#20005e",
    PRIMARY_LIGHT="#f312a2",
    SECONDARY="#ff2d55",
    SECONDARY_DARK="#a706aa",
    BLACK="#16141a",
    GREY="#f2f2f2",
    GREY_DARK="#a59f9f",
    RED="#f44336",
    RED_SECONDARY="#e2747e",
    GREEN_SECONDARY="#abd804",
    PURPLE="#a706aa",
}
import { axiosWithToken } from '../utils/functions/apiCallWithToken';

const serverURL = process.env.REACT_APP_SERVER;

export const fetchCategory = async (params = {}) => {
    const result = await axiosWithToken(`${serverURL}/category/`, 'get', {}, params);
    return result;
}

export const createCategory = async (data) => {
    const result = await axiosWithToken(`${serverURL}/category/create/`, 'post', data);
    return result;
}

export const deleteCategory = async (id) => {
    const result = await axiosWithToken(`${serverURL}/asset/dislike/${id}/`, 'delete', {});
    return result;
}

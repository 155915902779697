import { Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

import BACKGROUND from "../../assets/images/CITY_PNG.png"
import { EPATHS } from '../../routes/paths.enum';
import { NavLink, useHistory } from 'react-router-dom';

import useStyles from "../../styles/components/Home/createSection"
import DefaultButton from '../buttons/defaultButton';
import { Button } from '@material-ui/core';

function CreateSection() {
  const classes=useStyles();
  const {t}=useTranslation();
  const history = useHistory();

  return <div className={classes.root}> 
    <img src={BACKGROUND} alt={BACKGROUND} className={classes.img} />
    <div className={classes.blockText}>
      <Typography variant='h1'>{t("Create")}</Typography>
      <Typography variant='h2'>{t("CreateScenes")}</Typography>
      <div className={classes.buttonContainer}>
      <NavLink to={EPATHS.GET_STARTED} className={classes.getStartedButton} onClick={() => history.push({pathname:EPATHS.GET_STARTED})}>
        <DefaultButton color="primary" variant='outlined' text={t("GetStarted")} fontSize={18}/>
      </NavLink>
      </div>
    </div>
  </div>;
}

export default CreateSection;

import { getType } from 'typesafe-actions';
import * as actions from '../actions';

export const defaultState = {
  user: {},
  isTimeout: false,
};

const states = (state = defaultState, action) => {
  switch (action.type) {
    case getType(actions.setUser):
      return { ...state, user: action.payload};
    case getType(actions.setIsTimeout):
      return { ...state, isTimeout: action.payload};
  
    default:
      return state;
  }
};

export default states;

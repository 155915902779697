import React from 'react';

function ToggleIcon({showLegend, setShowLegend, zoomLevel}) {
  return (
    <div style={{
				background: '#A706AA',
				position: 'fixed',
				bottom: showLegend ? (zoomLevel > 3 ? 600 : 230) : 20,
				padding: 5,
				borderRadius: 8,
				left: 130,
				zIndex: 25,
				cursor: 'pointer',
				fontSize: 12
			}}
			onClick={() => setShowLegend(!showLegend)}
		>
			<i class={`fa text-white ${showLegend ? 'fa-arrow-down' : 'fa-arrow-up'}`} aria-hidden="true"></i>
    </div>
  );
}

export default ToggleIcon;

import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from '@reach/router';
import { useHistory } from 'react-router-dom';
import Footer from '../../components/footer';
import { EPATHS } from "../../../../routes/paths.enum";
import Header from "../../menu/header";

function CreateOptions() {  
  const {t}=useTranslation();
  const history=useHistory();
  const handleRedirectToPath=(path)=>{
    history.push({pathname:path})
  }

  return (
        <div>
        <Header className="" />
        <section className='jumbotron breadcumb no-bg' style={{backgroundImage: `url(${'./img/background/subheader.jpg'})`}}>
          <div className='mainbreadcumb'>
            <div className='container'>
              <div className='row m-10-hor'>
                <div className='col-12'>
                  <h1 className='text-center'>{t("Add Single 3D Asset")}</h1>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className='container'>
          <div className='row'>
            <div className="col-md-6 offset-md-3">
                {/* <p>{t("CreateCollectibleText")}</p> */}
                {/* <Link to={EPATHS.CREATE_SINGLE} className="opt-create" onClick={()=>handleRedirectToPath(EPATHS.CREATE_SINGLE)}>
                    <img src="./img/misc/coll-single.png" alt=""/>
                    <h3>{t("Single")}</h3>
                </Link> */}
                <Link to={EPATHS.CREATE_MULTIPLE} className="opt-create"  onClick={()=>handleRedirectToPath(EPATHS.CREATE_MULTIPLE)}>
                    <img src="./img/misc/coll-multiple.png" alt=""/>
                    <h3>{t("Add Single 3D Asset")}</h3>
                </Link>
            </div>
          </div>
        </section>

       

        <Footer />
      </div>
  )
}

export default CreateOptions
import { axiosWithToken } from '../utils/functions/apiCallWithToken';

const serverURL = process.env.REACT_APP_SERVER;

export const createNotification = async (data) => {
    const result = await axiosWithToken(`${serverURL}/notification/create/`, 'post', data);
    return result;
}

export const clearNotifications = async () => {
    const result = await axiosWithToken(`${serverURL}/notification/clear/`, 'post', {});
    return result;
}

export const fetchNotifications = async () => {
    const result = await axiosWithToken(`${serverURL}/notification/`, 'get', {});
    return result;
}
import { makeStyles, Theme } from '@material-ui/core';
import { ECOLORS } from '../../../utils/constants/colors.enum';

export default makeStyles((theme: Theme) => ({
    root:{
        width:450,
        height:400
    },
    topBlock:{
        height:150,
        backgroundColor:ECOLORS.GREEN_SECONDARY,
        display:"flex",
        justifyContent:"center",
        alignItems:"center"
    },
    icon:{
        color:"white",
        height:80,
        width:80
    },
    bottomBlock:{
        marginTop:20,
        display:"flex",
        flexDirection:"column",
        justifyContent:"center",
        alignItems:"center"
    },
    buttonBlock: {
        display: "flex",
        marginTop: 50,
        width: '60%',
        justifyContent: 'space-between'
    },
    buttonCancelBlock: {
        marginTop: 50
    },
    title:{
        fontWeight:"bold",
        fontSize:18,
        color:ECOLORS.PRIMARY_DARK
    },
    buttonResend:{
        backgroundColor:ECOLORS.SECONDARY_DARK,
        borderRadius:30,
        marginTop:20,
        boxShadow:"0px 0px 0px 0px",
        color:ECOLORS.PRIMARY,
        marginRight:10
    },
    button:{
        backgroundColor:ECOLORS.GREEN_SECONDARY,
        borderRadius:30,
        marginTop:20,
        boxShadow:"0px 0px 0px 0px",
        color:ECOLORS.PRIMARY
    },
    cardPrice: {
        marginBottom: '0rem',
        minHeight: 28
    },
    tierTitle: {
        marginBottom: '0rem',
        minHeight: 28,
        fontWeight: 400
    }
  }));
import React, { useState } from 'react'
import { Typography,Button  } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';

import useStyles from "../../styles/components/Servererror/modalError"

interface IModalAvax{
    open:boolean;
    onClose:()=>void
}

function ModalAvax({open,onClose}:IModalAvax) {
    const classes=useStyles();
    const {t}=useTranslation();
    // const [open, setOpen] = useState(true);
  return (
    <Dialog
    open={open}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    className='alert-modal'
  >
      <div className={classes.root}>
        <div className={classes.topBlock}>
            <HighlightOffIcon className={classes.icon} onClick={() => onClose()} />
        </div>  
        <div className={classes.bottomBlock}>
            <Typography className={classes.title}>
                {t("OhSnap")}
            </Typography>
            <Typography className={classes.supTitle}>
                {t("ConnectAvalancheChannel")}
            </Typography>
            {/* <Button
                variant="contained"
                color="secondary"
                className={classes.button}
                startIcon={<CloseIcon />}
                onClick={onClose}
            >
                {t("Close")}
            </Button> */}
        </div>
      </div>
  </Dialog>
  )
}

export default ModalAvax
import React, { useState } from 'react';
import {
    IconButton,
    Select,
    MenuItem,
    Box,
    TextField,
    FormControl
} from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

const buildingSizes = ['S', 'M', 'L', 'XL'];
const buildingTypes = ['EarniHome', 'EarniFactory'];
const defaultVariable = {'id': 0, 'variable': '--Please select--'};

//react functional component
const VariableItem = (props) => {
    const {item , isCompleted, addVariable, removeVariable, variableList, continents, updateVariable, disabled } = props;
    const [selectedItem, setSelectedItem] = useState(defaultVariable);
    const [value, setValue] = useState();
    const [id, setId] = useState();
    const [variable, setVariable] = useState();
    const [format, setFormat] = useState();
    const [maxRate, setMaxRate] = useState();
    const [minRate, setMinRate] = useState();

    const handleChangeItem = (e) => {
        setSelectedItem(e.target.value);
        if(e.target.value.minRate) {
            setValue(e.target.value.minRate);
        }
        else {
            setValue(0);
        }
        setId(e.target.value.id);
        setVariable(e.target.value.variable);
        setFormat(e.target.value.format);
        setMaxRate(e.target.value.maxRate);
        setMinRate(e.target.value.minRate);
    }

    const handleAddButtonClicked = () => {
        addVariable({"id": id, "variable": variable, "value": value, "format": format, "maxRate": maxRate, "minRate": minRate});
        setId();
        setVariable();
        setValue();
        setFormat();
        setSelectedItem(defaultVariable);
    }

    const handleRemoveVariable = (id) => {
        removeVariable(id);
    }

    return (
        <div>
            {
                isCompleted ? <Box style={styles.container}>
                    <span style={styles.variableSelector}>{item.variable}</span>
                    {
                        item.format === 'STAR' && 
                        // <Rating name={`rating-${item.id}`} value={parseInt(item.value / 10)} max={item.maxRate} onChange={(event, newValue) => {
                        //     updateVariable(item.id, newValue);
                        // }} disabled={true}/>
                        // <span style={styles.variableValue}>{item.value}</span>
                        <span>
                            <TextField
                                type="number"
                                variant="outlined"
                                size="small"
                                InputProps={{ inputProps: { 
                                    min: 1,
                                    max: 100 } 
                                }}
                                value={item.value}
                                onChange={(e) => (e.target.value < 0) || (e.target.value > 100) ? null : setValue(e.target.value)}
                                style={styles.variableValue}
                                disabled={disabled}
                            />
                            <span> / 100</span>
                        </span>
                    }
                    {
                        item.format === 'NUMBER' &&
                        <TextField 
                            type="number"
                            variant="outlined"
							size="small"
                            InputProps={{ inputProps: { 
                                min: item.minRate ? item.minRate : null,
                                max: item.maxRate ? item.maxRate : null } 
                            }}
                            value={item.value}
                            onChange={(e) => updateVariable(item.id, e.target.value)}
                            style={styles.variableValue}
                            disabled={disabled}
                        />
                        // <span style={styles.variableValue}>{item.value}</span>
                    }
                    {
                        item.variable === 'Level' &&
                        <span style={styles.variableValue}>{item.value}</span>
                    }
                    {
                        item.variable === 'Building Type' && 
                        <FormControl size="small">
                            <Select 
                                value={item.value}
                                onChange={(e) => updateVariable(item.id, e.target.value)}
                                style={styles.variableValue}
                                variant="outlined"
                                defaultValue=""
                                disabled={disabled}
                            >
                                {
                                    buildingTypes.map((item) => (
                                        <MenuItem key={item} value={item}>{item}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    }
                    {
                        item.variable === 'Building Size' && 
                        <FormControl size="small">
                        <Select 
                            value={item.value}
                            onChange={(e) => updateVariable(item.id, e.target.value)}
                            style={styles.variableValue}
                            variant="outlined"
                            defaultValue=""
                            disabled={disabled}
                        >
                            {
                                buildingSizes.map((item) => (
                                    <MenuItem key={item} value={item}>{item}</MenuItem>
                                ))
                            }
                        </Select></FormControl>
                    }
                    {
                        item.variable === 'Continent' && 
                        <FormControl size="small">
                        <Select 
                            value={item.value}
                            onChange={(e) => updateVariable(item.id, e.target.value)}
                            style={styles.variableValue}
                            variant="outlined"
                            defaultValue=""
                            disabled={disabled}
                        >
                            {
                                continents.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                ))
                            }
                        </Select></FormControl>
                    }
                    {item.variable !== 'Level' && <IconButton onClick={() => handleRemoveVariable(item.id)}><RemoveCircleOutlineIcon /></IconButton>}
                </Box>
                : <Box style={styles.container}>
                    <FormControl size="small">
                        <Select
                            value={selectedItem}
                            defaultValue=""
                            onChange={handleChangeItem}
                            style={styles.variableSelector}
                            variant="outlined"
                        >
                            <MenuItem value={defaultVariable}>{defaultVariable.variable}</MenuItem>
                            {
                                variableList.map((item) => (
                                    <MenuItem key={item.id} value={item}>{item.variable}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                    {
                        selectedItem && selectedItem.variable === 'Building Type' && <FormControl size="small"><Select 
                            value={value}
                            onChange={(e) => setValue(e.target.value)}
                            style={styles.variableValue}
                            variant="outlined"
                            defaultValue=""
                        >
                            {
                                buildingTypes.map((item) => (
                                    <MenuItem key={item} value={item}>{item}</MenuItem>
                                ))
                            }
                        </Select></FormControl>
                    }
                    {
                        selectedItem && selectedItem.variable === 'Building Size' && <FormControl size="small"><Select 
                            value={value}
                            onChange={(e) => setValue(e.target.value)}
                            style={styles.variableValue}
                            variant="outlined"
                            defaultValue=""
                        >
                            {
                                buildingSizes.map((item) => (
                                    <MenuItem key={item} value={item}>{item}</MenuItem>
                                ))
                            }
                        </Select></FormControl>
                    }
                    {
                        selectedItem && selectedItem.variable === 'Continent' && <FormControl size="small"><Select 
                            value={value}
                            onChange={(e) => setValue(e.target.value)}
                            style={styles.variableValue}
                            variant="outlined"
                            defaultValue=""
                        >
                            {
                                continents.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                ))
                            }
                        </Select></FormControl>
                    }
                    {
                        selectedItem && selectedItem.format === 'STAR' && 
                            // <Rating name="rating-new" value={value} max={10} onChange={(event, newValue) => {
                            //     setValue(newValue);
                            // }} />
                            <span>
                                <TextField
                                    type="number"
                                    variant="outlined"
                                    size="small"
                                    InputProps={{ inputProps: { 
                                        min: 1,
                                        max: 100 } 
                                    }}
                                    value={value}
                                    onChange={(e) => (e.target.value < 0) || (e.target.value > 100) ? null : setValue(e.target.value)}
                                    style={styles.variableValue}
                                />
                                <span> / 100</span>
                            </span>
                    }
                    {
                        selectedItem && selectedItem.format === 'NUMBER' && 
                            <TextField 
                                type="number"
                                variant="outlined"
								size="small"
                                InputProps={{ inputProps: { 
                                    min: selectedItem.minRate ? selectedItem.minRate : null,
                                    max: selectedItem.maxRate ? selectedItem.maxRate : null } 
                                }}
                                value={value}
                                onChange={(e) => (e.target.value < selectedItem.minRate && selectedItem.minRate) || (e.target.value > selectedItem.maxRate && selectedItem.maxRate) ? null : setValue(e.target.value)}
                                style={styles.variableValue}
                            />
                    }
                </Box>
            }
            {!isCompleted && 
            <button className='btn-main lead mb-5 mr15' style={styles.button} onClick={(e) => {
                e.preventDefault();
                handleAddButtonClicked();
            }} disabled={!(id && value && variable)}>Add</button>}
        </div>              
    );
};

const styles = {
    container: {
        display: 'flex',
        alignItems: 'center',
				marginTop: 10
    },
    variableSelector: {
        width: 240,
        marginRight: 20
    },
    variableValue: {
        width: 240,
    },
    row: {
        padding: 10
    },
    button: {
        marginTop: 20
    }
}
export default VariableItem;
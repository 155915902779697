import { axiosWithToken } from '../utils/functions/apiCallWithToken';

const serverURL = process.env.REACT_APP_SERVER;

export const fetchSnapshot = async (metamask) => {
    const result = await axiosWithToken(`${serverURL}/snapshot/${metamask}`, 'get', {});
    return result;
}

export const manualRegister = async (data) => {
    const result = await axiosWithToken(`${serverURL}/snapshot/register`, 'post', data);
    return result;
}

export const addSnapshot = async (data) => {
    const result = await axiosWithToken(`${serverURL}/snapshot/add`, 'post', data);
    return result;
}
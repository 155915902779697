import { Axios, Canceler } from '../../../core/axios';
import * as actions from '../../actions';
import api from '../../../core/api';
import authHeader from "../../../services/auth-header";
import * as TileService from "../../../services/TileService";
import * as AssetService from "../../../services/AssetService";
import * as NftService from "../../../services/NftService";

export const fetchAssetsBreakdown = (authorId, page, query=null, categoryId=false, activated=false, tagIds=null, utilityIds=null, orderBy=false, isNotSold=false) => async (dispatch, getState) => {
  dispatch(actions.setLoading(true));
  dispatch(actions.getAssetBreakdown.request(Canceler.cancel));
  try {
    let params = {};
    params['page'] = page;

    if (authorId) {
      params['user'] = authorId;
    }

    if(categoryId) {
      params['categoryId'] = categoryId;
    }

    if(query) {
      params['query'] = query;
    }

    if(activated) {
      params['activated'] = activated;
    }

    if(tagIds) {
      params['tagIds'] = JSON.stringify(tagIds);
    }

    if(utilityIds) {
      params['utilityIds'] = JSON.stringify(utilityIds);
    }

    if(orderBy) {
      params['orderBy'] = orderBy;
    }

    if(isNotSold) {
      params['isNotSold'] = isNotSold;
    }

    const data = await AssetService.fetchAssets(params);
    dispatch(actions.setLoading(false));
    dispatch(actions.setAssetCount(data.count));
    if(data.rows.length > 0) {
      dispatch(actions.getAssetBreakdown.success(data.rows));
    }
    else {
      dispatch(actions.showLoadMoreAssets(false));
    }
  } catch (err) {
    dispatch(actions.getAssetBreakdown.failure(err));
    dispatch(actions.setLoading(false));
  }
};

export const fetchNftLikeBreakdown = (authorId, page, isMusic = false) => async (dispatch, getState) => {
  
  //access the state
  const state = getState();
  dispatch(actions.setLoading(true));

  dispatch(actions.getNftLikeBreakdown.request(Canceler.cancel));

  try {
    const data = await NftService.fetchNftLike(page);
    
    dispatch(actions.setNFTLikeCount(data.count));
    dispatch(actions.setLoading(false));
    if(data.rows.length > 0) {
      dispatch(actions.getNftLikeBreakdown.success(data.rows));
    }
    else {
      dispatch(actions.showLoadMoreNFTLike(false));
    }
  } catch (err) {
    dispatch(actions.setLoading(false));
    dispatch(actions.getNftLikeBreakdown.failure(err));
  }
};

export const fetchAssetLikeBreakdown = (authorId, page, isMusic = false) => async (dispatch, getState) => {
  dispatch(actions.setLoading(true));
  dispatch(actions.getAssetLikeBreakdown.request(Canceler.cancel));

  try {
    const data = await AssetService.fetchAssetLike(page);
    
    dispatch(actions.setAssetLikeCount(data.count));
    dispatch(actions.setLoading(false));
    if(data.rows.length > 0) {
      dispatch(actions.getAssetLikeBreakdown.success(data.rows));
    }
    else {
      dispatch(actions.showLoadMoreAssetLike(false));
    }
  } catch (err) {
    dispatch(actions.setLoading(false));
    dispatch(actions.getAssetLikeBreakdown.failure(err));
  }
};

export const fetchTileLikeBreakdown = (authorId, page, isMusic = false) => async (dispatch, getState) => {
  dispatch(actions.setLoading(true));
  dispatch(actions.getTileLikeBreakdown.request(Canceler.cancel));

  try {
    const data = await TileService.fetchTileLike(page);
    
    dispatch(actions.setTileLikeCount(data.count));
    dispatch(actions.setLoading(false));
    if(data.rows.length > 0) {
      if(data.rows.length < 12) { dispatch(actions.showLoadMoreTileLike(false)); }
      dispatch(actions.getTileLikeBreakdown.success(data.rows));
    }
    else {
      dispatch(actions.showLoadMoreTileLike(false));
    }
  } catch (err) {
    dispatch(actions.setLoading(false));
    dispatch(actions.getTileLikeBreakdown.failure(err));
  }
};

export const fetchNftWishlistBreakdown = (authorId, page, isMusic = false) => async (dispatch, getState) => {
  dispatch(actions.setLoading(true));
  dispatch(actions.getNftWishlistBreakdown.request(Canceler.cancel));

  try {
    const data = await NftService.fetchNftWishlist(page);
    
    dispatch(actions.setNFTWishlistCount(data.count));
    dispatch(actions.setLoading(false));
    if(data.rows.length > 0) {
      dispatch(actions.getNftWishlistBreakdown.success(data.rows));
    }
    else {
      dispatch(actions.showLoadMoreNFTWishlist(false));
    }
  } catch (err) {
    dispatch(actions.setLoading(false));
    dispatch(actions.getNftWishlistBreakdown.failure(err));
  }
};

export const fetchAssetWishlistBreakdown = (authorId, page, isMusic = false) => async (dispatch, getState) => {
  dispatch(actions.setLoading(true));
  dispatch(actions.getAssetWishlistBreakdown.request(Canceler.cancel));

  try {
    const data = await AssetService.fetchAssetWishlist(page);
    
    dispatch(actions.setAssetWishlistCount(data.count));
    dispatch(actions.setLoading(false));
    if(data.rows.length > 0) {
      dispatch(actions.getAssetWishlistBreakdown.success(data.rows));
    }
    else {
      dispatch(actions.showLoadMoreAssetWishlist(false));
    }
  } catch (err) {
    dispatch(actions.setLoading(false));
    dispatch(actions.getAssetWishlistBreakdown.failure(err));
  }
};

export const fetchTileWishlistBreakdown = (authorId, page, isMusic = false) => async (dispatch, getState) => {
  dispatch(actions.setLoading(true));
  dispatch(actions.getTileWishlistBreakdown.request(Canceler.cancel));

  try {
    const data = await TileService.fetchTileWishlist(page);
    
    dispatch(actions.setTileWishlistCount(data.count));
    dispatch(actions.setLoading(false));
    if(data.rows.length > 0) {
      if(data.rows.length < 12) { dispatch(actions.showLoadMoreTileWishlist(false)); }
      dispatch(actions.getTileWishlistBreakdown.success(data.rows));
    }
    else {
      dispatch(actions.showLoadMoreTileWishlist(false));
    }
  } catch (err) {
    dispatch(actions.setLoading(false));
    dispatch(actions.getTileWishlistBreakdown.failure(err));
  }
};

export const fetchNftsBreakdown = (authorId, page, isForSale=false) => async (dispatch, getState) => {
  // const state = getState();
  dispatch(actions.setLoading(true));
  // console.log(state);

  dispatch(actions.getNftBreakdown.request(Canceler.cancel));

  try {
    let params = {};
    params['page'] = page;

    if (authorId) {
      params['user'] = authorId;
    }

    if (isForSale) {
      params['isForSale'] = isForSale;
    }

    const data = await NftService.fetchNFTs(params)
    
    dispatch(actions.setNFTCount(data.count));
    dispatch(actions.getNftBreakdown.success(data.rows));
    dispatch(actions.setLoading(false));
  } catch (err) {
    dispatch(actions.setLoading(false));
    dispatch(actions.getNftBreakdown.failure(err));
  }
};

export const fetchTilesBreakdown = (authorId, page, continent = null, posX = null, posY = null, posZ = null, isForSale=false, orderBy=false, tier) => async (dispatch, getState) => {
  dispatch(actions.setLoading(true));
  dispatch(actions.getTileBreakdown.request(Canceler.cancel));

  try {
    let params = {};
    params['page'] = page;

    if (authorId) {
      params['user'] = authorId;
    }

    if (continent) {
      params['continent'] = continent;
    }

    if (posX !== null && posX !== '') {
      params['posX'] = posX;
    }

    if (posY !== null && posY !== '') {
      params['posY'] = posY;
    }

    if (posZ !== null && posZ !== '') {
      params['posZ'] = posZ;
    }

    if (isForSale) {
      params['isForSale'] = isForSale;
    }

    if(orderBy) {
      params['orderBy'] = orderBy;
    }

    if(tier) {
      params['tier'] = tier;
    }

    const data = await TileService.fetchTiles(params);

    dispatch(actions.setTileCount(data.count));
    dispatch(actions.setLoading(false));
    if(data.rows.length > 0) {
      dispatch(actions.getTileBreakdown.success(data.rows));
    }
    else {
      dispatch(actions.showLoadMoreTiles(false));
    }
  } catch (err) {
    dispatch(actions.setLoading(false));
    dispatch(actions.getTileBreakdown.failure(err));
  }
};


export const fetchTileGroupsBreakdown = (authorId, page, continent, isForSale=false, orderBy=false, tier) => async (dispatch, getState) => {
  dispatch(actions.setLoading(true));
  dispatch(actions.getTileGroupBreakdown.request(Canceler.cancel));

  try {
    let params = {};
    params['page'] = page;

    if (authorId) {
      params['user'] = authorId;
    }

    if (continent) {
      params['continent'] = continent;
    }

    if (isForSale) {
      params['isForSale'] = isForSale;
    }

    if(orderBy) {
      params['orderBy'] = orderBy;
    }

    if(tier) {
      params['tier'] = tier;
    }

    const data = await TileService.fetchTileGroups(params);
    dispatch(actions.setLoading(false));    
    dispatch(actions.setTileGroupCount(data.count));
    if(data.rows.length > 0) {
      dispatch(actions.getTileGroupBreakdown.success(data.rows));
    }
    else {
      dispatch(actions.showLoadMoreTileGroups(false));
    }
  } catch (err) {
    dispatch(actions.setLoading(false));
    dispatch(actions.getTileGroupBreakdown.failure(err));
  }
};

export const fetchNftShowcase = () => async (dispatch) => {

  dispatch(actions.getNftShowcase.request(Canceler.cancel));
  dispatch(actions.setLoading(true));
  try {
    const { data } = await Axios.get(`${api.baseUrl}${api.nftShowcases}`, {
      cancelToken: Canceler.token,
      params: {},
      headers: authHeader()
    });
    dispatch(actions.setLoading(false));
    dispatch(actions.getNftShowcase.success(data));
  } catch (err) {
    dispatch(actions.setLoading(false));
    dispatch(actions.getNftShowcase.failure(err));
  }
};

export const fetchAssetDetail = (nftId) => async (dispatch) => {

  dispatch(actions.getAssetDetail.request(Canceler.cancel));

  try {
    dispatch(actions.setLoading(true));
    const data = await AssetService.fetchAsset(nftId);

    dispatch(actions.setLoading(false));
    dispatch(actions.getAssetDetail.success(data));
  } catch (err) {
    dispatch(actions.setLoading(false));
    dispatch(actions.getAssetDetail.failure(err));
  }
};

export const fetchNftDetail = (nftId) => async (dispatch) => {

  dispatch(actions.getNftDetail.request(Canceler.cancel));

  try {
    dispatch(actions.setLoading(true));
    const data = await NftService.fetchNFT(nftId);
    dispatch(actions.setLoading(false));
    dispatch(actions.getNftDetail.success(data));
  } catch (err) {
    dispatch(actions.setLoading(false));
    dispatch(actions.getNftDetail.failure(err));
  }
};

export const fetchTileDetail = (tileId) => async (dispatch) => {

  dispatch(actions.getTileDetail.request(Canceler.cancel));
  dispatch(actions.setLoading(true));
  try {
    const data = await TileService.fetchTile(tileId)
    dispatch(actions.setLoading(false));
    dispatch(actions.getTileDetail.success(data));
  } catch (err) {
    dispatch(actions.setLoading(false));
    dispatch(actions.getTileDetail.failure(err));
  }
};

export const fetchTileGroupDetail = (tileGroupId) => async (dispatch) => {

  dispatch(actions.getTileGroupDetail.request(Canceler.cancel));
  dispatch(actions.setLoading(true));
  try {
    const data = await TileService.fetchTileGroup(tileGroupId);
    dispatch(actions.setLoading(false));
    dispatch(actions.getTileGroupDetail.success(data));
  } catch (err) {
    dispatch(actions.setLoading(false));
    dispatch(actions.getTileGroupDetail.failure(err));
  }
};